<template>
  <v-container fluid></v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Int',
  data: () => ({}),
  mounted: function () {
    axios.get(`${this.$store.getters.NODEURL}/duofinalize/${this.$route.query.p}`, { withCredentials: true })
      .then((result) => {
        let payload = result.data
        let email = payload.data.email
        delete payload.data.email
        //* store the users access levels and personal information into the vue store
        this.$store.commit('details', payload.data)
        this.$store.commit('deserializePermissions', payload.data.pms)
        // email goes into separate store object
        this.$store.commit('email', email)
        // flag the user as being authed
        this.$store.commit('auth', true)
        // send them to the base page
        this.$router.push("/d/base").catch((e) => { console.log(e) })
      })
      .catch((err) => { console.log(err) }).finally(() => {})
  }
}
</script>
