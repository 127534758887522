<template>
  <v-container fluid>

    <!-- snackbar - alert window pop up -->
    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }} <v-btn text @click="snackbar = false">Close </v-btn>
    </v-snackbar>

    <v-navigation-drawer v-model="drawer" absolute temporary right width="400">
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-switch @change="flipAllRegions" color="red" v-model="allRegions" hide-details dense label="All Regions"></v-switch>
          <v-btn @click="clearAllRegions" :disabled="!vmRegionCodes.length>=1" icon x-small class="mt-5 ml-4"><v-icon>mdi-selection-off</v-icon></v-btn>
        </v-list-item>
      </v-list>
      <v-divider class="mt-5"></v-divider>
      <v-row dense no-gutters>
        <v-col cols="6">
          <v-list class="pa-0 ma-0" dense>
            <v-list-item v-for="(i,index) in 6" :key="irRegionCodes[index].value">
              <v-switch @change="checkFlipRegion" v-model="vmRegionCodes[index]" hide-details dense :label="irRegionCodes[index].text"></v-switch>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list class="pa-0 ma-0" dense>
            <v-list-item v-for="(i,index) in 7" :key="irRegionCodes[index].value">
              <v-switch @change="checkFlipRegion" v-model="vmRegionCodes[index+6]" hide-details dense :label="irRegionCodes[index+6].text"></v-switch>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="6">
          <v-list>
            <v-list-item>
              <v-switch v-model="items_ing" hide-details dense label="Ingredients"></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch v-model="items_flv" hide-details dense label="Flavors"></v-switch>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- rollup table -->
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          id="rollupTable"
          :headers="rollupHeaders"
          :items="rollupItems"
          class="elevation-3"
          :loading="rollupLoading"
          item-key="pID"
          :dense="rollupDense"
          :search="rollupSearch"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          @item-expanded="expandDataRow"
          :footer-props="{ 'items-per-page-options': [-1] }">
          <template v-slot:top>
            <v-toolbar color="blue darken-4 elevation-0" dark>
              <v-toolbar-title>Projections Rollup</v-toolbar-title>
              <v-divider class="mx-2 grey" inset vertical></v-divider>
              <v-btn @click="refreshData" :loading="rollupLoading" text outlined>
                {{$store.getters.buttonDetails ? 'Refresh' : ''}}<v-icon :right="$store.getters.buttonDetails" color="white">mdi-refresh</v-icon></v-btn>
              <v-btn @click="exportToCSV" :loading="exportLoading" text outlined class="ml-2 caption">
                {{$store.getters.buttonDetails ? 'Export' : ''}}<v-icon :right="$store.getters.buttonDetails" color="green">mdi-file-excel</v-icon></v-btn>
              <v-btn text outlined class="ml-2 caption" @click="collapseRows">
                {{$store.getters.buttonDetails ? 'Collapse' : ''}}<v-icon :right="$store.getters.buttonDetails" color="white">mdi-arrow-expand-vertical</v-icon></v-btn>
              <v-switch v-model="vsUSDorKG" @change="swapUStoKG" prepend-icon="mdi-currency-usd" append-icon="mdi-weight-kilogram" class="mt-5 ml-2"></v-switch>
              <v-spacer></v-spacer>
              <v-text-field class="mt-5" v-model="rollupSearch" label="Search"></v-text-field>
              <v-btn outlined text @click="drawer = !drawer" class="ml-3 blue"><v-icon light>mdi-menu</v-icon></v-btn>
            </v-toolbar>
            <v-text-field class="caption" :value="initials()" hide-details solo disabled></v-text-field>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="border:solid 2px #aaa">
              <v-data-table
                id="companySubTable"
                :headers="companySubHeaders"
                :items="companySubItems[item.index]"
                item-key="id"
                dense
                hide-default-footer
                :footer-props="{ 'items-per-page-options': [-1] }">
                <template v-slot:top>
                  <v-toolbar dense color="indigo darken-1 elevation-0" dark> <v-toolbar-title>{{item.Item}}</v-toolbar-title> </v-toolbar>
                </template>
                <template v-slot:item.Q1="props"><span>{{toCurrency(props.item.Q1)}}</span></template>
                <template v-slot:item.Q2="props"><span>{{toCurrency(props.item.Q2)}}</span></template>
                <template v-slot:item.Q3="props"><span>{{toCurrency(props.item.Q3)}}</span></template>
                <template v-slot:item.Q4="props"><span>{{toCurrency(props.item.Q4)}}</span></template>
                <template v-slot:item.Item="props">
                  <span class="caption blue--text">{{props.item.Item}} <v-chip x-small color="grey lighten-3">{{props.item.SalesMgrNowName}}</v-chip></span>
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:item.custno="props"><span class="grey--text">{{props.item.custno}}</span></template>
          <template v-slot:item.Q1P="props"><span :class="cz(props.item.Q1P,-1)">{{toCurrency(props.item.Q1P)}}</span></template>
          <template v-slot:item.Q2P="props"><span :class="cz(props.item.Q2P,-1)">{{toCurrency(props.item.Q2P)}}</span></template>
          <template v-slot:item.Q3P="props"><span :class="cz(props.item.Q3P,-1)">{{toCurrency(props.item.Q3P)}}</span></template>
          <template v-slot:item.Q4P="props"><span :class="cz(props.item.Q4P,-1)">{{toCurrency(props.item.Q4P)}}</span></template>
          <template v-slot:item.Q1KG="props"><span :class="cz(props.item.Q4P,-1)">{{toKilos(props.item.Q1KG)}} <span class="caption">kg</span></span></template>
          <template v-slot:item.Q2KG="props"><span :class="cz(props.item.Q4P,-1)">{{toKilos(props.item.Q2KG)}} <span class="caption">kg</span></span></template>
          <template v-slot:item.Q3KG="props"><span :class="cz(props.item.Q4P,-1)">{{toKilos(props.item.Q3KG)}} <span class="caption">kg</span></span></template>
          <template v-slot:item.Q4KG="props"><span :class="cz(props.item.Q4P,-1)">{{toKilos(props.item.Q4KG)}} <span class="caption">kg</span></span></template>
          <template v-slot:item.Q1A="props"><span :class="cz(props.item.Q1A, props.item.Q1P)">{{toCurrency(props.item.Q1A)}}</span></template>
          <template v-slot:item.Q2A="props"><span :class="cz(props.item.Q2A, props.item.Q2P)">{{toCurrency(props.item.Q2A)}}</span></template>
          <template v-slot:item.Q3A="props"><span :class="cz(props.item.Q3A, props.item.Q3P)">{{toCurrency(props.item.Q3A)}}</span></template>
          <template v-slot:item.Q4A="props"><span :class="cz(props.item.Q4A, props.item.Q4P)">{{toCurrency(props.item.Q4A)}}</span></template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Headers from './data/projection'
import { helperMixin } from '../mixins/helper.js'

export default {
  name: 'Rollup',
  mixins: [helperMixin],
  data: () => ({
    items_ing: false,
    items_flv: true,
    // side bar
    drawer: false,
    vmRegionCodes: [],
    irRegionCodes: Headers.ZoneRegionCodes,
    allRegions: false, // v
    allAgents: false,
    // other page stuff
    vsUSDorKG: false,
    // snackbar
    sb_model: false,
    sb_color: '',
    sb_delay: 5000, // milliseconds
    sb_msg: '',
    // datatable
    rollupHeaders: Headers.ProjectionRollupHeaders,
    rollupItems: [],
    rollupSubItems: [],
    expanded: [],
    rollupDense: true,
    rollupSearch: '',
    // button loading
    rollupLoading: false,
    exportLoading: false,
    // sub table
    companySubHeaders: [
      { text: 'Company', align: 'left', sortable: true, value: 'name', class: 'black--text font-weight-bold', width: '60em' },
      { text: 'Q1', align: 'left', sortable: true, value: 'Q1', class: 'black--text font-weight-bold', },
      { text: 'Q2', align: 'left', sortable: true, value: 'Q2', class: 'black--text font-weight-bold', },
      { text: 'Q3', align: 'left', sortable: true, value: 'Q3', class: 'black--text font-weight-bold', },
      { text: 'Q4', align: 'left', sortable: true, value: 'Q4', class: 'black--text font-weight-bold', }
    ],
    companySubItems: []
  }),
  beforeCreate: function() {
    // assign region codes
    for (let i in this.irRegionCodes) {
      this.vmRegionCodes[i] = false
    }
  },
  beforeMount: function() {
    // load the rep names from the database
    // this.loadAllRepNames()
  },
  methods: {
    collapseRows: function () {
      this.expanded = []
    },
    cz(a, b) {
      if (b === -1) {
        if (a <= 0) { return `secondary--text` }
        else return `black--text`
      }
      if (a <= 0) { return `secondary--text` }
      else if (a > b) { return 'green--text' }
      else { return 'red--text' }
    },
    snackbar: function(data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    initials() {
      let tmp = ''
      for (let p in this.vmRegionCodes) {
        if (this.vmRegionCodes[p]) {
          tmp += `${this.irRegionCodes[p].value},`
        }
      }
      tmp = tmp.slice(0, -1)
      return `filters: ${tmp}`
    },
    clearAllRegions() {
      this.vmRegionCodes = []
      this.allRegions = false
    },
    flipAllRegions() {
      this.$nextTick(() => {
        if (!this.allRegions) {
          this.vmRegionCodes = []
        } else {
          this.vmRegionCodes = this.irRegionCodes.slice()
        }
      })
      // store the current v-switch state in system state
      // this.$store.commit('vsQtrSalesbyCustomerFlag', this.allRegions)
      // this.$store.commit('vsQtrSalesbyCustomer', this.vmRegionCodes)
    },
    checkFlipRegion() {
      if (this.vmRegionCodes.filter((o) => o === false).length && this.allRegions) { this.allRegions = false }
    },
    loadAllRepNames() {
      this.ldSalesAgents = true
      // load the list of sales people from the database
      let list = []

      if (!this.$store.getters.isAdminAccess) {
        this.iSalesAgents.push({
          id: 0,
          text: 'You',
          value: this.$store.getters.salescode,
        })
        this.vmSalesAgents[0] = true
      } else {
        axios
          .get(`${this.$store.getters.NODEURL}/salespeople`, {
            withCredentials: true,
          })
          .then((response) => {
            let id = 0
            for (var k in response.data) {
              list.push({
                id: id++,
                text: `${response.data[k].First[0]}. ${response.data[k].Last}`,
                value: response.data[k].NavInitials,
              })
            }
          })
          .catch((err) => {
            console.log(err)
            this.iSalesAgents = []
          })
          .finally(() => {
            this.ldSalesAgents = false
            this.iSalesAgents = list
            this.iSalesAgentsCol1 = Math.round(this.iSalesAgents.length / 2)
          })
      }
    },
    swapUStoKG() {
      if (this.vsUSDorKG) {
        for (let i = 1; i <= 4; ++i) this.rollupHeaders[i].value = `Q${i}KG`
      } else {
        for (let i = 1; i <= 4; ++i) this.rollupHeaders[i].value = `Q${i}P`
      }
    },
    startLoadingAnimation() {
      this.rollupLoading = true
    },
    stopLoadingAnimation() {
      this.rollupLoading = false
    },
    refreshData() {
      this.load()
    },
    exportToCSV() {},
    expandDataRow(item) {
      if (!item.value) return
      // this.rollupSubItems = []
      this.startLoadingAnimation()
      axios
        .post(`${this.$store.getters.NODEURL}/item/projection/rollup/history`, { itemcore: item.item.Item }, { withCredentials: true } )
        .then((response) => { this.companySubItems[item.item.index] = response.data })
        .catch((err) => {
          if (err.response.status === 404) { this.snackbar(this.parseError(err.response.status)) }
        })
        .finally(() => { this.stopLoadingAnimation() })
    },
    load() {
      this.iRollupItems = []
      this.startLoadingAnimation()
      let vsSelectedCountries = []

      // build up an array of selected country codes
      for (var p in this.vmRegionCodes) {
        if (this.vmRegionCodes[p]) {
          vsSelectedCountries.push(this.irRegionCodes[p].value)
        }
      }

      let tmpType = []

      if (this.items_ing) tmpType.push('ING')
      if (this.items_flv) tmpType.push('FLV')

      axios
        .post(`${this.$store.getters.NODEURL}/item/projection/rollup`, {
          countries: vsSelectedCountries.join(','),
          itemtypes: tmpType.join(',')
          }, { withCredentials: true })
        .then((response) => {
          let tmp = [],
            o = null,
            index = 0
          for (var t in response.data) {
            o = response.data[t]
            if (o.Item.length >= 1) {
              o.index = index++
              tmp.push(...[o])
            }
          }
          this.rollupItems = tmp
        })
        .catch((err) => {
          console.log(err.response.status)
          if (err.response.status === 404) {
            this.snackbar(this.parseError(err.response.status))
          }
          throw err
        })
        .finally(() => {
          this.stopLoadingAnimation()
        })
    },
  },
}
</script>

<style>
 >>> #rollupTable table tr:nth-of-type(2n) td { background-color: #f4f4f4; }
 >>> #rollupTable .v-data-table__wrapper { height: calc(100vh - 275px) !important; }
 >>> #rollupTable >>> #companySubTable .v-data-table__wrapper { height: auto !important; }
 >>> #rollupTable >>> #companySubTable  table tr td { background-color: aaa; }
</style>
