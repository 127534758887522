const gm_annualSalesbyItemsHeaders =
[
    {
        text: "Item",
        align: "left",
        sortable: false,
        value: "item",
        class: "black--text font-weight-bold"
    },
    {
        text: "Description",
        align: "left",
        sortable: false,
        value: "desc",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd1",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd2",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd3",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd4",
        divider: true,
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg1",
        class: "black--text font-weight-bold blue lighten-5 text-no-wrap"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg2",
        class: "black--text font-weight-bold blue lighten-5 text-no-wrap"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg3",
        class: "black--text font-weight-bold blue lighten-5 text-no-wrap"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg4",
        class: "black--text font-weight-bold blue lighten-5 text-no-wrap"
    }
]

export default gm_annualSalesbyItemsHeaders