<template>
  <v-container fluid>
    <v-expansion-panels multiple accordion mandatory v-model="expansion_panel">
      <!-- -->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">Samples Request Form (IN DEVELOPMENT)</v-expansion-panel-header>
        <v-expansion-panel-content class="grey-striped">
          <!-- -->
          <v-card flat class="mt-4">
            <v-row>
              <v-col cols="12">

              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- -->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">Active Sample Requests (IN DEVELOPMENT)</v-expansion-panel-header>
        <v-expansion-panel-content class="grey-striped">
          <v-card flat class="mt-4">
            <v-row>
              <v-col cols="12">
                <!-- -->
                <v-toolbar flat>
                  <v-btn text outlined class="blue darken-2" elevation="0"> <v-icon color="white">mdi-refresh</v-icon> </v-btn>
                  <v-btn text outlined class="blue darken-2 ml-2" elevation="0"> <v-icon color="white">mdi-microsoft-excel</v-icon> </v-btn>
                  <v-btn text outlined class="blue darken-2 ml-2" elevation="0"> <v-icon color="white">mdi-adobe-acrobat</v-icon> </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="srf.search" label="Search" hide-details></v-text-field>
                </v-toolbar>
                <!-- -->
                <v-data-table :headers="srf.headers" :items="srf.items" :search="srf.search"
                              show-expand fixed-header :expanded.sync="srf.expanded"
                              item-key="id" @item-expanded="expand_row()">
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-3 ma-0" style="background-color:#f4f4f4;border:solid 1px #ddd;">
                      <v-row dense>
                        <v-col cols="8"> <v-text-field background-color="white" v-model="item.comment" dense outlined hide-details append-icon="mdi-pencil" label="Comments"></v-text-field> </v-col>
                        <v-col cols="2"> <v-text-field background-color="white" v-model="item.nbd" dense outlined hide-details append-icon="mdi-pencil" label="Need By Date"></v-text-field> </v-col>
                        <v-col cols="2"> <v-text-field background-color="white" v-model="item.fmi_code" dense outlined hide-details label="FMI CODE"></v-text-field> </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="8"> <v-text-field background-color="white" v-model="item.description" dense outlined hide-details append-icon="mdi-pencil" label="Description"></v-text-field> </v-col>
                        <v-col cols="2"> <v-text-field background-color="white" v-model="item.fema" dense outlined hide-details label="FEMA"> </v-text-field> </v-col>
                        <v-col cols="2"> <v-text-field background-color="white" v-model="item.cas" dense outlined hide-details label="CAS"></v-text-field> </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.supplier" dense outlined hide-details label="Supplier"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.supplier_number" dense outlined hide-details label="Supplier Number"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.quantity" dense outlined hide-details label="Quantity"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.units" dense outlined hide-details label="Units"></v-text-field> </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.price_usd" dense outlined hide-details label="Price USD"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.inco" dense outlined hide-details label="INFO"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.lead_time" dense outlined hide-details label="Lead Time"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.minimum" dense outlined hide-details label="Minimum Quantity"></v-text-field> </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.pack_size" dense outlined hide-details label="Pack Size"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.placed_initials" dense outlined hide-details label="Placed By"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.confirmed" dense outlined hide-details label="Confirmed"></v-text-field> </v-col>
                        <v-col cols="3"> <v-text-field background-color="white" v-model="item.qc_received" dense outlined hide-details label="QC Received"></v-text-field> </v-col>
                      </v-row>
                    </td>
                  </template>
                  <template v-slot:[`item.nbd`]="{ item }"> {{distance(item.nbd)}} </template>
                  <template v-slot:[`item.id`]="{ item }"> <v-chip label outlined small color="green">{{item.id}}</v-chip> </template>
                  <template v-slot:[`item.stage`]="{ item }"> <v-chip label outlined small color="blue">{{stage(item.stage)}}</v-chip> </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import formatDistance from 'date-fns/formatDistance'
import { helperMixin } from '../mixins/helper'
const MAX_STAGE = 4
export default {
  name: 'Sample Request',
  mixins: [helperMixin],
  data: () => ({
    expansion_panel: [0, 1],
    srf: {
      headers: [
        { text: "Request #", align: "left", sortable: true, value: "id" },
        { text: "Created By", align: "left", sortable: true, value: "created_by" },
        { text: "Requested", align: "left", sortable: true, value: "created_when" },
        { text: "Type", align: "left", sortable: true, value: "sample_type" },
        { text: "Category", align: "left", sortable: true, value: "sample_category" },
        { text: "Need By...", align: "left", sortable: true, value: "nbd" },
        { text: "Stage", align: "left", sortable: true, value: "stage" },
      ],
      items: [
        { id: 'SR07052022-01', created_by: 'Greg Merideth', created_when: '07/05/2022 11:04:01', sample_type: 'Alternate/New Vendor', sample_category: 'Vegetable Oils', comment: 'Society excited by cottage private an it esteems. Fully begin on by wound an. Girl rich in do up or both. At declared in as rejoiced of together. He impression collecting delightful unpleasant by prosperous as on. End too talent she object mrs wanted remove giving.', nbd: '9/1/2022', fmi_code: 'PK-123456', description: '', fema: '', cas: '', supplier:'', quantity: '', units: '', price_usd: '', inco: '', lead_time:'', minimum: '', pack_size: '', placed_initials: '', confirmed: '', stage: 0 },
        { id: 'SR07052022-02', created_by: 'Greg Merideth', created_when: '07/05/2022 13:15:36', sample_type: 'Preship', sample_category: 'Herbs, Spice, Seasonings', comment: '', nbd: '8/16/2022', fmi_code: 'PK-123456', description: '', fema: '', cas: '', supplier:'', quantity: '', units: '', price_usd: '', inco: '', lead_time:'', minimum: '', pack_size: '', placed_initials: '', confirmed: '', stage: 1 },
        { id: 'SR07062022-01', created_by: 'Greg Merideth', created_when: '07/06/2022 14:22:18', sample_type: 'Alternate/Existing Vendor', sample_category: 'Compressed Gases/Liquids', comment: '', nbd: '8/22/2022', fmi_code: 'PK-123456', description: '', fema: '', cas: '', supplier:'', quantity: '', units: '', price_usd: '', inco: '', lead_time:'', minimum: '', pack_size: '', placed_initials: '', confirmed: '', stage: 2 },
      ],
      expanded: [],
      search: '',
      dense: false,
    }
  }),
  beforeCreate () { },
  mounted() { },
  methods: {
    stage(e) {
      if(e >= 0 && e <= MAX_STAGE) return ['Created','Stage One','Stage Two','Stage Three','Completed'][e]
      else return 'Missing'
    },
    expand_row() { },
    distance(t) {
      if(!t) return ''
      return formatDistance(new Date(t), new Date(), { addSuffix: true })
    },
  },
  computed: {}
}
</script>

<style scoped>
 .grey-striped { background: repeating-linear-gradient(55deg,#f4f4f4 5px,#eee 25px); padding: 5px!important; margin:0px!important; }
</style>
