<template>
  <v-container>
    <!-- snackbar -->
    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
      </template>
    </v-snackbar>
    <!-- header row, details -->
    <v-row>
      <v-col cols="5">
        <v-text-field label="NBA Name" v-model="elements[1]" hide-details outlined readonly></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Sales Person" v-model="elements[5]" hide-details outlined readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Date Initiated" v-model="elements[6]" hide-details outlined readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="datePopUp" :close-on-content-click="false" offset-y min-width="290px" v-if="allowed['editNeedByDate']">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model = "dateFormatted" label="Need by Date" persistent-hint hint="mm/dd/yyyy" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="isodate" no-title>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="datePopUp = false">Cancel</v-btn>
            <v-btn text color="primary" @click="changeNeedbyDate(dateFormatted)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field v-else label="Need by Date" v-model="dateFormatted" hide-details outlined readonly></v-text-field>
      </v-col>
    </v-row>
    <!-- page tabs -->
    <v-tabs v-model="tabs" light color="blue" fixed-tabs show-arrows @change="changeTab()">
      <v-tab>Business Alert</v-tab>
      <v-tab>Additional</v-tab>
      <v-tab>Auditing</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <!-- data expansion headers -->
        <v-expansion-panels v-model="panelSetOne" accordion multiple focusable>
          <!-- CUSTOMER SERVICE -->
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Customer Service - Global
              <template v-slot:actions>
                <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-overlay z-index="2" absolute="absolute" v-if="nbaarchived"> 
                <v-card max-width="600" class="mx-auto" outlined color="transparent">
                  <v-card-title class="text-h2">ARCHIVED</v-card-title>
                </v-card>
              </v-overlay>
              <v-row>
                <v-col cols="3">
                  <v-switch color="green" class="ma-0 pt-2 mcap" v-model="elements[16]" @change="checkflag(16, 10)"
                    :loading="elementsaving[10].state" hint="Customer information verified?"
                    label="Verified?" :persistent-hint="true">
                  </v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." @change="globalUpdate($event, 1, 10)" dense outlined counter="4"
                    maxlength="4" v-model="initialsArray[10].value" :loading="initialsArray[10].loading"
                    :background-color="initialsArray[10].bgcolor" :error="initialsArray[10].error"
                    :disabled="initialsArray[10].disabled"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" @change="globalUpdate($event, 2, 10)" dense outlined counter="75"
                    maxlength="75" v-model="commentArray[10].value" :loading="commentArray[10].loading"
                    :background-color="commentArray[10].bgcolor" :error="commentArray[10].error"
                    :disabled="commentArray[10].disabled"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-switch color="green" class="ma-0 pt-2 mcap" v-model="elements[17]" @change="checkflag(17, 11)"
                    :loading="elementsaving[11].state" hint="Payment terms accepted?"
                    label="Terms Accepted?" :persistent-hint="true">
                  </v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." @change="globalUpdate($event, 1, 11)" dense outlined counter="4"
                    maxlength="4" v-model="initialsArray[11].value" :loading="initialsArray[11].loading"
                    :background-color="initialsArray[11].bgcolor" :error="initialsArray[11].error"
                    :disabled="initialsArray[11].disabled"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" @change="globalUpdate($event, 2, 11)" dense outlined counter="75"
                    maxlength="75" v-model="commentArray[11].value" :loading="commentArray[11].loading"
                    :background-color="commentArray[11].bgcolor" :error="commentArray[11].error"
                    :disabled="commentArray[11].disabled"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- SELLABLES -->
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Sellables <span class="caption ml-2"> - Select a sellable to access options</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-table-large</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="grey lighten-4">
              <!-- data table -->
              <v-data-table id="nbatable" :single-select="true" :headers="nbaTableHeaders" item-key="pID"
                :items="nbaTableItems" :items-per-page="45" @click:row="loadSellable"
                :footer-props="{ 'items-per-page-options': [45] }" :loading="nbaLoading">
                <template v-slot:item.initialkg="{ item }">{{toKilos(item.initialkg)}}</template>
                <template v-slot:item.annualkg="{ item }">{{toKilos(item.annualkg)}}</template>
                <template v-slot:item.abv="{ item }">{{toCurrency(item.abv)}}</template>
                <template v-slot:item.quotePrice="{ item }">{{toCurrency(item.quotePrice,2)}}</template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- DOC REQUIREMENTS -->
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Documentation Requirements
              <template v-slot:actions>
                <v-icon color="primary">mdi-library</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12">
                  <v-chip label v-for="(i,index) in 5" :key="index" color="success" class="mr-4" :disabled="!elements[documents[index].index]">
                    <v-icon v-if="elements[documents[index].index]" left>mdi-file-document</v-icon> {{documents[index].text}}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-chip label v-for="(i,index) in 4" :key="index" color="success" class="mr-4"  :disabled="!elements[documents[index + 5].index]">
                    <v-icon v-if="elements[documents[index + 5].index]" left>mdi-file-document</v-icon> {{documents[index + 5].text}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- R & R -->
          <v-expansion-panel :disabled="!(selectedSellable > 0)">
            <v-expansion-panel-header disable-icon-rotate>
              Research &amp; Development <span class="caption ml-2 blue--text" v-if="loadedSellableName.length">- {{loadedSellableName}}</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editResearch']" @change="flipswitch(0)" color="green" class="ma-0 pt-2 mcap"
                    hide-details v-model="checklist[0]" label="Formula Entered"></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 0)" dense outlined
                    counter="4" maxlength="4" v-model="initialsArray[0].value" :loading="initialsArray[0].loading"
                    :background-color="initialsArray[0].bgcolor" :disabled="initialsArray[0].disabled || !allowed['editResearch']"
                    :error="initialsArray[0].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 0)" dense outlined
                    counter="75" maxlength="75" v-model="commentArray[0].value" :loading="commentArray[0].loading"
                    :background-color="commentArray[0].bgcolor" :disabled="commentArray[0].disabled || !allowed['editResearch']"
                    :error="commentArray[0].error"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editResearch']" @change="flipswitch(1)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[1]" label="Retain Lot Number" hide-details></v-switch>
                </v-col>
                <v-col cols="2">
                  <v-text-field label="Lot #" type="text" @change="globalUpdate($event, 3, 12)" dense outlined counter="15" maxlength="15" v-model="initialsArray[12].value" :loading="initialsArray[12].loading" :background-color="initialsArray[12].bgcolor" :disabled="initialsArray[12].disabled || !allowed['editResearch']" :error="initialsArray[12].error"></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 1)" dense outlined counter="4" maxlength="4" v-model="initialsArray[1].value" :loading="initialsArray[1].loading" :background-color="initialsArray[1].bgcolor" :disabled="initialsArray[1].disabled || !allowed['editResearch']" :error="initialsArray[1].error"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 1)" dense outlined counter="75" maxlength="75" v-model="commentArray[1].value" :loading="commentArray[1].loading" :background-color="commentArray[1].bgcolor" :disabled="commentArray[1].disabled || !allowed['editResearch']" :error="commentArray[1].error"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- QUALITY CONTROL -->
          <v-expansion-panel :disabled="!(selectedSellable > 0)">
            <v-expansion-panel-header disable-icon-rotate>
              Quality Control <span class="caption ml-2 blue--text" v-if="loadedSellableName.length">- {{loadedSellableName}}</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editQuality']" @change="flipswitch(2)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[2]" label="QC Test Criteria Established" hide-details></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 2)" dense outlined counter="4" maxlength="4" v-model="initialsArray[2].value" :loading="initialsArray[2].loading" :background-color="initialsArray[2].bgcolor" :disabled="initialsArray[2].disabled || !allowed['editQuality']" :error="initialsArray[2].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 2)" dense outlined counter="75" maxlength="75" v-model="commentArray[2].value" :loading="commentArray[2].loading" :background-color="commentArray[2].bgcolor" :disabled="commentArray[2].disabled || !allowed['editQuality']" :error="commentArray[2].error"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editQuality']" @change="flipswitch(3)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[3]" label="Product Documentation Complete" hide-details></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 3)" dense outlined counter="4" maxlength="4" v-model="initialsArray[3].value" :loading="initialsArray[3].loading" :background-color="initialsArray[3].bgcolor" :disabled="initialsArray[3].disabled || !allowed['editQuality']" :error="initialsArray[3].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 3)" dense outlined counter="75" maxlength="75" v-model="commentArray[3].value" :loading="commentArray[3].loading" :background-color="commentArray[3].bgcolor" :disabled="commentArray[3].disabled || !allowed['editQuality']" :error="commentArray[3].error"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- PURCHASING -->
          <v-expansion-panel :disabled="!(selectedSellable > 0)">
            <v-expansion-panel-header disable-icon-rotate>
              Purchasing <span class="caption ml-2 blue--text" v-if="loadedSellableName.length">- {{loadedSellableName}}</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editPurchasing']" @change="flipswitch(4)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[4]" label="Raw Materials" hint="Raw Materials Sourced &amp; Pricing Updated?" :persistent-hint="true"></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 4)" dense outlined counter="4" maxlength="4" v-model="initialsArray[4].value" :loading="initialsArray[4].loading" :background-color="initialsArray[4].bgcolor" :disabled="initialsArray[4].disabled || !allowed['editPurchasing']" :error="initialsArray[4].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 4)" dense outlined counter="75" maxlength="75" v-model="commentArray[4].value" :loading="commentArray[4].loading" :background-color="commentArray[4].bgcolor" :disabled="commentArray[4].disabled || !allowed['editPurchasing']" :error="commentArray[4].error"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editPurchasing']" @change="flipswitch(5)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[5]" label="Packaging Materials" hint="Packaging Materials Sourced &amp; Pricing Updated?" :persistent-hint="true"></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 5)" dense outlined counter="4" maxlength="4" v-model="initialsArray[5].value" :loading="initialsArray[5].loading" :background-color="initialsArray[5].bgcolor" :disabled="initialsArray[5].disabled || !allowed['editPurchasing']" :error="initialsArray[5].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 5)" dense outlined counter="75" maxlength="75" v-model="commentArray[5].value" :loading="commentArray[5].loading" :background-color="commentArray[5].bgcolor" :disabled="commentArray[5].disabled || !allowed['editPurchasing']" :error="commentArray[5].error"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editPurchasing']" @change="flipswitch(6)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[6]" label="Procurement" hint="Procurement Lead Time for First Order?" :persistent-hint="true"></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 6)" dense outlined counter="4" maxlength="4" v-model="initialsArray[6].value" :loading="initialsArray[6].loading" :background-color="initialsArray[6].bgcolor" :disabled="initialsArray[6].disabled || !allowed['editPurchasing']" :error="initialsArray[6].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 6)" dense outlined counter="75" maxlength="75" v-model="commentArray[6].value" :loading="commentArray[6].loading" :background-color="commentArray[6].bgcolor" :disabled="commentArray[6].disabled || !allowed['editPurchasing']" :error="commentArray[6].error"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- CUSTOMER SERVICE -->
          <v-expansion-panel :disabled="!(selectedSellable > 0)">
            <v-expansion-panel-header disable-icon-rotate>
              Customer Service <span class="caption ml-2 blue--text" v-if="loadedSellableName.length">- {{loadedSellableName}}</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editCustomerService']" @change="flipswitch(7)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[7]" label="Product Information Setup Complete?" hide-details></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 7)" dense outlined counter="4" maxlength="4" v-model="initialsArray[7].value" :loading="initialsArray[7].loading" :background-color="initialsArray[7].bgcolor" :disabled="initialsArray[7].disabled || !allowed['editCustomerService']" :error="initialsArray[7].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 7)" dense outlined counter="75" maxlength="75" v-model="commentArray[7].value" :loading="commentArray[7].loading" :background-color="commentArray[7].bgcolor" :disabled="commentArray[7].disabled || !allowed['editCustomerService']" :error="commentArray[7].error"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- OPERATIONS -->
          <v-expansion-panel :disabled="!(selectedSellable > 0)">
            <v-expansion-panel-header disable-icon-rotate>
              Operations <span class="caption ml-2 blue--text" v-if="loadedSellableName.length">- {{loadedSellableName}}</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-switch :disabled="!allowed['editOperations']" @change="flipswitch(8)" color="green" class="ma-0 pt-2 mcap" v-model="checklist[8]" label="Raw Material Availabilty Checked?" hide-details></v-switch>
                </v-col>
                <v-col cols="1">
                  <v-text-field label="Init." type="text" @change="globalUpdate($event, 1, 8)" dense outlined counter="4" maxlength="4" v-model="initialsArray[8].value" :loading="initialsArray[8].loading" :background-color="initialsArray[8].bgcolor" :disabled="initialsArray[8].disabled || !allowed['editOperations']" :error="initialsArray[8].error"></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Comment" type="text" @change="globalUpdate($event, 2, 8)" dense outlined counter="85" maxlength="85" v-model="commentArray[8].value" :loading="commentArray[8].loading" :background-color="commentArray[8].bgcolor" :disabled="commentArray[8].disabled || !allowed['editOperations']" :error="commentArray[8].error"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- ATTACHMENTS -->
          <v-expansion-panel :disabled="!(selectedSellable > 0)">
            <v-expansion-panel-header disable-icon-rotate>
              Attachments <span class="caption ml-2 blue--text" v-if="loadedSellableName.length">- {{loadedSellableName}}</span>
              <template v-slot:actions>
                <v-icon color="primary">mdi-file-settings-outline</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="5">
                  <v-file-input v-model="uploadControl" clearable dense accept=".pdf,.xlsx,.xls" :error="uploadError"
                    @change="checkFile" :error-messages="uploadErrorMsg" error-count="1" :loading="uploadLoading"
                    show-size></v-file-input>
                </v-col>
                <v-col cols="2">
                  <v-btn v-show="(uploadControl != undefined && !uploadError) && !nbaarchived" class="elevation-0" color="success"
                    @click="upload()">
                    UPLOAD<v-icon right>mdi-paperclip</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-chip-group column active-class="white">
                    <v-chip class="mr-2" v-for="n in attachments" color="primary" :key="n.value" label
                      @click="linkToAttachment(n)">
                      <v-icon left>mdi-label</v-icon>{{n.text}} ({{formatBytes(n.size,0)}})
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>

      <v-tab-item>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-textarea label="Salesforce Note" class="text-body-2" readonly disabled outlined no-resize
              v-model="additionalInfo" rows="3" hint="Additional notes provided from Salesforce" persistent-hint></v-textarea>
            <v-divider class="ma-4"></v-divider>
            <v-textarea clear-icon label="Additional Information" rows="3" maxlength="350" counter="350"
              @change="updateUserNote" outlined no-resize v-model="userNote.value" :disabled="userNote.disabled"
              :loading="userNote.loading" :background-color="userNote.bgcolor"
              hint="Use the box above to add additional notes to this new business alert.  When archived, these notes will be broadcast to email and Slack."
              persistent-hint></v-textarea>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-btn color="red" @click="archiveDialog = !archiveDialog" dark>Archive Business Alert<v-icon right dark>
                mdi-file-document-edit-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <v-data-table id="audittable" :headers="auditHeaders" :items="auditItems" :items-per-page="10"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'}">
              <template v-slot:top>
                <v-toolbar color="grey lighten-4 elevation-0" light dense>
                  <v-toolbar-title>Audit Trail</v-toolbar-title>
                  <v-divider class="mx-2 grey" inset vertical></v-divider>
                  <v-btn outlined text class="ml-2" color="white" @click="loadAuditTrail()">
                    <v-icon color="blue">mdi-refresh</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs-items>

    <ArchiveDiaglog @archiveAction="archiveAction" v-if="archiveDialog" v-bind:pid="nbapID" />

  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import async from 'async'
import axios from 'axios'
const ArchiveDiaglog = () => import( /* webpackChunkName: "ArchiveDiaglog" */ '../components/ArchiveDialog')
import {
  helperMixin
} from '../mixins/helper'

const AUDIT_BUSINESS_ALERT = 110
const vsAccountVerified = 7
const vsPaymentAccepted = 9
const INITIALS_ARRAY = 1
const COMMENT_ARRAY = 2
const SPECIAL_LOT_NUMBER_ARRAY = 3
const TEXT_ENTRY_ARRAY_LENGTH = 12
const LOT_NUMBER_INDEX = 12
const ADDITIONAL_INFO_FIELD = 22,
  USERNOTE_FIELD = 23,
  ARCHIVE_FLAG = 24

const VERIFIED_FIELDS = 10
const TERMS_FIELD = 11

export default {
  components: {
    ArchiveDiaglog
  },
  mixins: [helperMixin],
  name: "NewBusinessAlertDetails",
  data: () => ({
    tabs: null,
    nbaLoading: false,
    nbaSearch: '',
    // todo make this a vue class instead
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    rightdrawer: false,
    // main display
    salesPerson: '',
    dateInitiated: '',
    uploadControl: null,
    uploadError: undefined,
    uploadLoading: false,
    uploadErrorMsg: '',
    nbaTableHeaders: [{
        text: "Sample Name",
        align: "left",
        sortable: false,
        value: "name",
        class: "black--text font-weight-bold"
      },
      {
        text: "Item Code",
        align: "left",
        sortable: false,
        value: "itemCode",
        class: "black--text font-weight-bold"
      },
      {
        text: "Description",
        align: "left",
        sortable: false,
        value: "description",
        class: "black--text font-weight-bold"
      },
      {
        text: "Quote Basis",
        align: "left",
        sortable: false,
        value: "quotebasis",
        class: "black--text font-weight-bold"
      },
      {
        text: "Intial Qty (KGs)",
        align: "left",
        sortable: false,
        value: "initialkg",
        class: "black--text font-weight-bold"
      },
      {
        text: "Annual Qty (KGs)",
        align: "left",
        sortable: false,
        value: "annualkg",
        class: "black--text font-weight-bold"
      },
      {
        text: "Quoted Price",
        align: "left",
        sortable: false,
        value: "quotePrice",
        class: "black--text font-weight-bold"
      },
      {
        text: "Order Frequency",
        align: "left",
        sortable: false,
        value: "orderFrequency",
        class: "black--text font-weight-bold"
      },
      {
        text: "Annual Business Value",
        align: "left",
        sortable: false,
        value: "abv",
        class: "black--text font-weight-bold"
      }
    ],
    nbaTableItems: [],
    auditHeaders: [{
        text: "Description",
        align: "left",
        sortable: false,
        value: "description",
        class: "black--text font-weight-bold"
      },
      {
        text: "Sales Manager",
        align: "left",
        value: "salesmgr",
        class: "black--text font-weight-bold"
      },
      {
        text: "Occured",
        align: "left",
        value: "created",
        class: "black--text font-weight-bold"
      }
    ],
    auditItems: [],
    checklist: [false, false, false, false, false, false, false, false, false],
    checklistOriginal: [],
    initialsArray: [],
    commentArray: [],
    editing: true,
    panelSetOne: [0, 1],
    panelcount: 10,
    attachments: [],
    docs: [],
    expandOrCollapse: false,
    allowed: [],
    auditLoaded: false,
    nbapID: 0,
    elements: [],
    elementsaving: [],
    // originalElements: [],
    selectedSellable: 0,
    // pendingChanges: false,
    // inerror: false,
    loadedSellableName: '',
    loadedSellableItemCode: '',
    archiveDialog: false,
    archiveNote: '',
    additionalInfo: '',
    userNote: {
      value: '',
      error: false,
      loading: false,
      bgcolor: undefined,
      disabled: false
    },
    nbaarchived: false,
    documents: [{
        text: 'Specification Sheet',
        index: 7
      },
      {
        text: 'SDS',
        index: 8
      },
      {
        text: 'Food Safety Certificate',
        index: 9
      },
      {
        text: 'Nutritional Statement',
        index: 10
      },
      {
        text: 'Ingredient Declaration',
        index: 11
      },
      {
        text: 'Kosher Certificate',
        index: 12
      },
      {
        text: 'Halal Certificate',
        index: 13
      },
      {
        text: 'Other Documents',
        index: 14
      },
      {
        text: 'Allergen Declaration',
        index: 15
      }
    ],
    datePopUp: false,
    dateFormatted: null,
    isodate: null
  }),
  beforeMount: function () {
    for (let i = 0; i < TEXT_ENTRY_ARRAY_LENGTH; ++i) {
      this.initialsArray.push({
        value: '',
        error: false,
        loading: false,
        bgcolor: undefined,
        disabled: false
      })
      this.commentArray.push({
        value: '',
        error: false,
        loading: false,
        bgcolor: undefined,
        disabled: false
      })
      this.elementsaving.push({
        state: false
      })
    }
    // push one for the LOT # field thrown in
    this.initialsArray.push({
      value: '',
      error: false,
      loading: false,
      bgcolor: undefined,
      disabled: false
    })
  },
  mounted: function () {
    if (this.$route.params.pid === undefined || this.$route.params.pid === null) {
      console.error('pid missing, nothing to do.')
      this.$router.push("/nba").catch((e) => {
        console.log(e)
      })
    } else {
      //todo verify this number as being value
      this.nbapID = this.$route.params.pid
      this.load()
      //todo make sure this is a valid array
      this.allowed = this.$store.getters.nbaPermissions
      this.checklistOriginal = [...this.checklist] // check for changes
    }
    // injection from dashboard.vue
    this.$root.$on('tabs', () => {
      this.expansionTabs()
    })
  },
  computed: {},
  methods: {
    changeNeedbyDate: function () {
      this.datePopUp = false
      axios.post(`${this.$store.getters.NODEURL}/api/1/alert/update/nbd/${this.nbapID}/${new Date(this.dateFormatted).getTime()}`, {
          withCredentials: true
        })
        .then((result) => {
          if(result.data.data === 'ok') {
            this.snackbar({ msg: "Need by date updated.", color: "success" })
          } else {
            this.snackbar({ msg: "Need by date failed to update, check the logs.", color: "error" })
          }
        })
        .catch((err) => {
          this.snackbar({ msg: "Need by date failed to update, check the logs.", color: "error" })
        })
        .finally(() => { })
    },
    range: function (start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    },
    updateUserNote: function () {

      this.userNote.loading = true
      this.userNote.disabled = true
      this.userNote.bgcolor = 'light-green lighten-4'

      axios.post(`${this.$store.getters.NODEURL}/api/1/alert/${this.nbapID}/usernote`, {
          text: this.userNote.value
        }, {
          withCredentials: true
        })
        .then((result) => {

        })
        .catch((err) => {
          console.warn(err)
        })
        .finally(() => {
          setTimeout(() => {
            this.userNote.loading = false
            this.userNote.disabled = false
            this.userNote.bgcolor = undefined
          }, 1000)
        })
    },
    checkFile: function (file) {
      if (file === null || file === undefined) {
        this.uploadError = false
        this.uploadErrorMsg = ''
        return
      }
      for (var v in this.attachments) {
        if (file.name === this.attachments[v].text || file.size === this.attachments[v].size) {
          this.uploadError = true
          this.uploadErrorMsg = 'That file already exists for this sellable.'
        } else {
          this.uploadError = false
          this.uploadErrorMsg = ''
        }
      }
    },
    formatBytes: function (a, b = 2) {
      if (0 === a) return "0 Bytes";
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024))
      return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    },
    stringtocolor: function (str) {
      var hash = 0
      var colour = '#'
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      for (let i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    },
    fieldCheck(index, which, elementIndex, lotCheck = false) {

      if (this.nbaarchived) {
        this.snackbar({
          msg: "Archived Alerts are Read-Only",
          color: "error"
        })
        return
      }

      let i = this.initialsArray[index],
        c = this.commentArray[index],
        l = this.initialsArray[LOT_NUMBER_INDEX]

      i.value = i.value || ''
      c.value = c.value || ''
      l.value = l.value || ''

      // special check for the lot # field
      if (lotCheck) {
        if (l.value.length === 0) {
          this.initialsArray[LOT_NUMBER_INDEX].error = true
          setTimeout(() => {
            this.$set(this.checklist, index, false)
            this.initialsArray[LOT_NUMBER_INDEX].error = false
          }, 1500)
          return false
        } else {
          // if the switch is on, disable, else, enable
          this.initialsArray[LOT_NUMBER_INDEX].disabled = this.checklist[index]
        }
      }
      // if either of the two fields are blank, flash them, set the error then clear it
      if (i.value.length === 0 || c.value.length === 0) {
        this.initialsArray[index].error = true
        this.commentArray[index].error = true
        setTimeout(() => {
          if (which) this.$set(this.elements, elementIndex, false)
          else this.$set(this.checklist, index, false)
          this.initialsArray[index].error = false
          this.commentArray[index].error = false
        }, 1500)
        return false
      } else {
        // if the switch is on, disable, else, enable
        if (which) {
          this.initialsArray[index].disabled = this.elements[elementIndex]
          this.commentArray[index].disabled = this.elements[elementIndex]
        } else {
          this.initialsArray[index].disabled = this.checklist[index]
          this.commentArray[index].disabled = this.checklist[index]
        }
      }
      return true
    },
    flipswitch: function (index) {

      if (this.nbaarchived) {
        this.snackbar({
          msg: "Archived Alerts are Read-Only",
          color: "error"
        })
        return
      }

      if (!this.fieldCheck(index, 0, true, index === 1 ? true : false)) return

      axios.post(`${this.$store.getters.NODEURL}/api/1/alert/update/s/${this.selectedSellable}/${index}`, // update status flag of the given sellable
          {
            withCredentials: true
          })
        .then((result) => {})
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {})
    },
    archiveAction: function (data) {
      axios.post(`${this.$store.getters.NODEURL}/api/1/alert/archive/${this.nbapID}`, {
          finalnote: data,
          customer: this.elements[1]
        }, {
          withCredentials: true
        })
        .then((result) => {
          this.snackbar({
            msg: 'Business Alert Archived.',
            color: 'success'
          })
          setTimeout(() => {
            this.$router.push('/nba')
          }, 2500);
        })
        .catch((err) => {
          this.snackbar({
            msg: 'Failed to Archive.',
            color: 'error'
          })
          console.warn(err)
        })
        .finally(() => {})
    },
    globalUpdate: function (value, element, index) {

      if (this.nbaarchived) {
        this.snackbar({
          msg: "Archived Alerts are Read-Only",
          color: "error"
        })
        return
      }

      let initials = ''
      // were handling the initials text fields here
      if (element === INITIALS_ARRAY) {
        this.initialsArray[index].disabled = true // disable the field
        this.initialsArray[index].loading = true // show the loading animation
        for (let i in this.initialsArray) {
          initials += `${this.initialsArray[i].value},`
        } // build a CSV version of the initials array
        initials = initials.slice(0, -1)
        //* post update / initials / NBA_ID / array index
        axios.post(`${this.$store.getters.NODEURL}/api/1/alert/update/i/${this.nbapID}/${index}`, {
            data: initials,
            sellableID: this.selectedSellable
          }, {
            withCredentials: true
          })
          .then((result) => {
            this.initialsArray[index].disabled = false
            this.initialsArray[index].bgcolor = 'green lighten-4'
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.initialsArray[index].bgcolor = undefined
              this.initialsArray[index].loading = false
            }, 1500)
          })
      } else if (element == COMMENT_ARRAY) { // else comment post
        this.commentArray[index].disabled = true
        this.commentArray[index].loading = true
        axios.post(`${this.$store.getters.NODEURL}/api/1/alert/update/c/${this.nbapID}/${index}`, {
            data: this.commentArray[index].value,
            sellableID: this.selectedSellable,
            sellableName: `${this.loadedSellableName} (${this.loadedSellableItemCode})`
          }, {
            withCredentials: true
          })
          .then((result) => {
            this.commentArray[index].disabled = false
            this.commentArray[index].bgcolor = 'green lighten-4'
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.commentArray[index].bgcolor = undefined
              this.commentArray[index].loading = false
            }, 1500)
          })
      } else if (element == SPECIAL_LOT_NUMBER_ARRAY) {
        this.initialsArray[index].disabled = true
        this.initialsArray[index].loading = true
        axios.post(`${this.$store.getters.NODEURL}/api/1/alert/update/l/${this.nbapID}/${this.selectedSellable}`, {
            lotn: this.initialsArray[index].value
          }, {
            withCredentials: true
          })
          .then((result) => {
            this.initialsArray[index].disabled = false
            this.initialsArray[index].bgcolor = 'green lighten-4'
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.initialsArray[index].bgcolor = undefined
              this.initialsArray[index].loading = false
            }, 1500)
          })
      }
    },
    checkflag: function (index, vmodel) {

      if (this.nbaarchived) {
        this.snackbar({
          msg: "Archived Alerts are Read-Only",
          color: "error"
        })
        return
      }

      if (!this.fieldCheck(vmodel, 1, index, false)) return

      this.elementsaving[vmodel].state = true
      let idx = vmodel == VERIFIED_FIELDS ? 1 : 2 // set the update index to 1 or 2 based on the CS v-switch picked

      axios.post(`${this.$store.getters.NODEURL}/api/1/alert/update/f/${this.nbapID}/${idx}`, {
          withCredentials: true
        })
        .then((result) => {
          if (result.data.nbaID == this.nbapID) {
            if (result.data.newvalue.FLAG === true) { // bit flag flipped top true
              this.initialsArray[vmodel].disabled = true
              this.commentArray[vmodel].disabled = true
            } else { // flag set to false
              this.initialsArray[vmodel].disabled = false
              this.commentArray[vmodel].disabled = false
            }
            // disable the loading animation on the control
            setTimeout(() => {
              this.elementsaving[vmodel].state = false
            }, 1500)
          } else {
            this.elementsaving[vmodel].state = false
            this.snackbar({
              msg: "Failed to update setting, contact support.",
              color: "error"
            })
          }
        })
        .catch((err) => {
          this.snackbar({
            msg: "Failed to update setting, contact support.",
            color: "error"
          })
          this.$set(this.elements, index, false)
          this.initialsArray[vmodel].disabled = false
          this.commentArray[vmodel].disabled = false
          this.elementsaving[vmodel].state = false
        })
        .finally(() => {})
    },
    loadSellable: function (item, row) {
      row.select(true)
      axios.get(`${this.$store.getters.NODEURL}/api/1/alert/sellable/${item.pID}`, {
          withCredentials: true
        })
        .then((result) => {

          this.attachments = [] // clear out any attachment list

          let flags = result.data[0]
          let __attachments = result.data[1] || []

          this.checklist = flags.checkboxes.split(',').map(v => v === '0' ? false : true) // map flags to existing checkbox fields
          let __init = flags.initials.split(',')

          // load up the initials and comments array
          for (let i = 0; i < __init.length; ++i) {
            this.initialsArray[i].value = __init[i]
            this.commentArray[i].value = flags[`comment${i+1}`]
          }
          // set switch flags
          for (let i = 0; i < __init.length; ++i) {
            if (this.checklist[i]) {
              this.initialsArray[i].disabled = this.commentArray[i].disabled = true
              if (i === 1) {
                this.initialsArray[LOT_NUMBER_INDEX].disabled = true
              }
            }
          }

          this.initialsArray[LOT_NUMBER_INDEX].value = flags['lotnumber']

          this.selectedSellable = item.pID // set the selected sellable ID value
          this.loadedSellableName = `${item.description} (${item.itemCode})` // add it to the headers of each v-panel
          this.loadedSellableItemCode = item.itemCode // and the item code
          this.attachments = __attachments
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {})
    },
    changeTab: function () {
      if (this.tabs !== undefined) {
        if (this.tabs === 1 && !this.auditLoaded) {
          this.loadAuditTrail()
          this.auditLoaded = true
        }
      }
    },
    loadAuditTrail: function () {
      axios.get(`${this.$store.getters.NODEURL}/api/1/audit/${AUDIT_BUSINESS_ALERT}/${this.nbapID}`, {
          withCredentials: true
        })
        .then((result) => {
          this.auditItems = result.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    rdColor: function (index, shouldTotal) {
      let t = 0
      for (let i in index) {
        t += this.checklist[index[i]] == true ? 1 : 0
      }
      return t == shouldTotal ? 'green--text' : 'red--text'
    },
    expansionTabs: function () {
      if (this.panelSetOne.length === 0) {
        if (this.selectedSellable > 0) {
          this.panelSetOne = [...Array(this.panelcount).keys()].map((k, i) => i)
        } else {
          this.panelSetOne = [0, 1, 2]
        }
      } else {
        this.panelSetOne = []
      }
    },
    addFile: function (e) {

      if (this.nbaarchived) {
        this.snackbar({
          msg: "Archived Alerts are Read-Only",
          color: "error"
        })
        return
      }

      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return ([...droppedFiles]).forEach(f => {
        this.uploadFilename1 = f
      })
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    load: function () {
      async.waterfall([
        // FUNCTION ONE - LOAD NBA Details
        async.apply(function (that, callback) {
            axios.get(`${that.$store.getters.NODEURL}/api/1/alert/${that.nbapID}`, {
                withCredentials: true
              })
              .then((result) => {

                const nbaObject = result.data[0][0]

                let recent = result.data[1],
                  editors = [],
                  me = that.$store.getters.salescode

                for (let p in recent) {
                  if (recent[p].nav !== me)
                    editors.push(recent[p].nav)
                }

                that.$store.commit('nbaEditingUsers', editors)

                let i = 0

                for (var k in nbaObject) {
                  that.elements[i++] = nbaObject[k]
                }
                // that.originalElements = [...that.elements]
                // special handling for the customer service global options
                that.initialsArray[VERIFIED_FIELDS].value = nbaObject['F10initials']
                that.commentArray[VERIFIED_FIELDS].value = nbaObject['F10comment']
                that.initialsArray[TERMS_FIELD].value = nbaObject['F11initials']
                that.commentArray[TERMS_FIELD].value = nbaObject['F11comment']

                // todo fix this
                that.initialsArray[VERIFIED_FIELDS].disabled = that.elements[16]
                that.commentArray[VERIFIED_FIELDS].disabled = that.elements[16]
                that.initialsArray[TERMS_FIELD].disabled = that.elements[17]
                that.commentArray[TERMS_FIELD].disabled = that.elements[17]
                that.dateFormatted = that.formatDate(new Date(that.elements[4]).toISOString().substr(0, 10))
                that.isodate = new Date(that.elements[4]).toISOString().substr(0, 10)

                // additional info field
                that.additionalInfo = that.elements[ADDITIONAL_INFO_FIELD]
                that.userNote.value = that.elements[USERNOTE_FIELD]
                that.nbaarchived = that.elements[ARCHIVE_FLAG]
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                callback(null, that)
              })
          }, this),
          // FUNCTION TWO - LOAD NBA Sellables - pass in the NBA ID code
          function (that, callback) {
            that.nbaLoading = true
            // /api/1/alert/:alertID/sellables/infull
            axios.get(`${that.$store.getters.NODEURL}/api/1/alert/${that.nbapID}/sellables/infull`, {
                withCredentials: true
              })
              .then((result) => {
                that.nbaTableItems = result.data
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                that.nbaLoading = false
                callback(null)
              })
          }
      ], (error, result) => {
        if (error) {
          console.error(error)
        }
      })
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    linkToAttachment: function (e) {
      axios.get(`${this.$store.getters.NODEURL}/attachment/${e.value}`, {
          responseType: 'arraybuffer'
        }, {
          withCredentials: true
        })
        .then((response) => {
          let filesaver = require('file-saver')
          filesaver.saveAs(new Blob([response.data]), e.text)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    upload: function (area) {

      if (this.nbaarchived) {
        this.snackbar({
          msg: "Archived Alerts are Read-Only",
          color: "error"
        })
        return
      }

      for (var v in this.attachments) {
        if (this.uploadControl.name === this.attachments[v].text || this.uploadControl.size === this.attachments[v].size) {
          this.snackbar({
            msg: "This file has already been uploaded to this sellable.",
            color: "error"
          })
          this.uploadControl = null
          return
        }
      }
      var formData = new FormData()
      formData.append('uploaded', this.uploadControl)
      formData.append('pID', this.nbapID)
      formData.append('sID', this.selectedSellable)
      //axios.post(`${this.$store.getters.NODEURL}/api/1/upload`, formData, {
      axios.post(`${this.$store.getters.NODEURL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }, {
          withCredentials: true
        })
        .then((response) => {
          if (response.status === 200) {
            this.snackbar({
              msg: `File ${this.uploadControl.name} uploaded.`,
              color: "success"
            })
            this.attachments.push({
              text: this.uploadControl.name,
              value: response.data.filename,
              size: this.uploadControl.size
            })
            this.uploadControl = null
          } else {
            this.snackbar({
              msg: "File failed to upload.",
              color: "error"
            })
          }
        })
        .catch((err) => {
          this.snackbar({
            msg: `File failed to upload ${err}`,
            color: "error"
          })
          this.uploadControl = null
          console.error(err)
        })
        .finally(() => {})
    }
  },
  watch: {
    isodate(val) {
      this.dateFormatted = this.formatDate(this.isodate)
    },
  }
}
</script>

<style scoped>
 .mcap >>> label { font-size: 14px!important; }
 .v-expansion-panel--active > .v-expansion-panel-header { min-height:32px!important }
 </style>