<template>
  <div id="app">
    <v-app>
      <v-snackbar top centered v-model="snack.model" :color="snack.color" dismissible content-class="overline" elevation="4">{{snack.message}}
         <template v-slot:action="{ attrs }"><v-btn color="yellow lighten-3" text small v-bind="attrs" @click="snack.model = false">Close</v-btn></template>
      </v-snackbar>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import { EventBus } from './components/EventBus'
export default {
  name: "App",
  mounted: function () { },
  data: () => ({ snack: { model: false, message: '', color: 'black white--text' } }),
  methods: {
    alert (message, color) {
      this.snack.message = message
      this.snack.color = color || 'success darken-2 white--text'
      this.snack.model = true
    }
  },
  created () {
    EventBus.$on('notify', (e) => { this.alert(e.message, e.color) })
    EventBus.$on('error', (e) => { this.alert(e.message, 'red darken-2 white--text') })
  }
}
</script>
<style>
</style>