<template>
  <v-container fluid>
    <v-expansion-panels multiple accordion v-model="expansion_panel">
      <!-- -->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">Raw Material Calculator (IN DEVELOPMENT)</v-expansion-panel-header>
        <v-expansion-panel-content class="grey-striped">
          <!-- -->
          <v-card flat class="mt-4">
            <v-card-text>
            <v-row>
              <v-col cols="2">
                <v-text-field dense outlined v-model="search" label="Search" @change="search_pk_values"
                              persistent-hint hint="Search by keyword"
                              append-outer-icon="mdi-magnify"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete outlined dense
                                v-model="select"
                                :items="pk_search.items"
                                persistent-hint
                                :hint="pk_search.results"
                                :error="pk_search.error"
                                item-text="value"
                                :loading="pk_search.loading"
                                hide-selected
                                append-outer-icon="mdi-content-copy" @click:append-outer="formula_id = select">
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.value"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action> $ {{item.cost}} </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field dense outlined
                              label="Formula ID"
                              v-model="formula_id"
                              persistent-hint hint="Enter complete formula Id"
                              append-outer-icon="mdi-database"
                              @click:append-outer="load_pk_formula"
                              @change="load_pk_formula"></v-text-field>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
      <v-expansion-panel-header class="font-weight-bold">Formula Breakdown (IN DEVELOPMENT)</v-expansion-panel-header>
        <v-expansion-panel-content class="grey-striped">
          <!-- -->
          <v-card flat class="mt-4">
            <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="rmc.headers" :items="rmc.items" :search="rmc.search" dense
                              :loading="rmc.loading" :single-expand="true" show-expand :expanded.sync="expanded"
                              @item-expanded="item_expanded()">
                    <template v-slot:[`item.subs`]="{ item }"><div v-if="item.subs.length">{{item.subs.length}} sub-components</div></template>
                    <template v-slot:[`item.price`]="{ item }">$ {{cvt(item.price)}}</template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" style="border:solid 1px #444;">
                          {{item.subs}}
                      </td>
                    </template>
                </v-data-table>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
     </v-expansion-panels>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios'
import { helperMixin } from '../mixins/helper'
import { EventBus } from '../components/EventBus.js'
import formatDistance from 'date-fns/formatDistance'

const MAX_STAGE = 4
export default {
  name: 'RMC',
  mixins: [helperMixin],
  data: () => ({
    expanded: [],
    rmc: {
      search: '',
      items: [],
      headers: [
        { text: "Component ID", align: "left", sortable: true, value: "id" },
        { text: "Current Version", align: "left", sortable: false, value: "version" },
        { text: "Cost", align: "right", sortable: true, value: "price" },
      ],
      loading: false,
    },
    search: null,
    select: null,
    formula_id: 'PK-07675',
    expansion_panel: [0,1],
    pk_search_sync: null,
    pk_search: {
      advanced_search_active: false,
      results: 'No Results',
      model: '',
      items: [],
      timer: null,
      loading: false,
      error_messages: [],
      error: false,
    }
  }),
  beforeCreate () { },
  mounted() { },
  methods: {
    cvt(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0, })
    },
    item_expanded() {
      console.log(this.expanded)
    },
    distance(t) { return !t ? '' : formatDistance(new Date(t), new Date(), { addSuffix: true }) },
    load_pk_formula() {
      this.rmc.loading = true
      axios.get(`${this.$store.getters.NODEURL}/formula/explode/${this.formula_id}`, { withCredentials: true })
           .then((result) => {
              this.formula_id = ''
              this.rmc.items = []
              let d = result.data.values
              if(d.length) {
                d.forEach(r => {
                  this.rmc.items.push({ id: r.item, version: r.v, price: r.price, desc: r.desc })
                })
              }
            })
           .catch((err) => { console.warn(err) })
           .finally(() => {
            this.rmc.loading = false
           })
    },
    search_pk_values() {
      if(!this.search) return
      this.pk_search.loading = true
      this.pk_search.error_messages = []
      this.pk_search.items = []
      axios.post(`${this.$store.getters.NODEURL}/formula/com/query`, { query: this.search }, { withCredentials: true })
        .then(response => {
          // console.log(response.data.results.length)
          if(response.data.results.length > 0) {
            let i = 0
            this.pk_search.items = response.data.results.map(r => ({ index: i++, value: r.value, text: r.text, cost: r.cost }))
            this.pk_search.results = `${this.pk_search.items.length} Results`
          } else {
            this.pk_search.model = ''
            this.pk_search.results = `No Results`
          }
        })
        .catch(e => {
          console.error(e)
          EventBus.$emit('error', { message: `Search Failed - 52` })
          this.pk_search_items = []
          this.pk_search.error_messages.push('database error')
        })
        .finally(() => { this.pk_search.loading = false })
    },
  },
  computed: {}
}
</script>

<style scoped>
 .grey-striped { background: repeating-linear-gradient(55deg,#f4f4f4 5px,#eee 25px); padding: 5px!important; margin:0px!important; }
</style>
