const ar_ProjectionRollupHeaders =
[
    {
        text: 'Item',
        align: 'left',
        sortable: true,
        value: 'Item',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q1 Pro',
        align: 'right',
        value: 'Q1P',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q2 Pro',
        align: 'right',
        value: 'Q2P',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q3 Pro',
        align: 'right',
        value: 'Q3P',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q4 Pro',
        align: 'right',
        value: 'Q4P',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q1 Act',
        align: 'right',
        value: 'Q1A',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q2 Act',
        align: 'right',
        value: 'Q2A',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q3 Act',
        align: 'right',
        value: 'Q3A',
        class: 'black--text font-weight-bold'
    },
    {
        text: 'Q4 Act',
        align: 'right',
        value: 'Q4A',
        class: 'black--text font-weight-bold'
    }
]

export default ar_ProjectionRollupHeaders