<template>
  <v-container fluid>

    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer v-model="drawer" absolute temporary right width="400" @transitionend="watchDrawer($event)">
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allLocations" block @click="flipAllLocations" dark
        class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allLocations?'No':'All'}} Locations
      </v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectLocationArray">
            <v-chip v-for="t in locationArray" :key="t.id" :small="!vs_button_larger" label
              :disabled="canHazPermission(t.idx)" active-class="success">
              <v-avatar left>
                <v-icon small v-if="selectLocationArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                </v-icon>
              </v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allRegionsFlag" block @click="flipAllRegions" dark
        class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allRegionsFlag?'No':'All'}} Regions
      </v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectedRegionCodeArray">
            <v-chip v-for="t in regionCodeArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
              <v-avatar left>
                <v-icon small v-if="selectedRegionCodeArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                </v-icon>
              </v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <div v-if="$store.getters.isManager" class="mt-2">
        <v-btn color="blue-grey darken-2" elevation="0" v-model="allAgentsFlag" block @click="flipAllAgents" dark
          class="rounded-0">
          <v-icon left color="blue-grey">mdi-label</v-icon> {{allAgentsFlag?'No':'All'}} Agents
        </v-btn>
        <v-row>
          <v-col md="10" offset-md="1">
            <v-chip-group active-class="primary--text" column multiple v-model="selectedSalesAgentArray">
              <v-chip v-for="t in salesAgentArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
                <v-avatar left>
                  <v-icon small v-if="selectedSalesAgentArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                  </v-icon>
                </v-avatar>
                {{ t.text }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col md="10" offset-md="1">
            <v-btn @click="load(false)" outlined color="green" block small>Search <v-icon right color="green" small>
                mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-navigation-drawer>

    <v-row>
      <v-col cols="12">

        <v-toolbar flat>
          <v-toolbar-title>Quarterly by Customer</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-select :items="periodyears" v-model="selectYear2" hide-details class="input-yearselect mr-2" background-color="yellow lighten-5"></v-select>
          <v-select :items="periodyears" v-model="selectYear1" hide-details class="input-yearselect mr-2" background-color="yellow lighten-5"></v-select>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-btn @click="load(false)" text outlined class="blue darken-2" elevation="0"> <v-icon color="white">mdi-refresh</v-icon></v-btn>
          <v-btn @click="exportToCSV" class="blue darken-2 ml-2" elevation="0"> <v-icon color="white" :right="$store.getters.buttonDetails">mdi-microsoft-excel</v-icon> </v-btn>
          <v-btn @click="exportToPDF" class="blue darken-2 ml-2" elevation="0"> <v-icon color="white">mdi-adobe-acrobat</v-icon> </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="csearch" label="Search" hide-details></v-text-field>
          <v-btn small depressed @click="drawer = !drawer" class="ml-3 white"> <v-icon color="indigo">mdi-menu</v-icon> </v-btn>
        </v-toolbar>

        <v-data-table id="quaterlysalesbycustomer" ref="historytable1" :headers="hSalesbyQuarter"
          :items="itemsCompanies" class="caption" :loading="customerloading" item-key="id" fixed-header
          hide-default-footer :single-expand="true" show-expand :dense="$store.getters.preference_dense"
          :expanded.sync="expanded" @item-expanded="rowExpand" :search="csearch"
          :footer-props="{ 'items-per-page-options': [-1] }">

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length"
              style="border-top:solid 4px #444;border-bottom:solid 4px #444;background-color:#444;" class="pa-3 ma-0">
              <v-data-table id="itemhistorytable" :headers="hItemsbyQuarter" :items="iItemsbyQuarter"
                :custom-sort="customSort" class="elevation-4 caption font-weight-bold" item-key="pid" dense
                hide-default-footer :footer-props="{ 'items-per-page-options': [-1] }">
                <template v-slot:top>
                  <v-toolbar color="indigo lighten-2 elevation-0" dark dense>
                    <v-toolbar-title>{{item.name}}</v-toolbar-title>
                  </v-toolbar>
                </template>
                <!-- add country name to description -->
                <template v-slot:item.desc="{ item }">
                  <span v-if="item.pid%2==0">{{item.desc}} <v-chip color="green lighten-4" x-small>{{item.Company}}
                    </v-chip></span>
                </template>
              </v-data-table>
              <span ref="scroll"></span>
            </td>
          </template>

          <template v-slot:item.name="{ item }">
            <span>{{item.name.substr(0,22)}} <v-chip outlined x-small>{{item.agent}}</v-chip></span>
          </template>
          <template v-slot:item.Q1Current="{ item }">
            <span :class="item.Q1Current < 0 ? 'error--text':''">{{cvt(item.Q1Current)}}</span>
          </template>
          <template v-slot:item.Q2Current="{ item }">
            <span :class="item.Q2Current < 0 ? 'error--text':''">{{cvt(item.Q2Current)}}</span>
          </template>
          <template v-slot:item.Q3Current="{ item }">
            <span :class="item.Q3Current < 0 ? 'error--text':''">{{cvt(item.Q3Current)}}</span>
          </template>
          <template v-slot:item.Q4Current="{ item }">
            <span :class="item.Q4Current < 0 ? 'error--text':''">{{cvt(item.Q4Current)}}</span>
          </template>
          <template v-slot:item.CurrentTotal="{ item }">
            <span v-if="item.CurrentTotal>=0" class="black--text font-weight-bold">{{cvt(item.CurrentTotal)}}</span>
            <span v-else class="error--text">{{cvt(item.CurrentTotal)}}</span>
          </template>

          <template v-slot:item.Q1Prior="{ item }">
            <span :class="item.Q1Prior < 0 ? 'error--text':''">{{cvt(item.Q1Prior)}}</span>
          </template>
          <template v-slot:item.Q2Prior="{ item }">
            <span :class="item.Q2Prior < 0 ? 'error--text':''">{{cvt(item.Q2Prior)}}</span>
          </template>
          <template v-slot:item.Q3Prior="{ item }">
            <span :class="item.Q3Prior < 0 ? 'error--text':''">{{cvt(item.Q3Prior)}}</span>
          </template>
          <template v-slot:item.Q4Prior="{ item }">
            <span :class="item.Q4Prior < 0 ? 'error--text':''">{{cvt(item.Q4Prior)}}</span>
          </template>

          <template v-slot:item.PriorTotal="{ item }">
            <span v-if="item.PriorTotal>=0" class="black--text font-weight-bold">{{cvt(item.PriorTotal)}}</span>
            <span v-else class="error--text">{{cvt(item.PriorTotal)}}</span>
          </template>

          <template v-slot:body.append>
            <tr style="background-color:#e3f2fd;color:black;height:40px!important;font-weight:bold">
              <td colspan="2"></td>
              <td class="text-right">{{cvt(qtotals[0])}}</td>
              <td class="text-right">{{cvt(qtotals[1])}}</td>
              <td class="text-right">{{cvt(qtotals[2])}}</td>
              <td class="text-right">{{cvt(qtotals[3])}}</td>
              <td class="text-right">{{cvt(qtotals[8])}}</td>
              <td class="text-right">{{cvt(qtotals[4])}}</td>
              <td class="text-right">{{cvt(qtotals[5])}}</td>
              <td class="text-right">{{cvt(qtotals[6])}}</td>
              <td class="text-right">{{cvt(qtotals[7])}}</td>
              <td class="text-right">{{cvt(qtotals[9])}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import async from 'async'
import axios from 'axios'
import Headers from './data/history'

const bct = (a) => {
  return parseInt(a.replace(/[^0-9.]+/g, ""))
}
const rowNewLine = () => {
  return '\r\n'
}

const DATABASE_FIRST_YEAR = 2013

export default {
  name: "CustomerSalesQuarter",
  data: () => ({
    vs_button_larger: false,
    drawer: false,
    expanded: [],
    jsondata: [],
    itemjsondata: [],
    companylist: [], // table list of itemsCompanies for given sales agent
    periodyears: [],
    salesData: [], // hmm
    alert: false,
    alerttext: '',
    iItemsbyQuarter: [],
    itemsCustomerParts: [],
    itemsCustomerPartKG: [],
    hItemsbyQuarter: Headers.ItemsbyQuarter,
    hSalesbyQuarter: Headers.SalesbyQuarter,
    itemsCompanies: [],
    selectYear1: '',
    selectYear2: '',
    csearch: '',
    customerloading: false,
    customerdense: true,
    qtotals: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // snack bar pop ups
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    // selections
    selectedSalesAgentArray: [],
    salesAgentArray: [],
    selectedRegionCodeArray: [],
    regionCodeArray: Headers.ZoneRegionCodes,
    allRegionsFlag: false,
    allAgentsFlag: false,
    allLocations: false,
    locationArray: [
      { text: 'FMI', id: 0, idx: 25 },
      { text: 'SMG', id: 1, idx: 26 },
      { text: 'GT', id: 2, idx: 27 },
      { text: 'CR', id: 3, idx: 28 },
    ],
    selectLocationArray: [0],
    index: 0
  }),
  beforeCreate: function () {},
  beforeMount: function () {
    // load the rep names from the database
    this.loadAllRepNames()
  },
  mounted: function () {
    // push an array of years from the first database year in LCSALES (2013) to the current year (store.js)
    for (var i = DATABASE_FIRST_YEAR; i < this.$store.getters.currentSalesYear + 1; ++i) {
      this.periodyears.push(i.toString())
    }
    // pre-populate the current year and the prior year into the fields
    this.selectYear1 = this.$store.getters.currentSalesYear.toString()
    this.selectYear2 = (this.$store.getters.currentSalesYear - 1).toString()

    // set the current column headers using the two year select
    this.columnQtrTitle()
    this.loadDrawerSelections()
  },
  computed: {},
  watch: {},
  methods: {
    docolors() {

    },
    loadDrawerSelections() {
      let __flags = this.$store.getters.salesSelectionFlags
      // load the prior state of the selection array
      this.selectLocationArray = __flags.companyByQuarter.locations.slice()
      this.selectedRegionCodeArray = __flags.companyByQuarter.regions.slice()
      this.selectedSalesAgentArray = __flags.companyByQuarter.agents.slice()
      // show the nav bar
      this.drawer = true
    },
    watchDrawer(e) {
      if (e.propertyName === 'transform' && !this.drawer) {
        this.$store.commit('salesSelectionFlagsQbC', {
          locations: this.selectLocationArray,
          regions: this.selectedRegionCodeArray,
          agents: this.selectedSalesAgentArray
        })
      }
    },
    exportToPDF: function () {

      if (this.itemsCompanies.length == 0) {
        this.snackbar({
          color: 'error',
          msg: 'Nothing to export.',
          delay: 4000
        })
        return
      }

      let current = this.selectYear1,
        prior = this.selectYear2
      let names = [],
        pdfdata = []

      this.customerloading = true // anim

      if(this.$store.getters.isManager) {
        for (let p in this.selectedSalesAgentArray) {
          names.push({
            name: this.salesAgentArray[this.selectedSalesAgentArray[p]].text,
            nav: this.salesAgentArray[this.selectedSalesAgentArray[p]].value
          })
        }
      } else {
        names.push({name: this.$store.getters.name, nav: this.$store.getters.salescode })
      }

      if (this.expanded.length) {

        pdfdata = { retype: 'exp', header: this.expanded, data: this.iItemsbyQuarter }
        axios.post(`${this.$store.getters.NODEURL}/pdf/qsbce`, {
            "year1": prior,
            "year2": current,
            "totals": this.qtotals,
            "data": pdfdata,
            "agents": names
          }, {
            withCredentials: true,
            responseType: 'arraybuffer'
          })
          .then((response) => {
            let filesaver = require('file-saver')
            filesaver.saveAs(new Blob([response.data]), 'export.pdf')
          })
          .catch((err) => { console.error(err) })
          .finally(() => { this.customerloading = false })
      } else {

        pdfdata = { retype: 'set', data: this.itemsCompanies }
        axios.post(`${this.$store.getters.NODEURL}/pdf/qsbc`, {
            "year1": prior,
            "year2": current,
            "totals": this.qtotals,
            "data": pdfdata,
            "agents": names
          }, {
            withCredentials: true,
            responseType: 'arraybuffer'
          })
          .then((response) => {
            let filesaver = require('file-saver')
            filesaver.saveAs(new Blob([response.data]), `${this.$store.getters.salescode}_Export_${this.selectYear1}_${this.selectYear2}.pdf`)
          })
          .catch((err) => { console.error(err) })
          .finally(() => { this.customerloading = false })
      }
    },
    canHazPermission(index) {
      return this.$store.getters.serializePermissions.toString(2)[index] == '1'
    },
    flipAllLocations() {
      this.allLocations = !this.allLocations
      if (this.allLocations) {
        for (var v in this.locationArray) {
          this.selectLocationArray.push(this.locationArray[v].id)
        }
      } else {
        this.selectLocationArray = []
      }
    },
    flipAllRegions() {
      this.allRegionsFlag = !this.allRegionsFlag
      if (this.allRegionsFlag) {
        for (var v in this.regionCodeArray) {
          this.selectedRegionCodeArray.push(this.regionCodeArray[v].id)
        }
      } else {
        this.selectedRegionCodeArray = []
      }
    },
    flipAllAgents() {
      this.allAgentsFlag = !this.allAgentsFlag
      if (this.allAgentsFlag) {
        for (var v in this.salesAgentArray) {
          this.selectedSalesAgentArray.push(this.salesAgentArray[v].id)
        }
      } else {
        this.selectedSalesAgentArray = []
      }
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    loadAllRepNames() {
      let __list = []
      try {
        if (this.$store.getters.isAdmin || this.$store.getters.isManager) {
          axios
            .get(`${this.$store.getters.NODEURL}/salespeople`, {
              withCredentials: true
            })
            .then(response => {
              let id = 0,
                td = response.data.data
              for (var k in td) {
                __list.push({
                  id: id++,
                  text: `${td[k].First[0]}. ${td[k].Last}`,
                  value: td[k].NavInitials
                })
              }
              this.salesAgentArray = __list
              this.salesAgentArrayCol1 = this.salesAgentArray.length / 2
            })
            .catch(err => {
              this.salesAgentArray = []
            })
            .finally(() => {})
        } else {
          this.salesAgentArray.push({
            id: 0,
            text: 'You',
            value: this.$store.getters.salescode
          })
          this.selectedSalesAgentArray[0] = true
        }
      } catch (err) {
        console.error(err)
      }
    },
    bLoadAllCompanies() {
      this.load(true)
      this.drawer = !this.drawer
    },
    customSort(items, sortBy, sortDesc) {
      if (sortBy[0] != undefined) {
        this.itemsCustomerParts.sort((a, b) => {
          if (sortBy[0] === 'PriorTotal' || sortBy[0] === 'CurrentTotal') {
            return sortDesc[0] ? bct(b[sortBy[0]]) < bct(a[sortBy[0]]) : bct(b[sortBy[0]]) > bct(a[sortBy[0]])
          }
        })
        return this.injectKGintoPartsArray()
      } else {
        return items
      }
    },
    rowExpand(item) {

      if (!item.value) {
        this.iItemsbyQuarter = []
        return
      }

      this.itemsCustomerParts = []
      this.itemsCustomerPartKG = []

      //! converted to REDIS
      axios
        .get(`${this.$store.getters.NODEURL}/customers/history/items/${item.item.cn}/${this.selectYear2}/${this.selectYear1}`, {
          withCredentials: true
        })
        .then(response => {
          this.itemjsondata = response.data.data.result
          this.itemsCustomerParts = response.data.data.tmpUSD
          this.itemsCustomerPartKG = response.data.data.tmpKG
          // sort the array
          this.itemsCustomerParts.sort((a, b) => {
            return bct(b.PriorTotal) - bct(a.PriorTotal)
          })
          this.iItemsbyQuarter = this.injectKGintoPartsArray() // iItemsbyQuarter is tied into the V-DATA-TABLE
        })
        /* eslint-disable no-unused-vars */
        .catch(e => {
          console.log(`rowExpand::${e}`)
        })
        .finally(() => {})
    },
    injectKGintoPartsArray() {
      var tmp = []
      var idx = 0
      for (var l = 0; l < this.itemsCustomerParts.length; ++l) {
        tmp.push(this.itemsCustomerParts[l])
        idx = this.itemsCustomerPartKG.findIndex(o => o.aname === this.itemsCustomerParts[l].name)
        tmp.push(this.itemsCustomerPartKG[idx])
      }
      return tmp
    },
    buildItemHeaders() {
      let row = ''
      for (var v in this.hItemsbyQuarter) {
        if (v != undefined)
          row += `"${this.hItemsbyQuarter[v].text}",`
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildItemDataRows() {
      for (var i = 0, row = '', column = 0; i < this.iItemsbyQuarter.length; i++) {
        for (var t in this.iItemsbyQuarter[i]) {
          if (++column > 1 && column < 14) {
            row += column > 2 ? `"${this.iItemsbyQuarter[i][t]}",` : `"${this.iItemsbyQuarter[i][t]}",`
          }
        }
        column = 0
        row += rowNewLine()
      }
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    // 3/15/2020
    buildCustomerHeaders(padcol) {
      let row = '',
        idx = 0
      for (var i in this.hSalesbyQuarter) {
        if (i != undefined)
          row += `"${this.hSalesbyQuarter[i].text}",`
        if (++idx === 1 && padcol) {
          row += `"",`
        }
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildAllCustomerDataRows() {
      for (var i = 0, row = '', ctr = 0; i < this.itemsCompanies.length; i++) {
        for (var index in this.itemsCompanies[i]) {
          if (++ctr > 2 && ctr < 14) {
            row += ctr > 3 ? `"${this.cvt(this.itemsCompanies[i][index])}",` : `"${this.itemsCompanies[i][index]}",`
          }
        }
        ctr = 0
        row += rowNewLine()
      }
      // console.log(row)
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildSingleCustomerDataRow(custno, padcol) {
      let column = 0,
        row = '',
        idx = 0
      // loop through all table columns
      for (var index in this.itemsCompanies[custno]) {
        // 2 through 13 are Name through KG Totals
        if (++column > 2 && column < 14) {
          row += column > 3 ? `"${this.cvt(this.itemsCompanies[custno][index])}",` : `"${this.itemsCompanies[custno][index]}",`
          if (idx++ === 0 && padcol) {
            row += `"",`
          }
        }
      }
      column = 0
      row += rowNewLine()
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    exportToCSV() {

      if (this.itemsCompanies.length === 0) {
        this.snackbar({
          color: 'error',
          msg: 'Nothing to export.',
          delay: 6000
        })
        return
      }

      this.customerloading = true // loading anim

      var CSVOUT = 'sep=,' + '\r\n'
      var row = ''

      // if a row is currently expanded, show the name of the company
      // with the item parts below it only
      if (this.expanded.length) {
        CSVOUT += this.buildCustomerHeaders(true)
        CSVOUT += this.buildSingleCustomerDataRow(this.itemsCompanies.findIndex(o => o.cn === this.expanded[0].cn), true)
        CSVOUT += this.buildItemHeaders()
        CSVOUT += this.buildItemDataRows()
      } else {
        // otherwise show all companies in the list
        CSVOUT += this.buildCustomerHeaders(false)
        CSVOUT += rowNewLine()
        CSVOUT += this.buildAllCustomerDataRows()
      }

      // build the link filename, hidden <A> and send it
      try {
        let fileName = `${this.$store.getters.name}_History_Export_${this.selectYear1}_${this.selectYear2}`.replace(/ /g, "_")
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSVOUT)
        var link = document.createElement("a")
        link.href = uri
        link.style = "visibility:hidden"
        link.download = fileName + ".csv"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        this.showsnack({
          message: 'Cannot create file for export, the error has been logged.',
          color: 'warning'
        })
      }

      this.customerloading = false
    },
    color(a, b) {
      return this.coloryoy ? `${a > b ? '' : 'red'}--text` : ''
    },
    columnQtrTitle: function () {

      let cs = this.selectYear1.toString(), ps = this.selectYear2.toString()

      for (let c = 1; c < 5; ++c) {
        let priorYear = ps.substring(2,4)
        let proYear = cs.substring(2,4)
        this.hSalesbyQuarter[c].text = `'${priorYear}Q${c}` // display Q1 through Q4 of selected prior year
        this.hSalesbyQuarter[c + 5].text = `'${proYear}Q${c}` // display Q1 through Q4 of projection year
        this.hItemsbyQuarter[c + 1].text = `'${priorYear}Q${c}` // display Q1 through Q4 of selected prior year
        this.hItemsbyQuarter[c + 6].text = `'${proYear}Q${c}` // display Q1 through Q4 of projection year
      }

      this.hSalesbyQuarter[5].text = `'${ps.substring(2,4)} Total` // total of prior
      this.hSalesbyQuarter[10].text = `'${cs.substring(2,4)} Total` // total of projection
      this.hItemsbyQuarter[6].text = `'${ps.substring(2,4)} Total` // total of prior
      this.hItemsbyQuarter[11].text = `'${cs.substring(2,4)} Total` // total of projection
    },
    sumYears: function (year) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].PeriodYear === year) {
          sum += this.jsondata[i].TotalDollars
        }
      }
      return sum
    },
    sumCountry: function (country) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].BillCountry === country) {
          sum++
        }
      }
      return sum
    },
    totalItemByQTR: function (qtr, item, year, sumByMoney) {
      let sum = 0
      for (var i = 0; i < this.itemjsondata.length; ++i) {
        if (this.itemjsondata[i].PeriodQTR === qtr &&
          this.itemjsondata[i].ItemCore === item &&
          this.itemjsondata[i].PeriodYear === year) {
          sum += sumByMoney ? this.itemjsondata[i].TotalDollars : this.itemjsondata[i].Qtyship
        }
      }
      return sumByMoney ? sum : Math.round(sum, 1)
    },
    quartersum: function (qtr, company, year) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].PeriodQTR === qtr &&
          this.jsondata[i].CustName === company &&
          this.jsondata[i].PeriodYear === year) {
          sum += this.jsondata[i].TotalDollars
        }
      }
      return sum
    },
    cvt(value) {
      let v = (value === undefined ? 0 : +value)
      return v.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    cvp(value) {
      let v = (value === undefined ? 0 : value)
      return v.toLocaleString('en-US', {
        minimumFractionDigits: v < 10 ? 1 : 0,
        maximumFractionDigits: v < 10 ? 1 : 0
      })
    },
    percent: function (pj, v) {
      return Math.round((parseInt(v) / pj) * 100, 1)
    },
    load: function (loadall) {

      this.drawer = false

      if (this.selectYear1 === this.selectYear2) {
        this.snackbar({
          color: 'error',
          msg: 'Quarterly sales years cannot be identical.',
          delay: 4000
        })
        return
      }

      let selectRegions = [], selectAgents = [], selectLocations = []

      this.expanded = [] // close any expanded rows

      if (this.selectLocationArray.length === 0) {
        this.snackbar({
          color: 'error',
          msg: 'You must select at least one location.',
          delay: 4000
        })
        return
      }

      if (this.selectedRegionCodeArray.length === 0) {
        this.snackbar({
          color: 'error',
          msg: 'You must select at least one region.',
          delay: 4000
        })
        return
      }

      // build up an array of selected country codes
      for (let p in this.selectLocationArray) {
        selectLocations.push(this.locationArray[this.selectLocationArray[p]].text)
      }

      for (let p in this.selectedRegionCodeArray) {
        selectRegions.push(this.regionCodeArray[this.selectedRegionCodeArray[p]].value)
      }

      if(this.$store.getters.isManager) {
        for (let p in this.selectedSalesAgentArray) {
          selectAgents.push(this.salesAgentArray[this.selectedSalesAgentArray[p]].value)
        }
      } else {
        selectAgents.push(this.$store.getters.salescode)
      }

      if (selectAgents.length === 0 ) {
        this.snackbar({ color: 'error', msg: 'You must select at least one sales agent.', delay: 4000 })
        return
      }

      this.customerloading = true // trigger the loading animation on the table

      async.waterfall(
        [
          async.apply(function (that, callback) {
            let id = 0
            let current = that.selectYear1, prior = that.selectYear2
            axios
              .post(`${that.$store.getters.NODEURL}/customers/history/${prior}/${current}`, {
                countries: selectRegions.join(','),
                agents: that.$store.getters.isManager ? selectAgents.join(',') : selectAgents[0],
                locations: selectLocations.join(',')
              }, {
                withCredentials: true
              })
              .then(response => {
                that.jsondata = response.data.data
                that.qtotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                for (var t = 0; t < that.jsondata.length; ++t) {
                  for (var g = 0; g < 4; ++g) {
                    that.qtotals[g] += that.jsondata[t][`Q${g+1}Prior`]
                    that.qtotals[g + 4] += that.jsondata[t][`Q${g+1}Current`]
                  }
                }
                that.qtotals[8] = [that.qtotals[0], that.qtotals[1], that.qtotals[2], that.qtotals[3]].reduce((a, b) => a + b, 0)
                that.qtotals[9] = [that.qtotals[4], that.qtotals[5], that.qtotals[6], that.qtotals[7]].reduce((a, b) => a + b, 0)
                that.jsondata.sort((a, b) => b.PriorTotal - a.PriorTotal)
                that.itemsCompanies = that.jsondata
              })
              /* eslint-disable no-unused-vars */
              .catch(e => {
                console.error(e)
              })
              .finally(() => {
                callback(null, that)
              })
          }, this)
        ],
        function (err, that) {
          that.customerloading = false
          that.columnQtrTitle()
        }
      )
    }
  }
}
</script>

<style>
 #quaterlysalesbycustomer .v-data-table__wrapper{height:calc(100vh - 235px) !important;}
 #quaterlysalesbycustomer table tbody tr td:nth-child(7) {background-color:#e3f2fd;}
 #quaterlysalesbycustomer table tbody tr td:nth-child(12) {background-color:#e3f2fd;}
</style>

<style scoped>
 .input-yearselect { max-width: 115px!important; }
 tr.v-data-table__selected { background: #fff59d !important; color: #000; }
 #quaterlysalesbycustomer >>> #itemhistorytable .v-data-table__wrapper{height:auto!important;}
 #quaterlysalesbycustomer >>> #itemhistorytable table tr:nth-of-type(4n) td { background-color: #e8e8e8;}
 #quaterlysalesbycustomer >>> #itemhistorytable table tr:nth-of-type(4n-1) td { background-color: #e8e8e8;}
 #itemhistorytable table tbody tr td:nth-child(7) {background-color:#ddd!important;}
 #itemhistorytable table tbody tr td:nth-child(12) {background-color:#ddd!important;}
</style>