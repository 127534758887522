var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.expansion_panel),callback:function ($$v) {_vm.expansion_panel=$$v},expression:"expansion_panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"font-weight-bold"},[_vm._v("Raw Material Calculator (IN DEVELOPMENT)")]),_c('v-expansion-panel-content',{staticClass:"grey-striped"},[_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search","persistent-hint":"","hint":"Search by keyword","append-outer-icon":"mdi-magnify"},on:{"change":_vm.search_pk_values},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","items":_vm.pk_search.items,"persistent-hint":"","hint":_vm.pk_search.results,"error":_vm.pk_search.error,"item-text":"value","loading":_vm.pk_search.loading,"hide-selected":"","append-outer-icon":"mdi-content-copy"},on:{"click:append-outer":function($event){_vm.formula_id = _vm.select}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.value)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_vm._v(" $ "+_vm._s(item.cost)+" ")])]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Formula ID","persistent-hint":"","hint":"Enter complete formula Id","append-outer-icon":"mdi-database"},on:{"click:append-outer":_vm.load_pk_formula,"change":_vm.load_pk_formula},model:{value:(_vm.formula_id),callback:function ($$v) {_vm.formula_id=$$v},expression:"formula_id"}})],1)],1)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"font-weight-bold"},[_vm._v("Formula Breakdown (IN DEVELOPMENT)")]),_c('v-expansion-panel-content',{staticClass:"grey-striped"},[_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.rmc.headers,"items":_vm.rmc.items,"search":_vm.rmc.search,"dense":"","loading":_vm.rmc.loading,"single-expand":true,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":function($event){return _vm.item_expanded()}},scopedSlots:_vm._u([{key:"item.subs",fn:function(ref){
var item = ref.item;
return [(item.subs.length)?_c('div',[_vm._v(_vm._s(item.subs.length)+" sub-components")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v("$ "+_vm._s(_vm.cvt(item.price)))]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"border":"solid 1px #444"},attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.subs)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }