<template>
  <v-container fluid>

    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-row no-gutters>
      <v-col cols="12">

          <v-toolbar flat>
          <v-toolbar-title>Quarterly Sales by Item</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-select :items="iReportYears" v-model="selectYear1" hide-details class="input-yearselect mr-2" background-color="yellow lighten-5"></v-select>
          <v-select :items="iReportYears" v-model="selectYear2" hide-details class="input-yearselect mr-2" background-color="yellow lighten-5"></v-select>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-btn @click="load(false)" text outlined class="blue darken-2" elevation="0"> <v-icon color="white">mdi-refresh</v-icon></v-btn>
          <v-btn @click="exportToCSV" text outlined class="blue darken-2 ml-2" elevation="0"> <v-icon color="white">mdi-microsoft-excel</v-icon> </v-btn>
          <v-btn class="blue darken-2 ml-2" elevation="0" disabled> <v-icon color="white">mdi-adobe-acrobat</v-icon> </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="tSearch" label="Search" hide-details></v-text-field>
          <v-btn small depressed @click="drawer = !drawer" class="ml-3 white"> <v-icon color="indigo">mdi-menu</v-icon> </v-btn>
          </v-toolbar>

        <v-data-table
          id="itemQTRDataTable"
          :headers="qtrDataHeaders"
          :items="qtrDataItems"
          class="elevation-3"
          :loading="tlLoading"
          :single-expand="true"
          show-expand
          dense
          :expanded.sync="expanded"
          @item-expanded="rowExpand"
          item-key="id"
          hide-default-footer
          :search="tSearch"
          :disable-sort="sortDesc"
          fixed-header
          :footer-props="{ 'items-per-page-options': [-1] }">

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="border-top:solid 4px #444;border-bottom:solid 4px #444;background-color:#444;" class="pa-3 ma-0">
              <v-data-table
                id="purchasetable"
                :headers="qtrDataSubHeaders"
                :items="qtrItemSubPurchaseList"
                :loading="tlPurchase"
                class="elevation-4 caption font-weight-bold"
                item-key="pid"
                dense
                hide-default-footer
                :footer-props="{ 'items-per-page-options': [-1] }">
               <template v-slot:top>
                 <v-toolbar color="indigo lighten-2 elevation-0" dark dense>
                   <v-toolbar-title>{{item.name}}</v-toolbar-title>
                 </v-toolbar>
               </template>
               <template v-slot:item.PriorTotal="props">{{!vsUSDorKG ? cvt(props.item.PriorTotal) : cvk(props.item.PriorTotal)}}</template>
               <template v-slot:item.CurrentTotal="props">{{!vsUSDorKG ? cvt(props.item.CurrentTotal) : cvk(props.item.CurrentTotal)}}</template>
              </v-data-table>
            </td>
          </template>

          <template v-slot:item.name="props">{{ props.item.name }}</template>
          <template v-slot:item.Q1Prior="props">
            <span :class="clr(props.item.Q1Prior)">{{ !vsUSDorKG ? cvt(props.item.Q1Prior) : cvk(props.item.Q1Prior) }}</span>
          </template>
          <template v-slot:item.Q2Prior="props">
            <span :class="clr(props.item.Q2Prior)">{{ !vsUSDorKG ? cvt(props.item.Q2Prior) : cvk(props.item.Q2Prior) }}</span>
          </template>
          <template v-slot:item.Q3Prior="props">
            <span :class="clr(props.item.Q3Prior)">{{ !vsUSDorKG ? cvt(props.item.Q3Prior) : cvk(props.item.Q3Prior) }}</span>
          </template>
          <template v-slot:item.Q4Prior="props">
            <span :class="clr(props.item.Q4Prior)">{{ !vsUSDorKG ? cvt(props.item.Q4Prior) : cvk(props.item.Q4Prior) }}</span>
          </template>
          <template v-slot:item.PriorTotal="props"><b>{{ !vsUSDorKG ? cvt(props.item.PriorTotal) : cvk(props.item.PriorTotal) }}</b></template>
          <template v-slot:item.Q1Current="props">
            <span :class="clr(props.item.Q1Current)">{{ !vsUSDorKG ? cvt(props.item.Q1Current) : cvk(props.item.Q1Current) }}</span>
          </template>
          <template v-slot:item.Q2Current="props">
            <span :class="clr(props.item.Q2Current)">{{ !vsUSDorKG ? cvt(props.item.Q2Current) : cvk(props.item.Q2Current) }}</span>
          </template>
          <template v-slot:item.Q3Current="props">
            <span :class="clr(props.item.Q3Current)">{{ !vsUSDorKG ? cvt(props.item.Q3Current) : cvk(props.item.Q3Current) }}</span>
          </template>
          <template v-slot:item.Q4Current="props">
            <span :class="clr(props.item.Q4Current)">{{ !vsUSDorKG ? cvt(props.item.Q4Current) : cvk(props.item.Q4Current) }}</span>
          </template>
          <template v-slot:item.CurrentTotal="props"><b>{{ !vsUSDorKG ? cvt(props.item.CurrentTotal) : cvk(props.item.CurrentTotal) }}</b></template>

          <template v-slot:body.append>
            <tr style="background-color:#e3f2fd;color:black;height:40px!important;font-weight:bold">
              <td colspan="3"></td>
              <td class="text-right">{{cvt(qtotals[0])}}</td>
              <td class="text-right">{{cvt(qtotals[1])}}</td>
              <td class="text-right">{{cvt(qtotals[2])}}</td>
              <td class="text-right">{{cvt(qtotals[3])}}</td>
              <td class="text-right">{{cvt(qtotals[8])}}</td>
              <td class="text-right">{{cvt(qtotals[4])}}</td>
              <td class="text-right">{{cvt(qtotals[5])}}</td>
              <td class="text-right">{{cvt(qtotals[6])}}</td>
              <td class="text-right">{{cvt(qtotals[7])}}</td>
              <td class="text-right">{{cvt(qtotals[9])}}</td>
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-navigation-drawer v-model="drawer" absolute temporary right width="400" @transitionend="watchDrawer($event)" class="bg-color">
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allLocations" block @click="flipAllLocations" dark class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allLocations?'No':'All'}} Locations
      </v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectLocationArray">
            <v-chip v-for="t in locationArray" :key="t.id" :small="!vs_button_larger" label :disabled="canHazPermission(t.idx)" active-class="success">
              <v-avatar left> <v-icon small v-if="selectLocationArray.includes(t.id)" color="white">mdi-checkbox-marked-circle</v-icon></v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allRegionsFlag" block @click="flipAllRegions" dark class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allRegionsFlag?'No':'All'}} Regions</v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectedRegionCodeArray">
            <v-chip v-for="t in regionCodeArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
              <v-avatar left> <v-icon small v-if="selectedRegionCodeArray.includes(t.id)" color="white">mdi-checkbox-marked-circle</v-icon></v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allTypes" block @click="flipAllTypes" dark class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allTypes?'No':'All'}} Types</v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectedTypeCodeArray">
            <v-chip v-for="t in typeCodeArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
              <v-avatar left> <v-icon small v-if="selectedTypeCodeArray.includes(t.id)" color="white">mdi-checkbox-marked-circle</v-icon></v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col md="10" offset-md="1">
            <v-btn @click="refreshData()" outlined color="green" block small>Search <v-icon right color="green" small>mdi-refresh</v-icon></v-btn>
          </v-col>
        </v-row>
      </div>
    </v-navigation-drawer>

  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import async from 'async'
import axios from 'axios'
import { helperMixin } from '../mixins/helper'
import Headers from './data/items'

const rowNewLine = () => {
  return '\r\n'
}
const DATABASE_FIRST_YEAR = 2013

export default {
  name: "ItemSalesQuarterly",
  mixins: [ helperMixin ],
  data: () => ({
    vs_button_larger: false,
    alert: false,
    alerttext: '',
    qtrDataHeaders: Headers.QuarterlySalesbyItem,
    qtrDataSubHeaders: Headers.QuarterlySalesSubHeaders,
    qtrDataItems: [],
    tSearch: '',
    vsItemDense: true,
    tlLoading: false,
    tlPurchase: false,
    tlExportData: false,
    dbError: false,
    // snack bar pop ups
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    selectYear1: '',
    selectYear2: '',
    iReportYears: [],
    vsUSDorKG: false,
    vmRegionCodes: [],
    qtotals: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    irRegionCodes: Headers.ZoneRegionCodes,
    drawer: true,
    // items_misc: false,
    items_ing: false,
    items_flv: true,
    responseJSON: '',
    usdItemsArray: [],
    kgItemsArray: [],
    sortDesc: false,
    allRegions: false,
    expanded: [],
    qtrItemSubPurchaseList: [],
    qtrItemSubPurchaseListUSD: [],
    qtrItemSubPurchaseListKG: [],

    // vswitch selections
    selectLocationArray: [0],
    selectedRegionCodeArray: [11],
    regionCodeArray: Headers.ZoneRegionCodes,
    allRegionsFlag: false,
    allLocations: false,
    allTypes: false,
    locationArray: [
      { text: 'FMI', id: 0, idx: 25 },
      { text: 'SMG', id: 1, idx: 26 },
      { text: 'GT', id: 2, idx: 27 },
      { text: 'CR', id: 3, idx: 28 },
    ],
    typeCodeArray: [ { text: 'Ingredients', id: 0, code: 'ING' }, { text: 'Flavors', id: 1, code:'FLV' } ],
    selectedTypeCodeArray:[],
    
  }),
  beforeCreate: function () {
    for (let i in this.irRegionCodes) {
      this.vmRegionCodes[i] = false
    }
  },
  beforeMount() { },
  mounted: function () {
    // build the options, selects and drop down options
    for (var i = DATABASE_FIRST_YEAR; i < this.$store.getters.currentSalesYear + 1; ++i) {
      this.iReportYears.push(i.toString())
    }
    this.selectYear1 = (this.$store.getters.currentSalesYear - 1).toString()
    this.selectYear2 = this.$store.getters.currentSalesYear.toString()

    // restore the state of the v-switches based on the VUEX store
    let vstate = this.$store.getters.vsQtrSalesbyItem

    if(vstate.length === 0) {
      this.allRegions = true
      this.vmRegionCodes = this.irRegionCodes.slice()
      this.$store.commit('vsQtrSalesbyItemFlag', true)
      this.$store.commit('vsQtrSalesbyItem', this.vmRegionCodes)
    } else {
      this.vmRegionCodes = vstate
    }
    this.allRegions = this.$store.getters.vsQtrSalesbyItemFlag

    this.columnQtrTitle()
  },
  computed: {},
  methods: {
    watchDrawer(e) {
      // if (e.propertyName === 'transform' && !this.drawer) { }
    },
    canHazPermission(index) {
      return this.$store.getters.serializePermissions.toString(2)[index] == '1'
    },
    flipAllTypes () {
      this.allTypes = !this.allTypes
      if (this.allTypes) {
        for (var v in this.typeCodeArray) {
          this.selectedTypeCodeArray.push(this.typeCodeArray[v].id)
        }
      } else {
        this.selectedTypeCodeArray = []
      }
    },
    flipAllLocations() {
      this.allLocations = !this.allLocations
      if (this.allLocations) {
        for (var v in this.locationArray) {
          this.selectLocationArray.push(this.locationArray[v].id)
        }
      } else {
        this.selectLocationArray = []
      }
    },
    flipAllRegions() {
      this.allRegionsFlag = !this.allRegionsFlag
      if (this.allRegionsFlag) {
        for (var v in this.regionCodeArray) {
          this.selectedRegionCodeArray.push(this.regionCodeArray[v].id)
        }
      } else {
        this.selectedRegionCodeArray = []
      }
    },
    subTableSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "PriorTotal") {
          if (!isDesc[0]) {
            return a.PriorTotal - b.PriorTotal
          } else {
            return a.PriorTotal - b.PriorTotal
          }
        }
      })
      return items
    },
    rowExpand(item) {
      if (!item.value) return

      let qi = item.item.name

      let _tmpCountryCodes = this.jsonifyCountryCodes()

      this.tlPurchase = true
      this.qtrItemSubPurchaseList = []

      axios.post(`${this.$store.getters.NODEURL}/item/history/company`, {
          year1: this.selectYear1,
          year2: this.selectYear2,
          countries: _tmpCountryCodes.join(','),
          itemcore: qi
        }, {
          withCredentials: true
        })
        .then(response => {
            this.qtrItemSubPurchaseListUSD = response.data[0]
            this.qtrItemSubPurchaseListKG = response.data[1]
            this.qtrItemSubPurchaseList = this.qtrItemSubPurchaseListUSD
            this.qtrItemSubPurchaseList.sort((a,b) => b.PriorTotal - a.PriorTotal) // load the USD version of the table
        })
        .catch(err => {
          console.log(err.response.status)
        })
        .finally(() => {
          this.tlPurchase = false
        })
    },
    columnQtrTitle: function () {
      let cs = this.selectYear2.toString(), ps = this.selectYear1.toString()
      for (let c = 0; c < 4; ++c) {
        this.qtrDataHeaders[c + 2].text = ` '${ps.substring(2,4)}Q${c+1}` // display Q1 through Q4 of selected prior year
        this.qtrDataHeaders[c + 7].text = ` '${cs.substring(2,4)}Q${c+1}` // display Q1 through Q4 of projection year
        this.qtrDataSubHeaders[c+1].text = ` '${ps.substring(2,4)}Q${c+1}`
        this.qtrDataSubHeaders[c+6].text = ` '${cs.substring(2,4)}Q${c+1}`
      }
      this.qtrDataHeaders[6].text = `'${ps.substring(2,4)} Total` // total of prior
      this.qtrDataSubHeaders[5].text = `'${ps.substring(2,4)} Total` // total of prior
      this.qtrDataHeaders[11].text = `'${cs.substring(2,4)} Total` // total of projection
      this.qtrDataSubHeaders[10].text = `'${cs.substring(2,4)} Total` // total of prior
    },
    swapUStoKG() {
      if (this.vsUSDorKG) {
        this.sortDesc = !this.sortDesc
        this.qtrDataItems = this.kgItemsArray // switch to KG
        this.qtrItemSubPurchaseList = this.qtrItemSubPurchaseListKG // switch to KG
      } else {
        this.qtrDataItems = this.usdItemsArray
        this.qtrItemSubPurchaseList = this.qtrItemSubPurchaseListUSD // switch to USD
      }
    },
    itemtypeFLV() {
      if (this.items_flv && this.items_all) {
        this.items_all = false
      }
      if (this.items_ing && this.items_flv) {
        this.$nextTick(() => {
          this.items_all = true
          this.items_ing = false
          this.items_flv = false
        })
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.pickedAllCountries) {
          this.vmRegionCodes = []
        } else {
          this.vmRegionCodes = this.irRegionCodes.slice()
        }
      })
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    buildItemHeaders(padcol) {
      let row = '',
        idx = 0
      for (var i in this.qtrDataHeaders) {
        if (i != undefined)
          row += `"${this.qtrDataHeaders[i].text}",`
        if (++idx === 2 && padcol) {
          row += `"",`
        }
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildAllItemDataRows() {
      for (var i = 0, row = '', columnID = 0; i < this.qtrDataItems.length; i++) {
        for (var index in this.qtrDataItems[i]) {
          if (++columnID > 1 && columnID < 14) {
            if(this.vsUSDorKG) {
               row += columnID > 2 ? `"${this.cvk(this.qtrDataItems[i][index])}",` : `"${this.qtrDataItems[i][index]}",`
            } else {
               row += columnID > 2 ? `"${this.cvt(this.qtrDataItems[i][index])}",` : `"${this.qtrDataItems[i][index]}",`
            }
          }
        }
        columnID = 0
        row += rowNewLine()
      }
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildSingleItemDataRow(custno, padcol) {
      let columnID = 0, row = '', idx = 0
      for (var index in this.qtrDataItems[custno]) {
        if (++columnID > 1 && columnID < 14) {
          if(this.vsUSDorKG) {
            row += columnID > 2 ? `"${this.cvk(this.qtrDataItems[custno][index])}",` : `"${this.qtrDataItems[custno][index]}",`
          } else {
            row += columnID > 2 ? `"${this.cvt(this.qtrDataItems[custno][index])}",` : `"${this.qtrDataItems[custno][index]}",`
          }
          if(idx++===1 && padcol) { row += `"",` }
        }
      }
      columnID = 0
      row += rowNewLine()
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildItemHistoryHeaders(padcol) {
      let row = '', idx = 0
      for (var v in this.qtrDataSubHeaders) {
          if (v != undefined)
            row += `"${this.qtrDataSubHeaders[v].text}",`
          if (++idx === 1 && padcol) { row += `"","",` }
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildItemHistoryDataRows() {
      var idx
      for (var i = 0, row = '', columnID = 0; i < this.qtrItemSubPurchaseList.length; i++) {
        idx = 0
        for (var t in this.qtrItemSubPurchaseList[i]) {
          if (++columnID > 2) {
              row += columnID > 2 ? `"${this.cvk(this.qtrItemSubPurchaseList[i][t])}",` : `"${this.qtrItemSubPurchaseList[i][t]}",`
          }
          if (++idx === 3) { row += `"","",` }
        }
        columnID = 0
        row += rowNewLine()
      }
      return  row.slice(0, row.length - 1) + rowNewLine()
    },
    exportToCSV() {

      if (this.qtrDataItems.length === 0) {
        this.snackbar({
          color: 'error',
          msg: 'Nothing to export.',
          delay: 6000
        })
        return
      }

      this.startLoadingAnimation()

      let CSVOUT = 'sep=,' + '\r\n', row = ''

      // if a row is currently expanded, show the name of the company
      // with the item parts below it only
      if (this.expanded.length) {
        CSVOUT += this.buildItemHeaders(true)
        CSVOUT += this.buildSingleItemDataRow(this.qtrDataItems.findIndex(o => o.desc === this.expanded[0].desc), true)
        CSVOUT += this.buildItemHistoryHeaders(true)
        CSVOUT += this.buildItemHistoryDataRows()
      } else {
        CSVOUT += this.buildItemHeaders(false)
        CSVOUT += rowNewLine()
        CSVOUT += this.buildAllItemDataRows()
      }

      // build the link filename, hidden <A> and send it
      try {
        let fileName = `${this.$store.getters.name}_Items_Export_${this.selectYear1}_${this.selectYear2}-${this.vsUSDorKG ? 'KG':'USD'}`.replace(/ /g, "_")
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSVOUT)
        var link = document.createElement("a")
        link.href = uri
        link.style = "visibility:hidden"
        link.download = fileName + ".csv"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        this.showsnack({
          message: 'Cannot create file for export, the error has been logged.',
          color: 'warning'
        })
      }

      this.stopLoadingAnimation()
    },
    refreshData() {
      this.loadData()
    },
    clr(v) {
      return `${v <= 0 ? 'grey--text':''}`
    },
    cvt(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    cvk(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: value < 10 ? 1 : 0,
        maximumFractionDigits: value < 10 ? 1 : 0
      })
    },
    startLoadingAnimation() {
      this.tlLoading = true
    },
    stopLoadingAnimation() {
      this.tlLoading = false
    },
    async processData() {
      let tmpt = [],
        loc = null
      for (var item in this.usdItemsArray) {
        loc = this.kgItemsArray.findIndex((c) => c.name === this.usdItemsArray[item].name)
        tmpt.push(this.kgItemsArray[loc])
      }
      this.kgItemsArray = tmpt
    },
    jsonifyCountryCodes () {
      let tmp = []
      for (var p in this.vmRegionCodes) {
        if (this.vmRegionCodes[p]) {
          tmp.push(this.irRegionCodes[p].value)
        }
      }
      return tmp
    },
    jsonifyItemTypes () {
      let tmp = []
      if (this.items_ing) tmp.push('ING')
      if (this.items_flv) tmp.push('FLV')
      return tmp
    },
    loadData: function () {

      this.qtrDataItems = []
      this.expanded = []

      this.drawer = false
      this.annualDataItems = []
      let selectRegions = [], selectTypes = [], selectLocations = []

      if (this.selectLocationArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one location.', delay: 4000 })
        return
      }
      if (this.selectedRegionCodeArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one region.', delay: 4000})
        return
      }
      if (this.selectedTypeCodeArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one type to display.', delay: 4000})
        return
      }

      // build up an array of selected country codes
      for (let p in this.selectLocationArray) {
        selectLocations.push(this.locationArray[this.selectLocationArray[p]].text)
      }
      for (let p in this.selectedRegionCodeArray) {
        selectRegions.push(this.regionCodeArray[this.selectedRegionCodeArray[p]].value)
      }
      for (let p in this.selectedTypeCodeArray) {
        selectTypes.push(this.typeCodeArray[this.selectedTypeCodeArray[p]].code)
      }

      this.startLoadingAnimation()

      this.columnQtrTitle()

      async.waterfall(
        [
          async.apply(function (that, callback) {
            axios
              .post(`${that.$store.getters.NODEURL}/item/history`, {
                year1: that.selectYear1,
                year2: that.selectYear2,
                countries: selectRegions.join(','),
                itemtypes: selectTypes.join(','),
                locations: selectLocations.join(',')
              }, {
                withCredentials: true
              })
              .then(response => {

                that.responseJSON = response.data[0] // all data returned from SQL by NODE
                let itemcore = response.data[1] // unique list of company items processed by NODE
                that.usdItemsArray = response.data[2] // the array by USD
                that.kgItemsArray = response.data[3] // the array by KG

                for (let t = 0; t < that.usdItemsArray.length; ++t) {
                  for (var g = 0; g < 4; ++g) {
                    that.qtotals[g] += that.usdItemsArray[t][`Q${g+1}Prior`]
                    that.qtotals[g + 4] += that.usdItemsArray[t][`Q${g+1}Current`]
                  }
                }
                that.qtotals[8] = [that.qtotals[0], that.qtotals[1], that.qtotals[2], that.qtotals[3]].reduce((a, b) => a + b, 0)
                that.qtotals[9] = [that.qtotals[4], that.qtotals[5], that.qtotals[6], that.qtotals[7]].reduce((a, b) => a + b, 0)

                // sort the entire array
                that.usdItemsArray.sort((a, b) => b.PriorTotal - a.PriorTotal)
                // align KG to USD in array after sort
                that.processData()
                // initially show the USD values in the table
                that.qtrDataItems = that.usdItemsArray
              })
              .catch(err => {
                console.log(err.response.status)
                if (err.response.status === 404) {
                  that.snackbar(that.parseError(err.response.status))
                }
                throw err
              })
              .finally(() => {
                that.stopLoadingAnimation()
              })
          }, this) /* async.apply */
        ],
        function (err, that) {
          if (err) {
            that.dbError = true
            this.snackbar({
              msg: 'Failed to load data.',
              color: 'error',
              delay: 3000
            })
          }
        }
      ) /* async.waterfall */
    }
  }
}
</script>

<style>
#itemQTRDataTable .v-data-table__wrapper{height:calc(100vh - 235px) !important;}
</style>

<style scoped>
.input-yearselect { max-width: 115px!important; max-height:20px!important; margin-top:-20px!important; }
tr.v-data-table__selected { background: #fff59d !important; color: #000; }
.v-data-table td { padding: 0 8px!important; }
#itemQTRDataTable table tbody tr td:nth-child(8) {background-color:#e3f2fd}
#itemQTRDataTable table tbody tr td:nth-child(13) {background-color:#e3f2fd}
#itemQTRDataTable >>> #purchasetable .v-data-table__wrapper{height:auto!important;} 
#itemQTRDataTable >>> #purchasetable tbody tr td:nth-child(6) {background-color:#ddd!important;}
#itemQTRDataTable >>> #purchasetable tbody tr td:nth-child(11) {background-color:#ddd!important;} 
</style>
