import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Print from 'vue-print-nb'
import store from './store/store'
import Vuelidate from 'vuelidate'
import router from './router/router'
import Toast from 'vue-toastification'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

import 'vue-toastification/dist/index.css'

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true

Vue.use(router)
Vue.use(Vuex)
Vue.use(vuetify)
Vue.use(Vuelidate)
Vue.use(VueClipboard)
Vue.use(require('vue-shortkey'))
Vue.use(Print)

Vue.use(Toast, { transition: "Vue-Toastification__bounce", maxToasts: 20, newestOnTop: true })

/* Sentry.init({
  dsn: 'https://83d472728eaa4376955ae4b1a0547f7a@o370348.ingest.sentry.io/5233955',
  integrations: [new Integrations.Vue({
    Vue,
    attachProps: true
  })],
}) */

/*
--no need to apply title dynamically through router
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'FMI'
    next()
})
*/

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')