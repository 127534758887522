const gm_quarterlySalesSubHeaders =
[
    {
        text: "Company",
        align: "left",
        sortable: true,
        value: "desc",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q1",
        sortable: false,
        align: "right",
        value: "Q1Prior"
    },
    {
        text: "Q2",
        align: "right",
        sortable: false,
        value: "Q2Prior"
    },
    {
        text: "Q3",
        align: "right",
        sortable: false,
        value: "Q3Prior"
    },
    {
        text: "Q4",
        align: "right",
        sortable: false,
        value: "Q4Prior"
    },
    {
        text: "Total",
        align: "right",
        sortable: true,
        value: "PriorTotal",
        class: "black--text font-weight-bold blue lighten-5"
    },
    {
        text: "Q1",
        align: "right",
        sortable: false,
        value: "Q1Current"
    },
    {
        text: "Q2",
        align: "right",
        sortable: false,
        value: "Q2Current"
    },
    {
        text: "Q3",
        align: "right",
        sortable: false,
        value: "Q3Current"
    },
    {
        text: "Q4",
        align: "right",
        sortable: false,
        value: "Q4Current"
    },
    {
        text: "Total",
        align: "right",
        sortable: true,
        value: "CurrentTotal",
        class: "black--text font-weight-bold blue lighten-5"
    }
]

export default gm_quarterlySalesSubHeaders