var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-app-bar-nav-icon'),_c('span',{staticClass:"ml-1"},[_vm._v("Territory Totals")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.territoryHeaders,"items":_vm.territoryItems,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.yoy",fn:function(ref){
var item = ref.item;
return [_vm._v("> "),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(item.yoy.toFixed(2)))])]}},{key:"item.proj",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(item.proj.toFixed(2)))])]}},{key:"item.growth",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(item.growth.toFixed(2)))])]}},{key:"item.prior",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.cvt(item.prior)))])]}},{key:"item.current",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.cvt(item.current)))])]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-app-bar-nav-icon'),_c('span',{staticClass:"ml-1"},[_vm._v("Top Three Customers")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.customerHeaders,"items":_vm.topThreeItems,"loading":_vm.topThreeLoading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.CustName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.truncate(item.CustName,28)))]}},{key:"item.YoYGrowth",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.YoYGrowth)+" %")]}},{key:"item.ProjectionPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ProjectionPercent)+" %")]}},{key:"item.ProjectedGrowth",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ProjectedGrowth)+" %")]}}],null,true)})],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-app-bar-nav-icon'),_c('span',{staticClass:"ml-1"},[_vm._v("Bottom Three Customers")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.customerHeaders,"items":_vm.bottomThreeItems,"loading":_vm.bottomThreeLoading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.CustName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.truncate(item.CustName,28)))]}},{key:"item.YoYGrowth",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.YoYGrowth)+" %")]}},{key:"item.ProjectionPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ProjectionPercent)+" %")]}},{key:"item.ProjectedGrowth",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ProjectedGrowth)+" %")]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }