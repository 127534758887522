<template>
  <v-container fluid style="height:100%!important" class="d-flex align-end">

    <v-card class="mx-auto" width="800" elevation="0">
      <v-card-text>
        <v-row>
          <!-- -->
          <v-col cols="4">
            <v-card flat outlined>
              <v-card-subtitle><span class="button font-weight-bold">Business Metric One</span></v-card-subtitle>
              <v-card-text>
                <v-sparkline :value="graph1" color="rgba(25, 25, 25, .7)" height="50" type="bar" padding="0" stroke-linecap="round" smooth :gradient="gradient" fill="fill"></v-sparkline>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- -->
          <v-col cols="4">
            <v-card flat outlined>
              <v-card-subtitle><span class="button font-weight-bold">Business Metric Two</span></v-card-subtitle>
              <v-card-text>
                <v-sparkline :value="graph2" color="rgba(25, 25, 25, .7)" height="50" type="bar" padding="0" stroke-linecap="round" smooth :gradient="gradient" fill="fill"></v-sparkline>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- -->
          <v-col cols="4">
            <v-card flat outlined>
              <v-card-subtitle><span class="button font-weight-bold">Business Metric Three</span></v-card-subtitle>
              <v-card-text>
                <v-sparkline :value="graph3" color="rgba(25, 25, 25, .7)" height="50" type="bar" padding="0" stroke-linecap="round" smooth :gradient="gradient" fill="fill"></v-sparkline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="center" v-if="$vuetify.breakpoint.lgAndUp">
      <v-img src="@/assets/logo.jpg" alt="" class="faded-image"></v-img>
    </div>
  </v-container>
</template>

<script>
export default {
  name:'Base',
  beforeMount() {},
  mounted() {
    this.graph1 = Array(60).fill().map(() => Math.round((150 * Math.random()) + 10),4)
    this.graph2 = Array(60).fill().map(() => Math.round((150 * Math.random()) + 10),4)
    this.graph3 = Array(60).fill().map(() => Math.round((150 * Math.random()) + 10),4)
  },
  data: () => ({
    gradient: ['#42b3f4'],
    graph1: [],
    graph2: [],
    graph3: [],
  })
}
</script>

<style scoped>
.center {
  position: fixed;
  width: 501px;
  height: 233px;
  top: 50%;
  left: 50%;
  margin-left: -250px;
  margin-top: -116px;
}
.faded-image { opacity: 0.5; }
</style>