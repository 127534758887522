const gm_annualSalesSubHeaders =
[
    {
        text: "Company",
        align: "left",
        sortable: false,
        value: "name",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd1",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd2",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd3",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "usd4",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg1",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg2",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg3",
        class: "black--text font-weight-bold"
    },
    {
        text: "",
        align: "right",
        sortable: true,
        value: "kg4",
        class: "black--text font-weight-bold"
    }
]

export default gm_annualSalesSubHeaders