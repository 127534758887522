<template>
  <v-container fluid>
    <v-card flat>
      <v-row dense>
        <v-col md=12>
          <v-toolbar flat color="grey lighten-3">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <span class="ml-1">Territory Totals</span>
          </v-toolbar>
          <v-card outlined>
            <v-data-table :headers="territoryHeaders" :items="territoryItems" hide-default-footer disable-pagination>
              <template v-slot:[`item.yoy`]="{ item }">> <span class="text-h5">{{ item.yoy.toFixed(2) }}</span>
              </template>
              <template v-slot:[`item.proj`]="{ item }"> <span class="text-h5">{{ item.proj.toFixed(2) }}</span>
              </template>
              <template v-slot:[`item.growth`]="{ item }"> <span class="text-h5">{{ item.growth.toFixed(2) }}</span>
              </template>
              <template v-slot:[`item.prior`]="{ item }"> <span class="text-h5">{{ cvt(item.prior) }}</span> </template>
              <template v-slot:[`item.current`]="{ item }"> <span class="text-h5">{{ cvt(item.current) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md=6>
          <v-toolbar flat color="grey lighten-3">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <span class="ml-1">Top Three Customers</span>
          </v-toolbar>
          <v-card outlined>
            <v-data-table :headers="customerHeaders" :items="topThreeItems" :loading="topThreeLoading"
              hide-default-footer disable-pagination>
              <template v-slot:[`item.CustName`]="{ item }">{{ truncate(item.CustName,28) }}</template>
              <template v-slot:[`item.YoYGrowth`]="{ item }">{{ item.YoYGrowth }} %</template>
              <template v-slot:[`item.ProjectionPercent`]="{ item }">{{ item.ProjectionPercent }} %</template>
              <template v-slot:[`item.ProjectedGrowth`]="{ item }">{{ item.ProjectedGrowth }} %</template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col md=6>
          <v-toolbar flat color="grey lighten-3">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <span class="ml-1">Bottom Three Customers</span>
          </v-toolbar>
          <v-card outlined>
            <v-data-table :headers="customerHeaders" :items="bottomThreeItems" :loading="bottomThreeLoading"
              hide-default-footer disable-pagination>
              <template v-slot:[`item.CustName`]="{ item }">{{ truncate(item.CustName,28) }}</template>
              <template v-slot:[`item.YoYGrowth`]="{ item }">{{ item.YoYGrowth }} %</template>
              <template v-slot:[`item.ProjectionPercent`]="{ item }">{{ item.ProjectionPercent }} %</template>
              <template v-slot:[`item.ProjectedGrowth`]="{ item }">{{ item.ProjectedGrowth }} %</template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {
  helperMixin
} from '../mixins/helper'
import axios from 'axios'
export default {
  name: 'Home',
  mixins: [helperMixin],
  data: () => ({
    stab: null,
    dtab: null,
    acrobatSearch: '',
    docuSearch: '',
    // territory table
    territoryItems: [],
    topThreeItems: [],
    topThreeLoading: false,
    bottomThreeLoading: false,
    bottomThreeItems: [],
    territoryHeaders: [{
        text: "% YoY Growth",
        align: "left",
        value: "yoy",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "% Projection",
        align: "left",
        value: "proj",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "% Projected Growth",
        align: "right",
        value: "growth",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "Prior YTD",
        align: "right",
        value: "prior",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "Current YTD",
        align: "right",
        value: "current",
        class: "black--text font-weight-bold",
        sortable: false
      }
    ],
    customerHeaders: [{
        text: "Customer",
        align: "left",
        value: "CustName",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "% YoY Growth",
        align: "left",
        value: "YoYGrowth",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "% Proj.",
        align: "left",
        value: "ProjectionPercent",
        class: "black--text font-weight-bold",
        sortable: false
      },
      {
        text: "% Proj. Growth",
        align: "right",
        value: "ProjectedGrowth",
        class: "black--text font-weight-bold",
        sortable: false
      }
    ],
    // snackbar start
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
  }),
  beforeCreate () {
    if(!this.$store.getters.allowDashboard) {
      console.info('user has no permission to this feature')
      this.$router.push('/d/base')
    }
  },
  mounted() {

    this.topThreeLoading = true
    this.bottomThreeLoading = true

    // store the current day of year
    this.$store.commit('currentDay', this.$store.getters.dayOfYear)

    //! TESTING CODE
    let subName = this.$store.getters.salescode == 'GLM' ? 'BS' : this.$store.getters.salescode

    axios.get(`${this.$store.getters.NODEURL}/quickstats/${subName}`)
      .then(response => {
        let tmp = response.data.performance[0]
        if (tmp) {
          this.territoryItems.push({
            yoy: tmp.YoYGrowth,
            proj: tmp.Projection,
            growth: tmp.ProjectionGrowth,
            prior: tmp.Prior,
            current: tmp.Current })
          for(var i = 0; i < 3; ++i) {
            this.topThreeItems.push(response.data.top[i])
            this.bottomThreeItems.push(response.data.bottom[i])
          }
        }
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.topThreeLoading = false
        this.bottomThreeLoading = false
      })
  },
  methods: {
    truncate: function (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    cvt(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    percent: function (pj, v) {
      return Math.round((parseInt(v) / pj) * 100, 1)
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    cvp(value) {
      let v = (value === undefined ? 0 : value)
      return v.toLocaleString('en-US', {
        minimumFractionDigits: v < 10 ? 1 : 0,
        maximumFractionDigits: v < 10 ? 1 : 0
      })
    }
  },
  computed: {}
}
</script>
