<template>
  <v-container fluid>

    <!-- v-toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>New Business Alerts</v-toolbar-title>
      <v-divider class="mx-2 grey" inset vertical></v-divider>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="2" text outlined v-bind="attrs" v-on="on" class="blue darken-2 ml-1" @click="load()">
            <v-icon color="white">mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-checkbox class="smallerBox" hide-details v-model="archived" color="yellow" label="Archived"></v-checkbox>
    </v-toolbar>

    <!-- NBA list -->
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="testpanels" focusable>
          <v-expansion-panel v-for="n in nbaTableItems" :key="n.pID" @change="showSellablesforID(n.pID)">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="4" class="body-2">
                  <span class="blue--text font-weight-bold">{{n.nbaName.toUpperCase()}}</span> -
                  <span class="caption">{{n.salesperson}}</span><br />
                  {{n.customer.toUpperCase()}}<span class="font-weight-bold float-right">{{cvt(n.nbaValue)}}</span>
                </v-col>
                <v-col cols="2" offset-md="1" class="caption">
                  Created <span class="green--text font-weight-bold">{{n.created}}</span><br />
                  Need By <span :class="n.remain < 0 ? 'red--text text-decoration-line-through':'green--text'" class="font-weight-bold">{{n.estimatedNeedDate}}</span>
                </v-col>
                <v-col cols="2" class="caption">
                  <span :class="!n.terms ? 'red--text text--darken-4' : 'green--text'">Account is {{!n.verified ? 'not':''}} verified</span><br />
                  <span :class="!n.terms ? 'red--text text--darken-4' : 'green--text'">{{!n.terms ? 'Have not':'Has'}} accepted terms</span>
                </v-col>
                <v-col cols="1" offset-md="1" class="pt-1">
                  <v-btn @click="__nbaDetails(n.pID)" elevation="2" text outlined small dark class="blue darken-2">Edit <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-slot:actions>
                <v-icon color="primary">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-4" style="background-color:#444;">
              <v-data-table :headers="sellablesTableHeaders" :items="sellablesTableItems" id="sellablesTable"
                :items-per-page="8" :loading="sellablesTableLoading" class="elevation-1 caption" hide-default-footer
                :footer-props="{ 'items-per-page-options': [-1] }">
                <template v-slot:item.name="prop">{{prop.item.name}}</template>
                <template v-slot:item.rd="prop">
                  <div :class="someColor(prop.item.rd, 2)" class="caption">
                    {{prop.item.rd == 2 ? 'Complete' : prop.item.rd == 1 ? 'Partial' : 'Not Started'}}</div>
                </template>
                <template v-slot:item.qc="prop">
                  <div :class="someColor(prop.item.qc, 2)" class="caption">
                    {{prop.item.qc == 2 ? 'Complete' : prop.item.qc == 1 ? 'Partial' : 'Not Started'}}</div>
                </template>
                <template v-slot:item.pc="prop">
                  <div :class="someColor(prop.item.pc, 3)" class="caption">
                    {{prop.item.qc == 3 ? 'Complete' : prop.item.qc == 2 || prop.item.qc == 1 ? 'Partial' : 'Not Started'}}
                  </div>
                </template>
                <template v-slot:item.qs="prop">
                  <div :class="someColor(prop.item.qs, 1)" class="caption">
                    {{prop.item.qs == 1 ? 'Complete' : 'Not Started'}}</div>
                </template>
                <template v-slot:item.op="prop">
                  <div :class="someColor(prop.item.op, 1)" class="caption">
                    {{prop.item.op == 1 ? 'Complete' : 'Not Started'}}</div>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import {
  forEach
} from 'async'
export default {
  name: "NewBusinessAlert",
  data: () => ({
    // sellables table
    sellablesTableLoading: false,
    sellablesTableHeaders: [{
        text: "Item",
        value: "item",
        class: "black--text font-weight-bold"
      },
      {
        text: "Description",
        value: "name",
        class: "black--text font-weight-bold"
      },
      {
        text: "Research",
        value: "rd",
        class: "black--text font-weight-bold"
      },
      {
        text: "Quality",
        value: "qc",
        class: "black--text font-weight-bold"
      },
      {
        text: "Purchasing",
        value: "pc",
        class: "black--text font-weight-bold"
      },
      {
        text: "Customer Service",
        value: "qs",
        class: "black--text font-weight-bold"
      },
      {
        text: "Operations",
        value: "op",
        class: "black--text font-weight-bold"
      }
    ],
    sellablesTableItems: [],
    //
    testpanels: [],
    show: [],
    expanded: [],
    loading: [],
    nbaLoading: false,
    nbaSearch: '',
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    rightdrawer: false,
    nbaTableItems: [],
    nbaStatusTableItems: [],
    selectRowIndex: 0,
    selectedSalesforceID: '',
    auxTextFields: [0, false, false],
    nbaTableHeaders: [{
        text: "Name",
        align: "left",
        sortable: true,
        value: "nbaName"
      },
      {
        text: "Customer",
        align: "left",
        sortable: true,
        value: "customer"
      },
      {
        text: "Created",
        align: "left",
        sortable: true,
        value: "created"
      },
      {
        text: "Value",
        align: "left",
        sortable: true,
        value: "nbaValue"
      },
      {
        text: "Need By",
        align: "left",
        sortable: true,
        value: "estimatedNeedDate"
      },
      {
        text: "Salesperson",
        align: "left",
        sortable: false,
        value: "salesperson"
      },
    ],
    nbaStatusTableHeader: [{
        text: "Name",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Status",
        align: "left",
        sortable: false,
        value: "status"
      }
    ],
    archived: false
  }),
  mounted: function () {
    this.load()
  },
  computed: {},
  methods: {
    showSellablesforID(e) {
      this.sellablesTableItems = []
      this.sellablesTableLoading = true
      axios.get(`${this.$store.getters.NODEURL}/api/1/alerts/${e}/sellables`, {
          withCredentials: true
        })
        .then((response) => {
          //this.expanded = response.data
          response.data.forEach(e => {
            this.sellablesTableItems.push({
              pID: e.pID,
              item: e.itemCode,
              name: e.name,
              rd: e.flags[0],
              qc: e.flags[1],
              pc: e.flags[2],
              qs: e.flags[3],
              op: e.flags[4]
            })
          })
        })
        .catch((err) => {
          console.log(`showSellablesforID ${err}`)
        })
        .finally(() => {
          this.sellablesTableLoading = false
        })
    },
    colorDaysRemain: function () {
      let v = this.auxTextFields[0]
      return v > 90 ? 'green-text' : v > 60 ? 'orange--text text--darken-4' : 'red--text text--darken-4'
    },
    verified: function () {
      return `Is ${this.auxTextFields[1] === true ? '' : 'not'} verified`
    },
    terms: function () {
      return `Has ${this.auxTextFields[1] === true ? '' : 'not'} accepted payment terms`
    },
    linktoSalesforce: function () {
      window.open(`https://flavormaterialsinternational.my.salesforce.com/${this.selectedSalesforceID}`, '_blank')
    },
    __nbaDetails: function (nbaID) {
      this.$router.push(`/nba/details/${nbaID}`).catch((e) => {
        console.log(e)
      })
    },
    someColor(flag, max) {
      let ret = ''
      switch (max) {
        case 1:
          ret = (flag < max ? 'red--text text--lighten-2' : 'green--text font-weight-bold')
          break
        case 2:
          ret = (flag == 0 ? 'red--text text--lighten-2' : flag == 1 ? 'orange--text' : 'green--text font-weight-bold')
          break
        case 3:
          ret = (flag == 0 || flag == 1 ? 'red--text text--lighten-2' : flag == 2 ? 'orange--text' : 'green--text font-weight-bold')
          break
        default:
          ret = 'black'
          break
      }
      return ret
    },
    cvt(value) {
      let v = (value === undefined ? 0 : +value)
      return v.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    cvp(value) {
      let v = (value === undefined ? 0 : value)
      return v.toLocaleString('en-US', {
        minimumFractionDigits: v < 10 ? 1 : 0,
        maximumFractionDigits: v < 10 ? 1 : 0
      })
    },
    load: function () {
      this.nbaLoading = true
      this.nbaTableItems = []
      this.show = []
      this.expanded = []
      axios
        .get(`${this.$store.getters.NODEURL}/api/1/alerts/${this.archived === false ? 0 : 1}`, {
          withCredentials: true
        })
        .then((response) => {
          let td = response.data.data
          this.nbaTableItems = td
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.nbaLoading = false
        })
    }
  }
}
</script>

<style scoped>
 .nba-expired { background:white url('../assets/expire.png') right no-repeat; } 
 #sellablesTable >>> .text-start { font-size: 12px!important; }
 .mcap >>> label { font-size: 14px!important; }
 .smallerBox >>> label { font-size: 14px!important; }
</style>
