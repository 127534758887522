<template>
  <v-container fluid>
    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
      </template>
    </v-snackbar>
    <confirm ref="confirm"></confirm>
    <v-row dense>
      <v-col md6 lg6 cols="6">
        <v-select :items="slSalesAgents" v-model="vmSalesAgents" label="Select Sales Agent" outlined
          :disabled="dsSalesAgents" :loading="loadSalesList" @change="pickSalesperson" hide-details
          @click:clear="clearsales" append-icon="mdi-database-search" clearable>
        </v-select>
      </v-col>
      <v-col md6 lg6 cols="6">
        <v-autocomplete outlined @change="getsalesdata" v-model="vmCustomerList" :disabled="!loadedSalesAgent"
          :items="slCustomerList" :loading="loadCompanyList" :search-input.sync="companysearch" hide-selected
          hide-details item-text="text" item-value="value" placeholder="Start typing to Search"
          append-icon="mdi-database-search" return-object></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md6 lg6 cols="6">
        <v-data-table :headers="SalesPriorHeaders" :items="totalsalesPYEAR" :loading="loadSalesPYEAR"
          hide-default-footer class="elevation-3" item-key="id">
          <template v-slot:top>
            <v-toolbar color="blue darken-4 elevation-0" dark dense>
              <v-select :disabled="!loadedSalesData" v-model="periodyearvalue" :items="periodyear" dark outlined
                hide-details dense class="input-select">
              </v-select>
              <v-btn :disabled="!loadedSalesData" text small color="yellow" @click="updatesalesdata">
                <v-icon dark>mdi-refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.q1="props">
            <span v-if="props.item.id===0">{{ props.item.q1 }}</span>
            <span v-else-if="props.item.id===1 || props.item.id===3" :class="pon(props.item.q1)">
              {{ props.item.q1 }}% <v-icon small v-if="props.item.q1 < 0">mdi-chevron-down</v-icon>
              <v-icon small v-else>mdi-chevron-up</v-icon>
            </span>
            <span v-else>{{ toKilos(props.item.q1) }}</span>
          </template>
          <template v-slot:item.q2="props">
            <span v-if="props.item.id===0">{{ props.item.q2 }}</span>
            <span v-else-if="props.item.id===1 || props.item.id===3" :class="pon(props.item.q2)">
              {{ props.item.q2 }}% <v-icon small v-if="props.item.q2 < 0">mdi-chevron-down</v-icon>
              <v-icon small v-else>mdi-chevron-up</v-icon>
            </span>
            <span v-else>{{ toKilos(props.item.q2) }}</span>
          </template>
          <template v-slot:item.q3="props">
            <span v-if="props.item.id===0">{{ props.item.q3 }}</span>
            <span v-else-if="props.item.id===1 || props.item.id===3" :class="pon(props.item.q3)">
              {{ props.item.q3 }}% <v-icon small v-if="props.item.q3 < 0">mdi-chevron-down</v-icon>
              <v-icon small v-else>mdi-chevron-up</v-icon>
            </span>
            <span v-else>{{ toKilos(props.item.q3) }}</span>
          </template>
          <template v-slot:item.q4="props">
            <span v-if="props.item.id===0">{{ props.item.q4 }}</span>
            <span v-else-if="props.item.id===1 || props.item.id===3" :class="pon(props.item.q4)">
              {{ props.item.q4 }}% <v-icon small v-if="props.item.q4 < 0">mdi-chevron-down</v-icon>
              <v-icon small v-else>mdi-chevron-up</v-icon>
            </span>
            <span v-else>{{ toKilos(props.item.q4) }}</span>
          </template>
          <template v-slot:item.total="props">
            <b>
              <span v-if="props.item.id===0">{{ toCurrency(props.item.total) }}</span>
              <span v-else-if="props.item.id===1">0%</span>
              <span v-else-if="props.item.id===2">{{ toKilos(props.item.total) }} <span
                  style="font-size: xx-small">KG</span></span>
              <span v-else>0%</span>
            </b>
          </template>
        </v-data-table>
      </v-col>
      <v-col md6 lg6 cols="6">
        <v-data-table :headers="SalesYTDHeaders" :items="totalsalesYTD" :loading="loadSalesYTD"
          hide-default-footer class="elevation-3" item-key="id">
          <template v-slot:top>
            <v-toolbar color="blue darken-4 elevation-0" dark dense>
              <v-toolbar-title>YTD Sales / Projections</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-switch v-model="percentSwitch" dense hide-details label="% of target"></v-switch>
            </v-toolbar>
          </template>
          <template v-slot:item.q1="props">
            <span v-if="props.item.id===0">{{ toCurrency(props.item.q1) }}</span>
            <span v-else-if="props.item.id===1" class="editable--text">{{ toCurrency(props.item.q1) }}</span>
            <span v-else-if="props.item.id===2">{{ toKilos(props.item.q1) }}</span>
            <span v-else class="editable--text">{{ toKilos(props.item.q1) }}</span>
          </template>
          <template v-slot:item.q2="props">
            <span v-if="props.item.id===0">{{ toCurrency(props.item.q2) }}</span>
            <span v-else-if="props.item.id===1" class="editable--text">{{ toCurrency(props.item.q2) }}</span>
            <span v-else-if="props.item.id===2">{{ toKilos(props.item.q2) }}</span>
            <span v-else class="editable--text">{{ toKilos(props.item.q2) }}</span>
          </template>
          <template v-slot:item.q3="props">
            <span v-if="props.item.id===0">{{ toCurrency(props.item.q3) }}</span>
            <span v-else-if="props.item.id===1" class="editable--text">{{ toCurrency(props.item.q3) }}</span>
            <span v-else-if="props.item.id===2">{{ toKilos(props.item.q3) }}</span>
            <span v-else class="editable--text">{{ toKilos(props.item.q3) }}</span>
          </template>
          <template v-slot:item.q4="props">
            <span v-if="props.item.id===0">{{ toCurrency(props.item.q4) }}</span>
            <span v-else-if="props.item.id===1" class="editable--text">{{ toCurrency(props.item.q4) }}</span>
            <span v-else-if="props.item.id===2">{{ toKilos(props.item.q4) }}</span>
            <span v-else class="editable--text">{{ toKilos(props.item.q4) }}</span>
          </template>

          <template v-slot:item.total="props">
            <b>
              <span v-if="props.item.id===0 || props.item.id===1">{{ toCurrency(props.item.total) }}</span>
              <span v-else>{{ toKilos(props.item.total) }} <span style="font-size: xx-small">KG</span></span>
            </b>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" lg12 md12 sm12>
        <v-data-table :headers="ItemProductHeaders" :loading="loadSalesDetails" id="ItemProductHeaders"
          :items="partsitems" :items-per-page="-1" class="elevation-4" item-key="id">
          <template v-slot:top>
            <v-toolbar color="blue darken-4 elevation-0" dark dense>
              <v-toolbar-title>Products</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn :disabled="!companysearch" @click="addProductProjection = true" class="ml-2" light small><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn :disabled="!companysearch" @click="mirrorCurrentSalesFigures" class="ml-2" light small><v-icon>mdi-database-arrow-right-outline</v-icon></v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title>2021 Projections</v-toolbar-title>
              <v-btn @click="saveprojections" class="ml-4" color="orange lighten-1" light v-if="pendingchanges">Save <v-icon right small color="black">mdi-content-save-settings-outline</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:header.cq1="{ header }">
            <span v-if="true">{{ header.text }}</span>
            <span v-else class="secondary--text">CLOSED</span>
          </template>
          <template v-slot:header.cq2="{ header }">
            <span v-if="true">{{ header.text }}</span>
            <span v-else class="secondary--text">CLOSED</span>
          </template>
          <template v-slot:header.cq3="{ header }">
            <span v-if="true">{{ header.text }}</span>
            <span v-else class="secondary--text">CLOSED</span>
          </template>
          <template v-slot:header.cq4="{ header }">
            <span v-if="true">{{ header.text }}</span>
            <span v-else class="secondary--text">CLOSED</span>
          </template>
          <template v-slot:item.DescItem="props">
            <span class="font-weight-bold" v-if="props.item.id%2===0">{{props.item.ItemCore}}</span>
            <span class="font-weight-bold" v-else>{{ props.item.DescItem }}</span>
          </template>
          <template v-slot:item.pq1="props">
            <span v-if="props.item.id%2===0">{{ toCurrency(props.item.pq1) }}</span>
            <span v-else>{{ toKilos(props.item.pq1) }}</span>
          </template>
          <template v-slot:item.pq2="props">
            <span v-if="props.item.id%2===0">{{ toCurrency(props.item.pq2) }}</span>
            <span v-else>{{ toKilos(props.item.pq2) }}</span>
          </template>
          <template v-slot:item.pq3="props">
            <span v-if="props.item.id%2===0">{{ toCurrency(props.item.pq3) }}</span>
            <span v-else>{{ toKilos(props.item.pq3) }}</span>
          </template>
          <template v-slot:item.pq4="props">
            <span v-if="props.item.id%2===0">{{ toCurrency(props.item.pq4) }}</span>
            <span v-else>{{ toKilos(props.item.pq4) }}</span>
          </template>
          <template v-slot:item.ptotal="props">
            <b>
              <span v-if="props.item.id%2===0">{{ toCurrency(props.item.ptotal) }}</span>
              <span v-else>{{ toKilos(props.item.ptotal) }} <span style="font-size: xx-small">KG</span></span>
            </b>
          </template>

          <template v-slot:item.action="props">
            <v-edit-dialog @save="saveedit(props.item.id)" large>
              <v-icon color=blue small>mdi-pencil</v-icon>
              <template v-slot:input color="red">
                <v-text-field @keydown="validate" class="mt-2" hide-details label="Jan(Q1)" maxlength="7"
                  v-model="props.item.cq1" flat outlined single-line></v-text-field>
                <v-text-field @keydown="validate" class="mt-2" hide-details label="Apr(Q2)" maxlength="7"
                  v-model="props.item.cq2" flat outlined single-line></v-text-field>
                <v-text-field @keydown="validate" class="mt-2" hide-details label="Jul(Q3)" maxlength="7"
                  v-model="props.item.cq3" flat outlined single-line></v-text-field>
                <v-text-field @keydown="validate" class="mt-2" hide-details label="Oct(Q4)" maxlength="7"
                  v-model="props.item.cq4" flat outlined single-line></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.cq1="props">
            <span v-if="props.item.id%2===0"
              :class="$store.getters.Q1AllowEdit ? 'editable--text' : 'accent--text'">{{props.item.cq1}}</span>
            <span v-else
              :class="$store.getters.Q1AllowEdit ? 'editable--text' : 'accent--text'">{{ props.item.cq1 }}</span>
          </template>
          <template v-slot:item.cq2="props">
            <span v-if="props.item.id%2===0"
              :class="$store.getters.Q2AllowEdit ? 'editable--text' : 'accent--text'">{{props.item.cq2}}</span>
            <span v-else
              :class="$store.getters.Q2AllowEdit ? 'editable--text' : 'accent--text'">{{ props.item.cq2 }}</span>
          </template>
          <template v-slot:item.cq3="props">
            <span v-if="props.item.id%2===0"
              :class="$store.getters.Q3AllowEdit ? 'editable--text' : 'accent--text'">{{props.item.cq3}}</span>
            <span v-else
              :class="$store.getters.Q3AllowEdit ? 'editable--text' : 'accent--text'">{{ props.item.cq3 }}</span>
          </template>
          <template v-slot:item.cq4="props">
            <span v-if="props.item.id%2===0"
              :class="$store.getters.Q4AllowEdit ? 'editable--text' : 'accent--text'">{{props.item.cq4}}</span>
            <span v-else
              :class="$store.getters.Q4AllowEdit ? 'editable--text' : 'accent--text'">{{ props.item.cq4 }}</span>
          </template>
          <template v-slot:item.ctotal="props">
            <b>
              <span v-if="props.item.id%2===0">{{ toCurrency(props.item.ctotal) }}</span>
              <span v-else>{{ toKilos(props.item.ctotal) }} <span style="font-size: xx-small">KG</span></span>
            </b>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-navigation-drawer v-model="addProductProjection" absolute temporary width="400" class="pa-2">
      <v-toolbar color="green" dark dense>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Search</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
      </v-toolbar>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="blue--text">Part Search by Description</v-list-item-title>
            <v-autocomplete @change="pickItemCoreValue" @click:clear="clearItemCoreValue" v-model="itemCoreSearch"
              :items="itemCoreSearchItems" :loading="itemCoreSearchLoading" :search-input.sync="itemCoreSearchSync"
              clearable hide-selected hide-no-data dense light background-color="white" item-text="text"
              item-value="value" :disabled="itemCoreSearchLoading" placeholder="Begin typing to filter list" hide-details return-object>
            </v-autocomplete>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="blue--text">Price/KG (USD)</v-list-item-title>
            <v-text-field v-model="newItemPriceKG" type="tel" placeholder="Price/KG" maxlength="6" single-line
                          @input="$v.newItemPriceKG.$touch()" @blur="$v.newItemPriceKG.$touch()" @keydown="validateKey"
                          :error-messages="priceKGError" required hide-details
                          dense counter="6"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="blue--text">Origin</v-list-item-title>
            <v-select v-model="newItemOriginSelection" :items="newItemOrigin" placeholder="Origin" clearable dense hide-details></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-card flat>
          <v-card-actions>
            <v-btn outlined color="error" @click="newItemCancel">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn outlined color="success darken-1" @click="newItemInsert" :disabled="!(newItemOriginSelection && itemCoreSearch && newItemPriceKG >= 1)">Insert</v-btn>
            <v-spacer></v-spacer>
            <v-btn outlined color="success darken-3" @click="newItemInsert" :disabled="!(newItemOriginSelection && itemCoreSearch && newItemPriceKG >= 1)">Insert/Alert</v-btn>
          </v-card-actions>
        </v-card>
      </v-list>
    </v-navigation-drawer>

  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import async from 'async'
import axios from 'axios'
import Headers from './data/projection'
import confirm from './Confirm'
import {
  helperMixin
} from '../mixins/helper'
import {
  required,
  minLength,
  numeric
} from "vuelidate/lib/validators"

const YTDUSDSales = 0,
  YTDUSDProjection = 1,
  YTDKGSales = 2,
  YTDKGProjection = 3
const MAX_QUARTERS = 4
const ITEM_PRV_COL_HEADER = 0,
  ITEM_PRV_YEAR_HEADER = 2,
  ITEM_PRV_TOTAL_HEADER = 6
const ITEM_PRO_COL_TOTAL_HEADER = 12

export default {
  name: "Projection",
  components: {
    'confirm': confirm
  },
  mixins: [helperMixin],
  data: () => ({
    newItemOrigin: ['FMI', 'SMG', 'LTCA'],
    newItemOriginSelection: null,
    newItemPriceKG: 0,
    addProductProjection: false,
    itemCoreSearch: '',
    itemCorePicked: false,
    itemCoreSearchItems: [],
    itemCoreSearchSync: null,
    itemCoreSearchLoading: false,
    fetchTimerID: null,
    // loading animations
    loadSalesPYEAR: false,
    loadSalesYTD: false,
    loadSalesDetails: false,
    loadSalesList: false,
    loadCompanyList: false,
    periodyear: ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021'],
    periodyearvalue: '',
    totalsalesYTD: [],
    totalsalesPYEAR: [],
    ItemProductHeaders: Headers.ItemProductHeaders,
    SalesPriorHeaders: Headers.SalesPriorHeaders,
    SalesYTDHeaders: Headers.SalesYTDHeaders,
    partsitems: [],
    // partsprojection: [],
    partPrices: [],
    // sales agents
    slSalesAgents: [],
    vmSalesAgents: null,
    // sales agents customers
    slCustomerList: [],
    pickedsalesrep: '',
    vmCustomerList: null,
    // selectCompanyName: '',
    dataloaded: false,
    quarters: [0, 0, 0, 0, 0, 0, 0, 0],
    kilos: [0, 0, 0, 0, 0, 0, 0, 0],
    cQTR: [0, 0, 0, 0],
    cKG: [0, 0, 0, 0],
    pendingchanges: false,
    companysearch: '',
    percentSwitch: false,
    percentageValues: [],
    loadedSalesData: false,
    loadedSalesAgent: false,
    dsSalesAgents: false,
    // tempdata: [],
    // snack bar pop ups
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
  }),
  computed: {
    priceKGError() {
      const errors = []
      if (!this.$v.newItemPriceKG.$dirty) return errors
      !this.$v.newItemPriceKG.numeric && errors.push("Must be a number")
      return errors
    },
  },
  mounted: function () {
    // grab the prior year to query along with the current year to date info
    this.periodyearvalue = (this.$store.getters.currentSalesYear - 1).toString()
    // set the column titles to incldue the year prefix
    this.columnQtrTitle()
    // grab the current day of year from the system to determine when to close off quarters
    this.$store.commit('currentDay', this.$store.getters.dayOfYear)

    if (this.$store.getters.isAdministrator) {
      // the search box for rep names will be all sales reps if admin access
      this.load()
    } else {
      // put the users "YOU" up in the box and allow them to search
      this.slSalesAgents.push({
        text: 'You',
        value: this.$store.getters.salescode
      })
      this.vmSalesAgents = this.slSalesAgents[0]
      this.dsSalesAgents = true
      this.loadedSalesAgent = true
      this.pickSalesperson(this.$store.getters.salescode)
    }

    // for(let i = 0; i < 50; ++i) { this.partsitems.push( { id: i + 1, description:`item ${i+1}`}) }
  },
  // YTDUSDSales = 0, YTDUSDProjection = 1, YTDKGSales = 2, YTDKGProjection = 3
  watch: {
    percentSwitch: function () {
      if (this.percentSwitch === true) {
        let p = 0
        for (let t = 0; t < this.cQTR.length; t++) {
          this.percentageValues[t] = this.totalsalesYTD[YTDUSDProjection][`q${t+1}`] // store the projection USD in temp
          p = this.percent(this.totalsalesYTD[YTDUSDSales][`q${t+1}`], this.percentageValues[t]) // calculate the % change
          if (p === Infinity || p === isNaN) p = 0 // set to 0 if infinity or NAN
          this.totalsalesYTD[YTDUSDProjection][`q${t+1}`] = `${p}%` // update the second row to reflect the %
          this.percentageValues[t + 4] = this.totalsalesYTD[YTDKGProjection][`q${t+1}`] // store the projection KG in temp
          p = this.percent(this.totalsalesYTD[YTDKGSales][`q${t+1}`], this.percentageValues[t + 4]) // calculate the % change
          if (p === Infinity || p === isNaN) p = 0 // set to 0 if infinity or NAN
          this.totalsalesYTD[YTDKGProjection][`q${t+1}`] = `${p}%` // update the second row to reflect the %
        }
      } else {
        for (let t = 0; t < this.cQTR.length; t++) {
          this.totalsalesYTD[YTDUSDProjection][`q${t+1}`] = this.percentageValues[t]
          this.totalsalesYTD[YTDKGProjection][`q${t+1}`] = this.percentageValues[t + 4]
        }
        this.percentageValues = []
      }
    },
    itemCoreSearchSync(payload) {
      if (this.itemCorePicked || payload == undefined) return
      if (payload.length < 3) return
      this.fetchDebounce(payload)
    }
  },
  methods: {
    mirrorCurrentSalesFigures () {
      for(let v = 0; v < this.partsitems.length; ++v) {
        this.partsitems[v].cq1 = this.partsitems[v].pq1
        this.partsitems[v].cq2 = this.partsitems[v].pq2
        this.partsitems[v].cq3 = this.partsitems[v].pq3
        this.partsitems[v].cq4 = this.partsitems[v].pq4
        this.saveedit(v)
      }
    },
    newItemCancel() {
      this.clearItemCoreValue()
      this.newItemPriceKG = 0
    },
    newItemInsert() {
      this.partsitems.push({
        id: this.partsitems.length,
        description: this.itemCoreSearch.text.split('|')[1].trim(), // remove the part # and the pipe | symbol
        ItemCore: this.itemCoreSearch.value,
        pq1: 0,
        pq2: 0,
        pq3: 0,
        pq4: 0,
        ptotal: 0,
        cq1: 0,
        cq2: 0,
        cq3: 0,
        cq4: 0,
        ctotal: 0
      })
      this.partsitems.push({
        id: this.partsitems.length + 1,
        description: '',
        ItemCore: '',
        pq1: 0,
        pq2: 0,
        pq3: 0,
        pq4: 0,
        ptotal: 0,
        cq1: 0,
        cq2: 0,
        cq3: 0,
        cq4: 0,
        ctotal: 0
      })
      this.partPrices.push({
        item: this.itemCoreSearch.value,
        value: this.newItemPriceKG
      })
    },
    fetchDebounce(payload) {
      clearTimeout(this.fetchTimerID)
      this.fetchTimerID = setTimeout(() => {
        this.queryItemCore(payload)
      }, 500);
    },
    pickItemCoreValue(payload) {
      if (payload == undefined) return
      this.itemCorePicked = true
    },
    clearItemCoreValue() {
      this.itemCorePicked = false
      this.itemCoreSearchItems = []
    },
    queryItemCore(item) {
      this.itemCoreSearchLoading = true
      this.itemCoreSearchItems = []
      axios.post(`${this.$store.getters.NODEURL}/item/search`, {
          query: item.toUpperCase()
        }, {
          withCredentials: true
        })
        .then((result) => {
          let ob = result.data.data
          Object.keys(ob).map(key => {
            this.itemCoreSearchItems.push({
              value: ob[key].ItemCore,
              text: `${ob[key].ItemCore} | ${ob[key].DescItem}`
            })
          })
        })
        .catch((err) => {
          console.warn(err)
        })
        .finally(() => {
          this.itemCoreSearchLoading = false
        })
    },
    /**
     * Generate column titles depending on the year selected in the vue model "periodyearvalue" and
     * the value in the STORE currentSalesYear
     */
    columnQtrTitle: function () {
      let cs = this.$store.getters.currentSalesYear.toString()
      for (let c = ITEM_PRV_YEAR_HEADER; c < ITEM_PRV_TOTAL_HEADER; ++c) {
        this.ItemProductHeaders[c].text = `'${this.periodyearvalue.substring(2,4)} Q${c - 1}` // display Q1 through Q4 of selected prior year
        this.ItemProductHeaders[c + 6].text = `'${cs.substring(2,4)} Q${c -1}` // display Q1 through Q4 of projection year
      }
      this.ItemProductHeaders[ITEM_PRV_TOTAL_HEADER].text = `Total` // total of prior
      this.ItemProductHeaders[ITEM_PRO_COL_TOTAL_HEADER].text = `Total` // total of projection
    },
    /**
     * Return a class object of either green or red text based on the parameters value
     *
     * @param {*} value - the value, expressed as an integer percentage to compare
     */
    pon(value) {
      return `${value < 0 ? 'red' : 'green'}--text`
    },
    percent: function (pj, v) {
      return Math.round((parseInt(v) / pj) * 100, 1)
    },
    /**
     * Handle key press validation from projection entry fields, allow backspace, delete and tab
     * and allow numbers with periods
     */
    validate(e) {
      if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab') {
        return
      } else if (!new RegExp('^[0-9.]$').test(e.key)) {
        e.preventDefault()
      }
    },
    /**
     * Look up partnumber in the parts table and return its value (price), should not return nothing
     *
     * @param {*} partnumber - string, the part number to lookup in the parts table
     */
    partPrice: function (partnumber) {
      let ret = this.partPrices.find(r => r.item === partnumber).value
      if (ret === null || ret === undefined) {
        throw "Part number is not in the parts array, this should not happen."
      }
      return ret
    },
    saveedit: function (t) {
      this.loadSalesYTD = this.loadSalesDetails = true
      this.pendingchanges = true
      let p = this.partPrices.find(x => x.item === this.partsitems[t].ItemCore)
      let j = this.partsitems[t]
      if (t % 2 === 0) { // dollar row
        for (let v = 0; v < 4; ++v) {
          this.partsitems[t + 1][`cq${v+1}`] = Math.round(this.partsitems[t][`cq${v+1}`] / p.value, 1)
        }
        this.sumEditRow(t)
      } else { // KG row
        for (let v = 0; v < 4; ++v) {
          this.partsitems[t - 1][`cq${v+1}`] = Math.round(this.partsitems[t][`cq${v+1}`] * p.value, 1)
        }
        this.sumEditRow(t - 1)
      }
      this.projectionUpdate()
    },
    sumEditRow: function (row) {
      let j = this.partsitems[row],
        v = this.partsitems[row + 1] // always start at dollar row (even)
      let sum1 = [parseInt(j.cq1), parseInt(j.cq2), parseInt(j.cq3), parseInt(j.cq4)]
      let sum2 = [parseInt(v.cq1), parseInt(v.cq2), parseInt(v.cq3), parseInt(v.cq4)]
      this.partsitems[row].ctotal = sum1.reduce((a, b) => a + b, 0)
      this.partsitems[row + 1].ctotal = sum2.reduce((a, b) => a + b, 0)
    },
    ysColor: function (a, b) {
      return a > b ? 'green--text' : 'red--text'
    },
    clearsales: function () {
      this.zerodata()
      this.dataloaded = false
      this.companysearch = ''
    },
    sumYears: function (year) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].PeriodYear === year) {
          sum += this.jsondata[i].TotalDollars
        }
      }
      return sum
    },
    totalKGbyQuarter: function (data, item, year) {
      let result = [4]
      var sum
      for (let p = 0; p < 4; ++p) {
        sum = 0
        for (let i = 0; i < data.length; ++i) {
          if (data[i].PeriodQTR === `Q${p+1}` && data[i].ItemCore === item && data[i].PeriodYear === year) {
            sum += Math.round(data[i].Qtyship * 100) / 100
          }
        }
        result[p] = sum
      }
      return result
    },
    totalDollarbyQuarter: function (data, item, year) {
      let result = [4]
      for (let p = 0; p < 4; ++p) {
        let sum = 0
        for (let i = 0; i < data.length; ++i) {
          if (data[i].PeriodQTR === `Q${p+1}` && data[i].ItemCore === item && data[i].PeriodYear === year) {
            sum += Math.round(data[i].TotalDollars, 1)
          }
        }
        result[p] = sum
      }
      return result
    },
    updatesalesyes: function () {
      this.ploading = true
      this.zerodata()
      this.columnQtrTitle()
      this.getsalesdata()
    },
    updatesalesno: function () {
      // make the save changes button flash or something
    },
    updatesalesdata: async function (item) {
      if (this.pendingchanges) {
        if (await this.$refs.confirm.open(
            'Pending Changes',
            'You have unsaved pending changes to your projections.',
            'Click yes to lose these changes and load a different sales year or cancel to save your changes.', {
              color: 'error',
              width: 350
            })) {
          this.updatesalesyes()
        } else {
          this.updatesalesno()
        }
      } else {
        this.updatesalesyes()
      }
    },
    processYTDData: function (data) {

      this.totalsalesYTD = []

      for (var t = 0; t < data.length; ++t) {
        this.cQTR[t] = data[t].Total
        this.cKG[t] = data[t].KG
      }

      // current YTD Sales Row
      this.totalsalesYTD.push({
        id: 0,
        key: 'USD',
        q1: this.cQTR[0],
        q2: this.cQTR[1],
        q3: this.cQTR[2],
        q4: this.cQTR[3],
        total: this.cQTR[0] + this.cQTR[1] + this.cQTR[2] + this.cQTR[3]
      })
      // current YTD Sales Row Projection
      this.totalsalesYTD.push({
        id: 1,
        key: 'USD',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        total: 0
      })
      // current YTD KILOS Row 
      this.totalsalesYTD.push({
        key: 'KG',
        id: 2,
        q1: this.cKG[0],
        q2: this.cKG[1],
        q3: this.cKG[2],
        q4: this.cKG[3],
        total: this.cKG[0] + this.cKG[1] + this.cKG[2] + this.cKG[3]
      })
      // current YTD KILOS Row Projection
      this.totalsalesYTD.push({
        id: 3,
        key: 'KG',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        total: 0
      })

      this.loadSalesYTD = false
    },
    processSalesData: function (data) {

      this.totalsalesPYEAR = []

      const percent = (a, b) => {
        if (b === 0 || a === 0) {
          return 'NA'
        } else return Math.round((a / b) - 1, 1)
      }

      let previous = this.periodyearvalue
      let twoyearprior = (previous - 1).toString()

      /* eslint-disable no-empty */
      for (var t = 0, l = 4; t < this.quarters.length / 2; ++t, ++l) { // go through the prior quarters
        try {
          this.quarters[t] = data.find(o => o.PeriodQTR === `Q${t+1}` && o.PeriodYear === previous).Total
          this.kilos[t] = data.find(o => o.PeriodQTR === `Q${t+1}` && o.PeriodYear === previous).KG
          this.quarters[l] = data.find(o => o.PeriodQTR === `Q${t+1}` && o.PeriodYear === twoyearprior).Total
          this.kilos[l] = data.find(o => o.PeriodQTR === `Q${t+1}` && o.PeriodYear === twoyearprior).KG
        } catch {}
      }

      // data will be 8 rows, 4 quarters for queried year, 4 rows for the year prior
      this.totalsalesPYEAR.push({
        id: 0,
        text: 'USD',
        q1: this.toCurrency(this.quarters[0]),
        q2: this.toCurrency(this.quarters[1]),
        q3: this.toCurrency(this.quarters[2]),
        q4: this.toCurrency(this.quarters[3]),
        total: this.quarters[0] + this.quarters[1] + this.quarters[2] + this.quarters[3]
      })
      this.totalsalesPYEAR.push({
        id: 1,
        text: `YoY % Growth '${twoyearprior.substring(2,4)}`,
        q1: percent(this.quarters[0], this.quarters[4]),
        q2: percent(this.quarters[1], this.quarters[5]),
        q3: percent(this.quarters[2], this.quarters[6]),
        q4: percent(this.quarters[3], this.quarters[7]),
        total: 0
      })
      this.totalsalesPYEAR.push({
        id: 2,
        text: 'KG',
        q1: this.toKilos(this.kilos[0]),
        q2: this.toKilos(this.kilos[1]),
        q3: this.toKilos(this.kilos[2]),
        q4: this.toKilos(this.kilos[3]),
        total: this.toKilos(this.kilos[0] + this.kilos[1] + this.kilos[2] + this.kilos[3])
      })
      this.totalsalesPYEAR.push({
        id: 3,
        text: `YoY % Growth '${twoyearprior.substring(2,4)}`,
        q1: percent(this.kilos[0], this.kilos[4]),
        q2: percent(this.kilos[1], this.kilos[5]),
        q3: percent(this.kilos[2], this.kilos[6]),
        q4: percent(this.kilos[3], this.kilos[7]),
        total: 0
      })
      this.loadSalesPYEAR = false
    },
    averagePricePart: function (data, part) {
      let parts = data.filter((v) => v.ItemCore == part)
      let average = 0.0
      if (parts.length > 1) {
        average = parts.reduce((a, b) => a + b.Unitprice, 0)
        average /= parts.length
      } else {
        average = parts[0].Unitprice
      }
      return average === 0 ? 1 : average
    },
    //* update code to search for average price across all sales
    processItemData: function (data) {
      this.partPrices = []
      let pyv = this.periodyearvalue
      for (var c = 0, d = 0; c < data.length; ++c) {
        if (!this.partsitems.find(o => o.ItemCore === data[c].ItemCore)) {
          this.partPrices.push({
            item: data[c].ItemCore,
            value: this.averagePricePart(data, data[c].ItemCore)
          })
          let psum = this.totalDollarbyQuarter(data, data[c].ItemCore, pyv)
          let csum = [0, 0, 0, 0]
          // first row push is the part, in dollars, per quarter for the prior year
          this.partsitems.push({
            id: d,
            description: data[c].DescItem,
            ItemCore: data[c].ItemCore,
            pq1: psum[0],
            pq2: psum[1],
            pq3: psum[2],
            pq4: psum[3],
            ptotal: psum.reduce((s, c) => s + c, 0),
            cq1: csum[0],
            cq2: csum[1],
            cq3: csum[2],
            cq4: csum[3],
            ctotal: 0
          })
          // second push is the same part but in kilos.
          psum = this.totalKGbyQuarter(data, data[c].ItemCore, pyv)
          this.partsitems.push({
              id: ++d,
              // DescItem: data[c].DescItem,
              ItemCore: data[c].ItemCore,
              pq1: psum[0],
              pq2: psum[1],
              pq3: psum[2],
              pq4: psum[3],
              ptotal: psum.reduce((s, c) => s + c, 0),
              cq1: csum[0],
              cq2: csum[1],
              cq3: csum[2],
              cq4: csum[3],
              ctotal: 0
            })
            ++d
        }
      }
    },
    projectionUpdate: function () {
      let i = null,
        csums = [0, 0, 0, 0],
        ksums = [0, 0, 0, 0]
      for (var c = 0; c < this.partsitems.length; c += 2) {
        for (var t = 0; t < 4; ++t) {
          csums[t] += parseInt(this.partsitems[c][`cq${t+1}`]) // update $
          ksums[t] += Math.round(this.partsitems[c + 1][`cq${t+1}`], 2) //update kg
        }
      }
      for (t = 0; t < 4; ++t) {
        this.totalsalesYTD[YTDUSDProjection][`q${t+1}`] = csums[t] // update YTD sales projection table
        this.totalsalesYTD[YTDKGProjection][`q${t+1}`] = ksums[t]
      }
      this.totalsalesYTD[YTDUSDProjection].total = csums.reduce((a, b) => a + b, 0) // update totals
      this.totalsalesYTD[YTDKGProjection].total = ksums.reduce((a, b) => a + b, 0)
      this.loadSalesYTD = this.loadSalesDetails = false
    },
    projectionData: function (data) {

      let i = null
      let pjUSDTotal = [0, 0, 0, 0]
      let pjKiloTotal = [0, 0, 0, 0]

      for (let c = 0; c < data.length; ++c) {
        if (!this.partsitems.find(o => o.ItemCore === data[c].ItemCore)) {
          this.partPrices.push({
            item: data[c].ItemCore,
            value: data[c].CurrentPrice
          })
        }
      }

      for (let c = 0; c < data.length; ++c) {
        i = this.partsitems.findIndex(o => o.ItemCore === data[c].ItemCore)
        if (i >= 0) {
          let dsum = 0,
            ksum = 0
          for (let t = 0; t < 4; ++t) { // quarters
            this.partsitems[i][`cq${t+1}`] = data[c][`Q${t+1}`] // store the projection from the database
            dsum += data[c][`Q${t+1}`] // add the projection totals together
            pjUSDTotal[t] += data[c][`Q${t+1}`] // store the total by quarter
            this.totalsalesYTD[YTDUSDProjection][`q${t+1}`] += data[c][`Q${t+1}`] // add the projection total to the YTD table
            this.partsitems[i + 1][`cq${t+1}`] = data[c][`KG${t+1}`] // store the projection from the database (KILOS)
            ksum += data[c][`KG${t+1}`] // add the projection totals together (KILOS)
            pjKiloTotal[t] += data[c][`KG${t+1}`] // store the total by quarter
            this.totalsalesYTD[YTDKGProjection][`q${t+1}`] += data[c][`KG${t+1}`] // add the projection total to the YTD table
          }
          this.partsitems[i].ctotal = dsum // show the total of the row (DOLLAR)
          this.partsitems[i + 1].ctotal = ksum // show the total of the row (KILO)
        }
      }

      for (let c = 0; c < data.length; ++c) {
        // if a projection item is not in the current table, add it with all 0 values.
        if (!this.partsitems.find(o => o.ItemCore === data[c].ItemCore)) {
          console.log(`adding extra projection item ${data[c].ItemCore}`)
          let index = this.partsitems.length
          this.partsitems.push({
            id: index,
            description: '',
            ItemCore: data[c].ItemCore,
            cq1: 0,
            cq2: 0,
            cq3: 0,
            cq4: 0,
            pq1: '-',
            pq2: '-',
            pq3: '-',
            pq4: '-',
            ctotal: 0,
            ptotal: '-'
          })
          this.partsitems.push({
            id: index + 1,
            description: '',
            ItemCore: '',
            cq1: 0,
            cq2: 0,
            cq3: 0,
            cq4: 0,
            pq1: '-',
            pq2: '-',
            pq3: '-',
            pq4: '-',
            ctotal: 0,
            ptotal: '-'
          })
          console.log(`zeroing prior quarterly figures`)
          let dsum = 0,
            ksum = 0
          for (let t = 0; t < 4; ++t) { // quarters
            this.partsitems[index][`cq${t+1}`] = data[c][`Q${t+1}`] // store the projection from the database
            dsum += data[c][`Q${t+1}`] // add the projection totals together
            pjUSDTotal[t] += data[c][`Q${t+1}`] // store the total by quarter
            this.totalsalesYTD[YTDUSDProjection][`q${t+1}`] += data[c][`Q${t+1}`] // add the projection total to the YTD table
            this.partsitems[index + 1][`cq${t+1}`] = data[c][`KG${t+1}`] // store the projection from the database (KILOS)
            ksum += data[c][`KG${t+1}`] // add the projection totals together (KILOS)
            pjKiloTotal[t] += data[c][`KG${t+1}`] // store the total by quarter
            this.totalsalesYTD[YTDKGProjection][`q${t+1}`] += data[c][`KG${t+1}`] // add the projection total to the YTD table
          }
          this.partsitems[index].ctotal = dsum // show the total of the row (DOLLAR)
          this.partsitems[index + 1].ctotal = ksum // show the total of the row (KILO)
          console.log(`done`)
        }
      }

      this.totalsalesYTD[YTDUSDProjection].total = pjUSDTotal.reduce((a, b) => a + b, 0)
      this.totalsalesYTD[YTDKGProjection].total = pjKiloTotal.reduce((a, b) => a + b, 0)
      this.loadSalesDetails = false
    },
    zerodata: function () {
      this.partsitems = []
      this.totalsalesPYEAR = []
      this.totalsalesYTD = []
      // this.partsprojection = []
      this.quarters = [0, 0, 0, 0, 0, 0, 0, 0]
      this.kilos = [0, 0, 0, 0, 0, 0, 0, 0]
      this.cQTR = [0, 0, 0, 0]
      this.cKG = [0, 0, 0, 0]
    },
    getsalesdata: async function (item) {

      if (this.vmCustomerList === undefined || this.vmCustomerList.value === undefined) {
        return
      } else {
        this.zerodata()
      }

      this.loadSalesPYEAR = true
      this.loadSalesYTD = true

      let s = this.pickedsalesrep,
        p = this.periodyearvalue,
        v = this.vmCustomerList.value,
        cs = this.$store.getters.currentSalesYear,
        url = this.$store.getters.NODEURL

      async.waterfall(
        [
          // Get current users sales by quarter
          async.apply(function (that, callback) {
              axios.get(`${url}/salespeople/salesbyquarter/${s}/${p}/${v}`, {
                  withCredentials: true
                })
                .then(response => {
                  that.processSalesData(response.data)
                })
                .catch(e => {
                  console.log(e)
                })
                .finally(() => {
                  callback(null, that)
                })
            }, this),
            // Get current users sales current year
            function (that, callback) {
              axios.get(`${url}/salespeople/salesbyquarter/${s}/${cs}/${v}`, {
                  withCredentials: true
                })
                .then(response => {
                  that.processYTDData(response.data.filter(o => o.PeriodYear === cs.toString()))
                })
                .catch(e => {
                  console.log(e)
                })
                .finally(() => {
                  callback(null, that)
                })
            },
            // Get current parts of the users company 
            function (that, callback) {
              axios.get(`${url}/salespeople/partsbyquarter/${s}/${p}/${v}`, {
                  withCredentials: true
                })
                .then(response => {
                  that.processItemData(response.data)
                })
                .catch(e => {
                  console.log(e)
                })
                .finally(() => {
                  that.loadedSalesData = true
                  callback(null, that)
                })
            },
            // get their projections
            function (that, callback) {
              axios.get(`${url}/salespeople/projection/${s}/${v}`, {
                  withCredentials: true
                })
                .then(response => {
                  that.projectionData(response.data)
                })
                .catch(e => {
                  console.log(e)
                })
                .finally(() => {
                  callback(null, that)
                })
            }
        ],
        function (err, that) {})

    },
    pickSalesperson: function (item) {
      if (item === undefined) {
        this.slCustomerList = []
        return
      } else {

        this.loadCompanyList = true
        this.pickedsalesrep = item

        axios
          .get(`${this.$store.getters.NODEURL}/customers/projection/${item}`, {
            withCredentials: true
          })
          .then(response => {
            let list = []
            for (var k in response.data) {
              list.push({
                text: response.data[k].CustName,
                value: response.data[k].CustNo
              })
            }
            this.slCustomerList = list
          })
          .catch(e => {
            this.slCustomerList = []
            console.log(e)
          })
          .finally(() => {
            this.loadedSalesAgent = true
            this.loadCompanyList = false
          })
      }
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    load: function () {
      this.loadSalesList = true
      // load the list of sales people from the database
      let list = []
      axios
        .get(`${this.$store.getters.NODEURL}/salespeople`, {
          withCredentials: true
        })
        .then(response => {
          let id = 0,
            sp = response.data.data

          for (var k in sp) {
            list.push({
              id: id++,
              text: `${sp[k].First} ${sp[k].Last}`,
              value: sp[k].NavInitials
            })
          }
          this.slSalesAgents = list // update the list of agent names
        })
        .catch(err => {
          console.log(err)
          this.slSalesAgents = [] // clear out the list if there's an error
        })
        .finally(() => {
          this.loadSalesList = false
        })
    },
    saveprojections: function () {

      this.loadSalesDetails = true
      this.pendingchanges = false

      let post = []
      let s = this.pickedsalesrep
      let v = this.vmCustomerList.value
      let cs = this.$store.getters.currentSalesYear
      let item = null

      // build up an array of inventory items to send to the server to update the array of projections
      for (let i = 0; i < this.partsitems.length; i += 2) {
        item = this.partsitems[i]
        // if the items in the list are all zero then ignore it
        if ([item.cq1, item.cq2, item.cq3, item.cq4].reduce((a, b) => a + b, 0) > 0) {
          post.push({
            salesmgr: s,
            custno: v,
            item: item.ItemCore,
            q1: item.cq1,
            q2: item.cq2,
            q3: item.cq3,
            q4: item.cq4,
            year: cs,
            price: this.partPrices.find(o => o.item === item.ItemCore).value
          })
        }
      }

      axios.defaults.withCredentials = true

      // post data
      axios.post(`${this.$store.getters.NODEURL}/salespeople/projection`, post, {
          withCredentials: true
        })
        .then(response => {
          this.snackbar({
            color: 'success',
            message: 'Projection data saved.'
          })
        })
        .catch(e => {
          this.snackbar({
            color: 'error',
            message: 'Projection data failed to save.'
          })
        })
        .finally(() => {
          this.loadSalesDetails = false
          this.pendingchanges = false
        })
    },
    qc: function (quarter) {
      let days = [10, 100, 191, 283] // 10 days after q1, q2, q3 and q4
      return this.$store.getters.dayOfYear > days[quarter - 1]
    },
    validateKey(e) {
      if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) return
      if (!new RegExp('^[0-9]$').test(e.key))
        e.preventDefault()
    }
  },
  validations: {
    newItemPriceKG: {
      required,
      minLength: minLength(1),
      numeric
    }
  }
}
</script>

<style scoped>
 body { overflow-y: scroll; }
</style>