const SalesReportHeaders = [
    {
        text: "Description",
        align: "left",
        value: "description"
    },
    {
        text: "Filename",
        align: "left",
        value: "filename",
        hide: 'mdAndDown'
    },
    {
        text: "Uploaded",
        align: "left",
        value: "uploaded",
        hide: 'mdAndDown'
    },
    {
        text: "Agent",
        align: "left",
        value: "agent",
        hide: 'mdAndDown'
    },
    {
        text: "Action",
        value: "actions",
        sortable: false
    }
]

export default SalesReportHeaders