var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.salesTableItems,"search":_vm.tableSearch,"loading":_vm.tableLoading,"footer-props":{
                itemsPerPageText: '',
                itemsPerPageOptions: [],
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"blue darken-4 elevation-0","dark":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-title',[_vm._v("Sales Documents")]):_c('v-toolbar-title',[_vm._v("Docs")]),_c('v-divider',{staticClass:"mx-2 grey",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"outlined":"","text":"","color":"white"},on:{"click":function($event){return _vm.loadDocuments()}}},[_vm._v(_vm._s(_vm.$store.getters.buttonDetails ? 'Refresh' : '')),_c('v-icon',{attrs:{"right":_vm.$store.getters.buttonDetails,"color":"white"}},[_vm._v("mdi-refresh")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('v-text-field',{staticClass:"mt-5",attrs:{"label":"Search"},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1)]},proxy:true},{key:"item.agent",fn:function(props){return [_c('span',{class:props.item.agent == null ? 'secondary--text':''},[_vm._v(_vm._s(props.item.agent == null ? 'Global': 'Agent/Self'))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"x-small":"","icon":"","href":_vm.linkurl(item),"target":"_new"}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.linkurl(item)),expression:"linkurl(item)",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copied),expression:"copied",arg:"success"}],attrs:{"x-small":"","icon":"","target":"_new"}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }