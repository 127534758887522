const ar_ItemsbyQuarter = [
    {
        text: "Item",
        align: "left",
        sortable: false,
        value: "name",
        class: "black--text font-weight-bold"
    },
    {
        text: "Description",
        align: "left",
        sortable: false,
        value: "desc",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q1",
        align: "right",
        sortable: false,
        value: "Q1Prior",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q2",
        align: "right",
        sortable: false,
        value: "Q2Prior",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q3",
        align: "right",
        sortable: false,
        value: "Q3Prior",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q4",
        align: "right",
        sortable: false,
        value: "Q4Prior",
        class: "black--text font-weight-bold"
    },
    {
        text: "Total",
        align: "right",
        sortable: true,
        value: "PriorTotal",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q1",
        align: "right",
        sortable: false,
        value: "Q1Current",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q2",
        align: "right",
        sortable: false,
        value: "Q2Current",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q3",
        align: "right",
        sortable: false,
        value: "Q3Current",
        class: "black--text font-weight-bold"
    },
    {
        text: "Q4",
        align: "right",
        sortable: false,
        value: "Q4Current",
        class: "black--text font-weight-bold"
    },
    {
        text: "Total",
        align: "right",
        sortable: true,
        value: "CurrentTotal",
        class: "black--text font-weight-bold"
    }
]

export default ar_ItemsbyQuarter
