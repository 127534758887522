import Vue from 'vue'
import axios from 'axios'
import VueCookie from 'vue-cookie'

Vue.use(VueCookie)

/** logout
 * 
 *
 * @date 2020-08-28
 * @param {*} state
 * @returns
 */
export const logout = (state) => {
  return new Promise(function (resolve, reject) {
    axios.defaults.withCredentials = true
    axios.post(`${state.getters.NODEURL}/logout`)
      .then(response => {
        state.commit('wipe', false)
        localStorage.clear()
        resolve(response ? true : false)
      })
      .catch(e => {
        reject(e)
      })
  })
}

/** verify
 * 
 *
 * @date 2020-08-28
 * @param {*} state
 * @returns
 */
export const verify = (state) => {
  return new Promise(function (resolve, reject) {
    if (VueCookie.get('auth')) {
      axios.defaults.withCredentials = true
      axios.post(`${state.getters.NODEURL}/verify`)
        .then(response => {
          if (response.status === 200)
            resolve(response ? true : false)
        })
        .catch(e => {
          state.commit('auth', false)
          reject(e)
        })
    } else {
      console.log('authentication cookie/JWT token is missing')
      localStorage.clear()
      state.commit('auth', false)
      reject(false)
    }
  })
}