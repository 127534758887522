const ar_ZoneRegionCodes = 
[
    {
    value: 'CN',
    text: 'China',
    id: 0
  },
  {
    value: 'CR',
    text: 'Costa Rica',
    id: 1
  },
  {
    value: 'DO',
    text: 'D. Republic',
    id: 2
  },
  {
    value: 'GT',
    text: 'Guatemala',
    id: 3
  },
  {
    value: 'HN',
    text: 'Honduras',
    id: 4
  },
  {
    value: 'HT',
    text: 'Haiti',
    id: 5
  },
  {
    value: 'JM',
    text: 'Jamaica',
    id: 6
  },
  {
    value: 'JP',
    text: 'Japan',
    id: 7
  },
  {
    value: 'PR',
    text: 'Pueto Rico',
    id: 8
  },
  {
    value: 'SV',
    text: 'El Salvador',
    id: 9
  },
  {
    value: 'TT',
    text: 'Trinidad',
    id: 10
  },
  {
    value: 'US',
    text: 'US',
    id: 11
  },
  {
    value: 'OO',
    text: 'Others',
    id: 12
  }
]

export default ar_ZoneRegionCodes