import Vue from 'vue'
import VuexPersist from 'vuex-persist'
import Vuex, {
    mapGetters
} from 'vuex'

// import navigation from './modules/navigation'

import * as actions from './actions'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
    key: 'portal',
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {
        pendingChanges: false,
        buttonDetails: false,
        serverresponse: '',
        authenticated: false,
        permissions: {
            member: false,
            manager: false,
            administrator: false,
            root: false,
            showAgentList: false,
            showCurrency: false,
            menuAccess: {
                dashboard: false,
                purchases: false,
                sales: false,
                projections: false,
                projectionReports: false,
                salesLibrary: false,
                reports: false,
                newBusinessAlerts: false,
            },
            nbaDepartmentAccess: {
                editNeedByDate: false,
                editResearch: false,
                editQuality: false,
                editPurchasing: false,
                editCustomerService: false,
                editOperations: false,
                canArchive: false,
            },
            formualationsAccess: {
                viewFormulations: false,
                editFormulations: false,
            },
            allowCSVExport: false,
            allowPDFExport: false,
            restrictFMI: false,
            restrictSMG: false,
            restrictLTCA: false,
        },
        user: {
            first: '',
            last: '',
            pid: '',
            nav: ''
        },
        email: '',
        menuReduced: false,
        currentSalesYear: new Date().getFullYear(),
        currentQuarter: Math.ceil((new Date().getMonth() + 1) / 3),
        currentDay: 0,
        cutoffs: [10, 100, 191, 283],
        NODEURLDEV: 'http://localhost:4000',
        NODEURLPUB: 'https://api.flavormaterials.com',
        devMode: false,
        /* */
        salesSelectionFlags: {
            companyByQuarter: {
                locations: [],
                regions: [],
                agents: []
            },
            companyByAnnual: {
                locations: [],
                regions: [],
                agents: []
            }
        },
        vsQtrSalesbyItem: [],
        vsQtrSalesbyItemFlag: true,
        vsAnnualSalesbyItem: [],
        vsAnnualSalesbyItemFlag: true,
        vsQtrSalesbyCustomer: [],
        vsQtrSalesbyCustomerFlag: true,
        __major: 'beta',
        __minor: '30',
        __build: 'C-2',
        nbaEditingUsers: [],
        display_brix: false,
        preferences: {
            display_table_dense: false
        }
    },
    plugins: [
        vuexLocalStorage.plugin
    ],
    actions,
    mutations: {
        preference_dense(state, payload) { 
            state.preferences.display_table_dense = payload
        },
        flap_brix(state) {
            state.display_brix = !state.display_brix
        },
        salesSelectionFlagsQbC(state, payload) {
            state.salesSelectionFlags.companyByQuarter = {
                ...payload
            }
        },
        salesSelectionFlagsQbA(state, payload) {
            state.salesSelectionFlags.companyByAnnual = {
                ...payload
            }
        },
        wipe(state, value) {
            state.authenticated = value
            state.email = ''
            state.user = {
                first: '',
                last: '',
                nav: '',
                pid: 0,
                allow: {}
            }
            state.nbaEditingUsers = []
        },
        nbaEditingUsers(state, value) {
            state.nbaEditingUsers = value
        },
        buttonDetails(state, value) {
            state.buttonDetails = value
        },
        pendingChanges(state, value) {
            state.pendingChanges = value
        },
        vsQtrSalesbyItem(state, value) {
            state.vsQtrSalesbyItem = value
        },
        vsQtrSalesbyItemFlag(state, value) {
            state.vsQtrSalesbyItemFlag = value
        },
        vsAnnualSalesbyItem(state, value) {
            state.vsAnnualSalesbyItem = value
        },
        vsAnnualSalesbyItemFlag(state, value) {
            state.vsAnnualSalesbyItemFlag = value
        },
        vsQtrSalesbyCustomer(state, value) {
            state.vsQtrSalesbyCustomer = value
        },
        vsQtrSalesbyCustomerFlag(state, value) {
            state.vsQtrSalesbyCustomerFlag = value
        },
        currentDay(state, value) {
            state.currentDay = value
        },
        auth(state, value) {
            state.authenticated = value
        },
        email(state, value) {
            state.email = value
        },
        menuReduced(state, value) {
            state.menuReduced = value
        },
        details(state, value) {
            try {
                state.user = {
                    ...value
                }
            } catch (err) {
                throw `Fail: ${err}`
            }
        },
        /** deserializePermissions
         * convert a decimal value (like) 268435448 into a binary representation of users permissions.
         *
         * @date 2021-02-16
         * @param {*} state
         * @param {*} data
         */
        deserializePermissions(state, data) {
            try {
                let obj = JSON.flatten(state.permissions)
                let objString = parseInt(data).toString(2) // convert to string of 1's and 0's
                let counter = 0
                for (var i in obj) {
                    obj[i] = (objString[counter++] == '1' ? true : false) // convert 1's and 0's to true/false in the permissions object
                }
                state.permissions = JSON.unflatten(obj)
            } catch (err) {
                throw `Fail > Deserializing permissions: ${err}`
            }
        }
    },
    getters: {
        preference_dense: (state) => { 
            return state.preferences.display_table_dense
        },
        display_brix: (state) => {
            return state.display_brix
        },
        defaultPasswordFlag: (state) => {
            return state.user.dep
        },
        salesSelectionFlags: (state) => {
            return state.salesSelectionFlags
        },
        memberToString: (state) => {
            // work in reverse, most accessible to least
            if (state.permissions.root) return 'Root Access'
            else if (state.permissions.administrator) return 'Administrative Access'
            else if (state.permissions.manager) return 'Manager Access'
            else return 'Member Access'
        },
        userPID: (state) => {
            return state.user.pid
        },
        isMember: (state) => {
            return state.permissions.member
        },
        isManager: (state) => {
            return state.permissions.manager
        },
        isAdministrator: (state) => {
            return state.permissions.administrator
        },
        isRoot: (state) => {
            return state.permissions.root
        },
        showAgentList: (state) => {
            return state.permissions.showAgentList
        },
        showCurrency: (state) => {
            return state.permissions.showCurrency
        },
        allowDashboard: (state) => {
            return state.permissions.menuAccess.dashboard
        },
        allowPurchases: (state) => {
            return state.permissions.menuAccess.purchases
        },
        allowSales: (state) => {
            return state.permissions.menuAccess.sales
        },
        allowProjections: (state) => {
            return state.permissions.menuAccess.projections
        },
        allowProjectionReports: (state) => {
            return state.permissions.menuAccess.projectionReports
        },
        allowLibrary: (state) => {
            return state.permissions.menuAccess.salesLibrary
        },
        allowReports: (state) => {
            return state.permissions.menuAccess.reports
        },
        allowBusinessAlerts: (state) => {
            return state.permissions.menuAccess.newBusinessAlerts
        },
        nbaEditNeedByDate: (state) => {
            return state.permissions.nbaDepartmentAccess.editNeedByDate
        },
        nbaResearch: (state) => {
            return state.permissions.nbaDepartmentAccess.editResearch
        },
        nbaQuality: (state) => {
            return state.permissions.nbaDepartmentAccess.editQuality
        },
        nbaPurchasing: (state) => {
            return state.permissions.nbaDepartmentAccess.editPurchasing
        },
        nbaCustomerService: (state) => {
            return state.permissions.nbaDepartmentAccess.editCustomerService
        },
        nbaOperations: (state) => {
            return state.permissions.nbaDepartmentAccess.editOperations
        },
        nbaCanArchive: (state) => {
            return state.permissions.nbaDepartmentAccess.canArchive
        },
        viewFormulations: (state) => {
            return state.permissions.formualationsAccess.viewFormulations
        },
        editFormulations: (state) => {
            return state.permissions.formualationsAccess.editFormulations
        },
        allowCSVExport: (state) => {
            return state.permissions.allowCSVExport
        },
        allowPDFExport: (state) => {
            return state.permissions.allowPDFExport
        },
        restrictFMI: (state) => {
            return state.permissions.restrictFMI
        },
        restrictSMG: (state) => {
            return state.permissions.restrictSMG
        },
        restrictLTCA: (state) => {
            return state.permissions.restrictLTCA
        },
        __dumpPermissions: (state) => {
            console.error('DO NOT INCLUDE IN RELEASE')
            return state.permissions
        },
        serializePermissions: (state) => {
            // convert the permissions object into a base 64 encoded string
            try {
                let obj = JSON.flatten(state.permissions)
                let objBinary = ''
                for (var i in obj) {
                    objBinary += obj[i] == true ? '1' : '0'
                }
                return parseInt(objBinary, 2)
            } catch (err) {
                throw `Failure in serializing permissions object to string.  Error given : ${err}`
            }
        },
        nbaEditingUsers: (state) => {
            return state.nbaEditingUsers
        },
        buttonDetails: (state) => {
            return state.buttonDetails
        },
        pendingChanges: (state) => {
            return state.pendingChanges
        },
        /* eslint-disable no-unused-vars */
        allowed: (state) => {
            console.warn('depreciated - convert to serialized permissions')
        },
        nbaPermissions: (state) => {
            return state.permissions.nbaDepartmentAccess
        },
        vsQtrSalesbyItem: (state) => {
            return state.vsQtrSalesbyItem
        },
        vsQtrSalesbyItemFlag: (state) => {
            return state.vsQtrSalesbyItemFlag
        },
        vsAnnualSalesbyItem: (state) => {
            return state.vsAnnualSalesbyItem
        },
        vsAnnualSalesbyItemFlag: (state) => {
            return state.vsAnnualSalesbyItemFlag
        },
        vsQtrSalesbyCustomer: (state) => {
            return state.vsQtrSalesbyCustomer
        },
        vsQtrSalesbyCustomerFlag: (state) => {
            return state.vsQtrSalesbyCustomerFlag
        },
        auth: (state) => {
            return state.authenticated
        },
        email: (state) => {
            return state.email
        },
        initial_name: (state) => {
            return `${state.user.first[0].toLowerCase()}${state.user.last.toLowerCase()}`
        },
        name: (state) => {
            return state.user.first === '' ? 'Not Logged In' : `${state.user.first} ${state.user.last}`
        },
        salescode: (state) => {
            return state.user.nav
        },
        currentSalesYear: (state) => {
            return state.currentSalesYear
        },
        currentQuarter: (state) => {
            return state.currentQuarter
        },
        dayOfYear: (state) => {
            var now = new Date()
            var start = new Date(now.getFullYear(), 0, 0)
            var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000)
            var oneDay = 1000 * 60 * 60 * 24
            state.currentDay = Math.floor(diff / oneDay)
            return Math.floor(diff / oneDay)
        },
        isDeveloperMode: (state) => {
            return state.devMode
        },
        NODEURL: (state) => {
            return state.devMode ? state.NODEURLDEV : state.NODEURLPUB
        },
        Q1AllowEdit: (state) => {
            return state.currentDay < state.cutoffs[0]
        },
        Q2AllowEdit: (state) => {
            return state.currentDay < state.cutoffs[1]
        },
        Q3AllowEdit: (state) => {
            return state.currentDay < state.cutoffs[2]
        },
        Q4AllowEdit: (state) => {
            return state.currentDay < state.cutoffs[3]
        },
        menuReduced: (state) => {
            return state.menuReduced
        },
        __version: (state) => {
            return `${state.__major}.${state.__minor} build ${state.__build}`
        }
    },
    computed: {
        ...mapGetters([
            'initial_name',
            'preference_dense',
            'display_brix',
            'userPID',
            'isDeveloperMode',
            'defaultPasswordFlag',
            'nbaPermissions',
            'salesSelectionFlags',
            'isMember',
            'isManager',
            'isAdministrator',
            'isRoot',
            'showAgentList',
            'showCurrency',
            'allowDashboard',
            'allowPurchases',
            'allowSales',
            'allowProjections',
            'allowProjectionReports',
            'allowLibrary',
            'allowReports',
            'allowBusinessAlerts',
            'nbaEditNeedByDate',
            'nbaResearch',
            'nbaQuality',
            'nbaPurchasing',
            'nbaCustomerService',
            'nbaOperations',
            'nbaCanArchive',
            'viewFormulations',
            'editFormulations',
            'allowCSVExport',
            'allowPDFExport',
            'restrictFMI',
            'restrictSMG',
            'restrictLTCA',
            'nbaEditingUsers',
            'buttonDetails',
            'pendingChanges',
            'allowNBA',
            'auth',
            'name',
            'currentSalesYear',
            'currentQuarter',
            'dayOfYear',
            'Q1AllowEdit',
            'Q2AllowEdit',
            'Q3AllowEdit',
            'Q4AllowEdit',
            'NODEURL',
            'isAdminAccess',
            'salescode',
            'vsQtrSalesbyItem',
            'vsQtrSalesbyItemFlag',
            'vsAnnualSalesbyItem',
            'vsAnnualSalesbyItemFlag',
            'vsQtrSalesbyCustomer',
            'vsQtrSalesbyCustomerFlag',
            'menuReduced',
            'serializePermissions'
        ])
    },
    // modules: { navigation }
})

JSON.flatten = function (data) {
    var result = {}
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur
        } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++)
                recurse(cur[i], prop ? prop + "." + i : "" + i)
            if (l == 0)
                result[prop] = []
        } else {
            var isEmpty = true
            for (var p in cur) {
                isEmpty = false
                recurse(cur[p], prop ? prop + "." + p : p)
            }
            if (isEmpty)
                result[prop] = {}
        }
    }
    recurse(data, "")
    return result
}

JSON.unflatten = function (data) {
    "use strict";
    if (Object(data) !== data || Array.isArray(data))
        return data
    var result = {},
        cur, prop, idx, last, temp
    for (var p in data) {
        cur = result, prop = "", last = 0
        do {
            idx = p.indexOf(".", last)
            temp = p.substring(last, idx !== -1 ? idx : undefined)
            cur = cur[prop] || (cur[prop] = (!isNaN(parseInt(temp)) ? [] : {}))
            prop = temp
            last = idx + 1
        } while (idx >= 0)
        cur[prop] = data[p]
    }
    return result[""]
}