<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn elevation="2" text outlined class="blue darken-2 ml-1" @click="loadDocuments()">
        <v-icon color="white">mdi-refresh</v-icon>
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-text-field hide-details class="mt-5" v-model="reports.search" label="Search"></v-text-field>
    </v-toolbar>
    <v-divider inset></v-divider>
    <v-data-table :headers="reports.headers"
                  :items="reports.items"
                  :search="reports.search"
                  :loading="reports.loading"
                  :items-per-page="20" item-key="fid"
                      :footer-props="{
                        'items-per-page-options': [20,20],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-text':'Reports per page'}">
      <template v-slot:item.agent="props">
        <span :class="props.item.agent == null ? 'secondary--text':''">{{props.item.agent == null ? 'Global': 'Agent/Self'}}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small text outlined class="blue darken-2 ml-1" @click="getIdentifier(item)" target="_new">
          <v-icon color="white" small>mdi-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import {
  helperMixin
} from '../mixins/helper'
import Headers from './data/reports'
import axios from 'axios'
import { EventBus } from '../components/EventBus.js'
export default {
  name: 'SalesLReports',
  mixins: [helperMixin],
  data: () => ({
    reports: {
      headers: Headers.SalesReportHeaders,
      items: [],
      loading: false,
      search: ''
    },
  }),
  mounted() {
    this.loadDocuments()
  },
  computed: {
    computedHeaders() {
      return this.salesTableHeaders.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    }
  },
  methods: {
    loadDocuments() {
      this.tableLoading = true
      axios.get(`${this.$store.getters.NODEURL}/reports`, {
          withCredentials: true
        })
        .then((result) => {
          let v = result.data.data
          console.log(v.length)
          if(v.length >= 1) {
            this.reports.items = v
          }
        })
        .catch((err) => {
          console.warn(err)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    copied() {},
    getIdentifier(val) {
      let guid = val.identifier.replace(/-/g, '')
      axios.get(`${this.$store.getters.NODEURL}/getfile/${guid}`, {
          responseType: 'arraybuffer'
        }, {
          withCredentials: true
        })
        .then((response) => {
          try {
            if(JSON.parse(Buffer.from(response.data).toString('utf8')).ok === false) {
              EventBus.$emit('error', { message: 'Unable to load file from server - Error 94' })
            }
          } catch {
            let filesaver = require('file-saver')
            filesaver.saveAs(new Blob([response.data]), val.filename)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
