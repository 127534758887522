 <template>
   <v-container fluid>

     <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
       {{ sb_msg }}
       <template v-slot:action="{ attrs }">
         <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
       </template>
     </v-snackbar>

     <v-row no-gutters>
       <v-col cols="12">
         
         <v-toolbar flat>
           <v-toolbar-title>Annual by Customer</v-toolbar-title>
           <v-divider class="mx-2" inset vertical></v-divider>
           <v-btn text outlined class="blue darken-2" elevation="0" @click="load(false)"> <v-icon color="white">mdi-refresh</v-icon> </v-btn>
           <v-btn text outlined class="blue darken-2 ml-2" elevation="0" @click="exportToCSV"> <v-icon color="white">mdi-microsoft-excel</v-icon> </v-btn>
           <v-btn @click="exportToPDF" text outlined class="blue darken-2 ml-2" elevation="0"
             :loading="annualTableLoading" :disabled="!$store.getters.allowPDFExport" light>
             <v-icon color="white">mdi-adobe-acrobat</v-icon>
           </v-btn>
           <v-spacer></v-spacer>
           <v-text-field v-model="annualTableSearch" label="Search" hide-details></v-text-field>
           <v-btn color="white" small depressed @click="drawer = !drawer" class="ml-4"> <v-icon color="indigo">mdi-menu</v-icon> </v-btn>
         </v-toolbar>
         <!-- -->
         <v-data-table id="annualSalesbyCustomer" ref="historytable2" :headers="annualSalesHeaders"
           :items="annualSalesItems" class="elevation-0 caption" :loading="annualTableLoading" item-key="id"
           :single-expand="true" show-expand fixed-header dense hide-default-footer :expanded.sync="expanded"
           @item-expanded="rowExpand" :search="annualTableSearch" :footer-props="{ 'items-per-page-options': [-1] }">
           <template v-slot:expanded-item="{ headers, item }">
             <td :colspan="headers.length"
               style="border-top:solid 4px #444;border-bottom:solid 4px #444;background-color:#444;" class="pa-3 ma-0">
               <v-data-table id="annualSalesbyCustomerSub" :headers="annualSubHeaders" :items="annualSubItems"
                 class="elevation-4 caption font-weight-bold" item-key="pid" dense hide-default-footer
                 :footer-props="{ 'items-per-page-options': [-1] }">
                 <template v-slot:top>
                   <v-toolbar color="indigo lighten-2 elevation-0" dark dense>
                     <v-toolbar-title>{{item.name}} ({{item.custno}})</v-toolbar-title>
                   </v-toolbar>
                 </template>
                 <template v-slot:[`item.usd1`]="{ item }">{{ cvt(item.usd1) }}</template>
                 <template v-slot:[`item.usd2`]="{ item }">{{ cvt(item.usd2) }}</template>
                 <template v-slot:[`item.usd3`]="{ item }">{{ cvt(item.usd3) }}</template>
                 <template v-slot:[`item.usd4`]="{ item }">{{ cvt(item.usd4) }}</template>
                 <template v-slot:[`item.kg1`]="{ item }">{{ cvk(item.kg1) }}</template>
                 <template v-slot:[`item.kg2`]="{ item }">{{ cvk(item.kg2) }}</template>
                 <template v-slot:[`item.kg3`]="{ item }">{{ cvk(item.kg3) }}</template>
                 <template v-slot:[`item.kg4`]="{ item }">{{ cvk(item.kg4) }}</template>
               </v-data-table>
             </td>
           </template>
           <template v-slot:[`item.name`]="{ item }">
             <span>{{item.name}} <v-chip color="blue lighten-4" x-small>{{item.agent}}</v-chip></span>
           </template>
           <template v-slot:[`item.usd1`]="{ item }"><span
               :class="clr(item.usd1)">{{ cvt(item.usd1) }}</span></template>
           <template v-slot:[`item.usd2`]="{ item }"><span
               :class="clr(item.usd2)">{{ cvt(item.usd2) }}</span></template>
           <template v-slot:[`item.usd3`]="{ item }"><span
               :class="clr(item.usd3)">{{ cvt(item.usd3) }}</span></template>
           <template v-slot:[`item.usd4`]="{ item }"><span
               :class="clr(item.usd4)">{{ cvt(item.usd4) }}</span></template>
           <template v-slot:[`item.kg1`]="{ item }"><span :class="clr(item.kg1)">{{ cvk(item.kg1) }}</span></template>
           <template v-slot:[`item.kg2`]="{ item }"><span :class="clr(item.kg2)">{{ cvk(item.kg2) }}</span></template>
           <template v-slot:[`item.kg3`]="{ item }"><span :class="clr(item.kg3)">{{ cvk(item.kg3) }}</span></template>
           <template v-slot:[`item.kg4`]="{ item }"><span :class="clr(item.kg4)">{{ cvk(item.kg4) }}</span></template>
           <template v-slot:[`body.append`]>
             <tr style="background-color:#e3f2fd;color:black;height:40px!important;font-weight:bold">
               <td colspan="2"></td>
               <td class="text-right">{{cvt(totals[1])}}</td>
               <td class="text-right">{{cvt(totals[2])}}</td>
               <td class="text-right">{{cvt(totals[3])}}</td>
               <td class="text-right">{{cvt(totals[8])}}</td>
               <td class="text-right">{{cvk(totals[4])}}</td>
               <td class="text-right">{{cvk(totals[5])}}</td>
               <td class="text-right">{{cvk(totals[6])}}</td>
               <td class="text-right">{{cvk(totals[7])}}</td>
             </tr>
           </template>
         </v-data-table>
       </v-col>
     </v-row>
     <v-navigation-drawer v-model="drawer" absolute temporary right width="400" @transitionend="watchDrawer($event)">
       <v-btn color="blue-grey darken-2" elevation="0" v-model="allLocations" block @click="flipAllLocations" dark
         class="rounded-0">
         <v-icon left color="blue-grey">mdi-label</v-icon> {{allLocations?'No':'All'}} Locations
       </v-btn>
       <v-row>
         <v-col md="10" offset-md="1">
           <v-chip-group active-class="primary--text" column multiple v-model="selectLocationArray">
             <v-chip v-for="t in locationArray" :key="t.id" :small="!vs_button_larger" label
               :disabled="canHazPermission(t.idx)" active-class="success">
               <v-avatar left>
                 <v-icon small v-if="selectLocationArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                 </v-icon>
               </v-avatar>
               {{ t.text }}
             </v-chip>
           </v-chip-group>
         </v-col>
       </v-row>
       <v-btn color="blue-grey darken-2" elevation="0" v-model="allRegionsFlag" block @click="flipAllRegions" dark
         class="rounded-0">
         <v-icon left color="blue-grey">mdi-label</v-icon> {{allRegionsFlag?'No':'All'}} Regions
       </v-btn>
       <v-row>
         <v-col md="10" offset-md="1">
           <v-chip-group active-class="primary--text" column multiple v-model="selectedRegionCodeArray">
             <v-chip v-for="t in regionCodeArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
               <v-avatar left>
                 <v-icon small v-if="selectedRegionCodeArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                 </v-icon>
               </v-avatar>
               {{ t.text }}
             </v-chip>
           </v-chip-group>
         </v-col>
       </v-row>
       <div v-if="$store.getters.isManager" class="mt-2">
         <v-btn color="blue-grey darken-2" elevation="0" v-model="allAgentsFlag" block @click="flipAllAgents" dark
           class="rounded-0">
           <v-icon left color="blue-grey">mdi-label</v-icon> {{allAgentsFlag?'No':'All'}} Agents
         </v-btn>
         <v-row>
           <v-col md="10" offset-md="1">
             <v-chip-group active-class="primary--text" column multiple v-model="selectedSalesAgentArray">
               <v-chip v-for="t in salesAgentArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
                 <v-avatar left>
                   <v-icon small v-if="selectedSalesAgentArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                   </v-icon>
                 </v-avatar>
                 {{ t.text }}
               </v-chip>
             </v-chip-group>
           </v-col>
         </v-row>
       </div>
       <div>
         <v-row>
           <v-col md="10" offset-md="1">
             <v-btn @click="load(false)" outlined color="green" block small>Search <v-icon right color="green" small>
                 mdi-refresh</v-icon>
             </v-btn>
           </v-col>
         </v-row>
       </div>
     </v-navigation-drawer>
   </v-container>
</template>

<script>
  
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import async from 'async'
import axios from 'axios'
import Headers from './data/items'

const bct = (a) => {
  return parseInt(a.replace(/[^0-9.]+/g, ""))
}
const rowNewLine = () => {
  return '\r\n'
}
const DATABASE_FIRST_YEAR = 2013

export default {
  name: "companyAnnualHistory",
  data: () => ({
    vs_button_larger: false,
    drawer: false,
    list: null,
    expanded: [],
    jsondata: [],
    itemjsondata: [],
    annualSalesItems: [],
    annualSubItems: [],
    annualSubHeaders: Headers.AnnualSalesbyCompanySubHeaders,
    annualSalesHeaders: Headers.AnnualSalesbyCompany,
    annualTableSearch: '',
    annualTableLoading: false,
    annualDenseFlag: true,
    displayQTR: false,
    asyncbaseremote: 'http://192.168.1.112:4000',
    asyncbaselocal: 'http://localhost:4000',
    totals: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // snack bar pop ups
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    // vswitch selections
    selectedSalesAgentArray: [],
    salesAgentArray: [],
    selectedRegionCodeArray: [],
    regionCodeArray: Headers.ZoneRegionCodes,
    allRegionsFlag: false,
    allAgentsFlag: false,
    allLocations: false,
    locationArray: [
      { text: 'FMI', id: 0, idx: 25 },
      { text: 'SMG', id: 1, idx: 26 },
      { text: 'GT', id: 2, idx: 27 },
      { text: 'CR', id: 3, idx: 28 },
    ],
    selectLocationArray: [0]
  }),
  beforeCreate: function () { },
  beforeMount: function () {
    // load the rep names from the database
    this.loadAllRepNames()
  },
  mounted: function () {
     // restore the state of the v-switches based on the VUEX store
    let vstate = this.$store.getters.vsQtrSalesbyCustomer
    // set the current column headers using the two year select
    this.columnQtrTitle()
    this.loadDrawerSelections()
  },
  methods: {
    loadDrawerSelections() {
      let __flags = this.$store.getters.salesSelectionFlags
      // load the prior state of the selection array
      this.selectLocationArray = __flags.companyByAnnual.locations.slice()
      this.selectedRegionCodeArray = __flags.companyByAnnual.regions.slice()
      this.selectedSalesAgentArray = __flags.companyByAnnual.agents.slice()
      // show the nav bar
      this.drawer = true
    },
    watchDrawer(e) {
      if (e.propertyName === 'transform' && !this.drawer) {
        this.$store.commit('salesSelectionFlagsQbA', {
          locations: this.selectLocationArray,
          regions: this.selectedRegionCodeArray,
          agents: this.selectedSalesAgentArray
        })
      }
    },
    canHazPermission(index) {
      return this.$store.getters.serializePermissions.toString(2)[index] == '1'
    },
    flipAllLocations() {
      this.allLocations = !this.allLocations
      if (this.allLocations) {
        for (var v in this.locationArray) {
          this.selectLocationArray.push(this.locationArray[v].id)
        }
      } else {
        this.selectLocationArray = []
      }
    },
    flipAllRegions() {
      this.allRegionsFlag = !this.allRegionsFlag
      if (this.allRegionsFlag) {
        for (var v in this.regionCodeArray) {
          this.selectedRegionCodeArray.push(this.regionCodeArray[v].id)
        }
      } else {
        this.selectedRegionCodeArray = []
      }
    },
    flipAllAgents() {
      this.allAgentsFlag = !this.allAgentsFlag
      if (this.allAgentsFlag) {
        for (var v in this.salesAgentArray) {
          this.selectedSalesAgentArray.push(this.salesAgentArray[v].id)
        }
      } else {
        this.selectedSalesAgentArray = []
      }
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    loadAllRepNames() {
      let __list = []
      try {
        if (this.$store.getters.isAdmin || this.$store.getters.isManager) {
          axios
            .get(`${this.$store.getters.NODEURL}/salespeople`, {
              withCredentials: true
            })
            .then(response => {
              let id = 0,
                td = response.data.data
              for (var k in td) {
                __list.push({
                  id: id++,
                  text: `${td[k].First[0]}. ${td[k].Last}`,
                  value: td[k].NavInitials
                })
              }
              this.salesAgentArray = __list
              this.salesAgentArrayCol1 = this.salesAgentArray.length / 2
            })
            .catch(err => {
              this.salesAgentArray = []
            })
            .finally(() => {})
        } else {
          this.salesAgentArray.push({
            id: 0,
            text: 'You',
            value: this.$store.getters.salescode
          })
          this.selectedSalesAgentArray[0] = true
        }
      } catch (err) {
        console.error(err)
      }
    },
    selectSalesAgent(item) {
      if (item != undefined) {
        this.vSalesAgents = item
      }
    },
    bLoadAllCompanies() {
      this.load(true)
      this.drawer = !this.drawer
    },
    customSort(items, sortBy, sortDesc) {
      if (sortBy[0] != undefined) {
        this.itemsCustomerParts.sort((a, b) => {
          if (sortBy[0] === 'PriorTotal' || sortBy[0] === 'CurrentTotal') {
            return sortDesc[0] ? bct(b[sortBy[0]]) < bct(a[sortBy[0]]) : bct(b[sortBy[0]]) > bct(a[sortBy[0]])
          }
        })
        return this.injectKGintoPartsArray()
      } else {
        return items
      }
    },
    rowExpand(item) {
      if (!item.value) return
      axios
        .get(`${this.$store.getters.NODEURL}/customer/history/detailed/${item.item.custno}`)
        .then(response => {
          this.annualSubItems = response.data.data
        })
        /* eslint-disable no-unused-vars */
        .catch(e => {
          console.log(`rowExpand::${e}`)
        })
        .finally(() => {})
    },
    buildItemHeaders() {
      let row = ''
      for (var v in this.annualSubHeaders) {
        if (v != undefined)
          row += `"${this.annualSubHeaders[v].text}",`
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildItemDataRows() {
      for (var i = 0, row = '', columnID = 0; i < this.annualSubItems.length; i++) {
        for (var t in this.annualSubItems[i]) {
          if (++columnID > 1) {
            row += (columnID > 2 && columnID < 5) ? `"${this.cvt(this.annualSubItems[i][t])}",` : `"${this.cvk(this.annualSubItems[i][t])}",`
            // console.log(`${columnID} ${this.annualSubItems[i][t]}`)
          }
        }
        columnID = 0
        row += rowNewLine()
      }
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildCustomerHeaders(padcol) {
      let row = '',
        idx = 0
      for (var i in this.annualSalesHeaders) {
        if (i != undefined)
          row += `"${this.annualSalesHeaders[i].text}",`
        // skip a row to fit the description below
        if (++idx === 1 && padcol) { row += `"",` }
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildAllCustomerDataRows() {
      for (var i = 0, row = '', columnID = 0; i < this.annualSalesItems.length; i++) {
        for (var index in this.annualSalesItems[i]) {
          if (++columnID > 2 && columnID < 12) {
            if(columnID >=4 && columnID <= 7)
               row += columnID > 3 ? `"${this.cvt(this.annualSalesItems[i][index])}",` : `"${this.annualSalesItems[i][index]}",`
            else
               row += columnID > 3 ? `"${this.cvk(this.annualSalesItems[i][index])}",` : `"${this.annualSalesItems[i][index]}",`
          }
        }
        columnID = 0
        row += rowNewLine()
      }
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildSingleCustomerDataRow(custno, padcol) {
      let ctr = 0,
        row = '',
        idx = 0
      for (var index in this.annualSalesItems[custno]) {
        if (++ctr > 2 && ctr < 12) {
          row += ctr > 3 && ctr < 8 ? `"${this.cvt(this.annualSalesItems[custno][index])}",` : `"${this.annualSalesItems[custno][index]}",`
          if (idx++ === 0 && padcol) {
            row += `"",`
          }
        }
      }
      ctr = 0
      row += rowNewLine()
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    exportToCSV() {

      this.annualTableLoading = true // loading anim

      var CSVOUT = 'sep=,' + '\r\n'
      var row = ''

      // if a row is currently expanded, show the name of the company
      // with the item parts below it only
      if (this.expanded.length) {
        CSVOUT += this.buildCustomerHeaders(true)
        CSVOUT += this.buildSingleCustomerDataRow(this.annualSalesItems.findIndex(o => o.custno === this.expanded[0].custno), true)
        CSVOUT += this.buildItemHeaders()
        CSVOUT += this.buildItemDataRows()
      } else {
        // otherwise show all companies in the list
        CSVOUT += this.buildCustomerHeaders(false)
        CSVOUT += rowNewLine()
        CSVOUT += this.buildAllCustomerDataRows()
      }

      // build the link filename, hidden <A> and send it
      try {
        let fileName = `${this.$store.getters.name}_Annual_History_Export`.replace(/ /g, "_")
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSVOUT)
        var link = document.createElement("a")
        link.href = uri
        link.style = "visibility:hidden"
        link.download = fileName + ".csv"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        this.showsnack({
          message: 'Cannot create file for export, the error has been logged.',
          color: 'warning'
        })
      }

      this.annualTableLoading = false
    },
    color(a, b) {
      return this.coloryoy ? `${a > b ? '' : 'red'}--text` : ''
    },
    columnQtrTitle: function () {
      let priorYear = this.$store.getters.currentSalesYear - 3
      for (let c = 1; c <= 4; ++c) {
        let sub = priorYear.toString().substring(2,4)
        this.annualSalesHeaders[c].text = `'${sub} $`
        this.annualSalesHeaders[c + 4].text = `'${sub} KG`
        this.annualSubHeaders[c + 1].text = `'${sub} $`
        this.annualSubHeaders[c + 5].text = `'${sub} KG`
        ++priorYear
      }
    },
    sumYears: function (year) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].PeriodYear === year) {
          sum += this.jsondata[i].TotalDollars
        }
      }
      return sum
    },
    sumCountry: function (country) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].BillCountry === country) {
          sum++
        }
      }
      return sum
    },
    totalItemByQTR: function (qtr, item, year, sumByMoney) {
      let sum = 0
      for (var i = 0; i < this.itemjsondata.length; ++i) {
        if (this.itemjsondata[i].PeriodQTR === qtr &&
          this.itemjsondata[i].ItemCore === item &&
          this.itemjsondata[i].PeriodYear === year) {
          sum += sumByMoney ? this.itemjsondata[i].TotalDollars : this.itemjsondata[i].Qtyship
        }
      }
      return sumByMoney ? sum : Math.round(sum, 1)
    },
    quartersum: function (qtr, company, year) {
      let sum = 0
      for (var i = 0; i < this.jsondata.length; ++i) {
        if (this.jsondata[i].PeriodQTR === qtr &&
          this.jsondata[i].CustName === company &&
          this.jsondata[i].PeriodYear === year) {
          sum += this.jsondata[i].TotalDollars
        }
      }
      return sum
    },
    clr(v) {
      return `${v <= 0 ? 'grey--text':''}`
    },
    cvt(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    cvk(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: value < 10 ? 1 : 0,
        maximumFractionDigits: value < 10 ? 1 : 0
      })
    },
    percent: function (pj, v) {
      return Math.round((parseInt(v) / pj) * 100, 1)
    },
    load: function (loadall) {

      // close any expanded rows
      this.expanded = []
      this.drawer = false

      let selectRegions = [], selectAgents = [], selectLocations = []

      if (this.selectLocationArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one location.', delay: 4000 })
        return
      }
      if (this.selectedRegionCodeArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one region.', delay: 4000})
        return
      }

      // build up an array of selected country codes
      for (let p in this.selectLocationArray) {
        selectLocations.push(this.locationArray[this.selectLocationArray[p]].text)
      }
      for (let p in this.selectedRegionCodeArray) {
        selectRegions.push(this.regionCodeArray[this.selectedRegionCodeArray[p]].value)
      }
      if(this.$store.getters.isManager) {
        for (let p in this.selectedSalesAgentArray) {
          selectAgents.push(this.salesAgentArray[this.selectedSalesAgentArray[p]].value)
        }
      } else {
        selectAgents.push(this.$store.getters.salescode)
      }

      if (selectAgents.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one sales agent.', delay: 4000})
        return
      }

      // trigger the loading animation on the table
      this.annualTableLoading = true

      this.totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      async.waterfall(
        [
          async.apply(function (that, callback) {
            axios
              .post(`${that.$store.getters.NODEURL}/customers/history/annual`, {
                countries: selectRegions.join(','),
                agents: that.$store.getters.isManager ? selectAgents.join(',') : selectAgents[0],
                locations: selectLocations.join(',')
              }, {
                withCredentials: true
              })
              .then(response => {
                let original = response.data.data
                original.sort((a, b) => b.usd4 - a.usd4)
                original.forEach(r => {
                  that.totals[1] += r.usd1
                  that.totals[2] += r.usd2
                  that.totals[3] += r.usd3
                  that.totals[8] += r.usd4
                  that.totals[4] += r.kg1
                  that.totals[5] += r.kg2
                  that.totals[6] += r.kg3
                  that.totals[7] += r.kg4
                })
                that.annualSalesItems = original
              })
              /* eslint-disable no-unused-vars */
              .catch(e => {
                console.error(e)
              })
              .finally(() => {
                callback(null, that)
              })
          }, this)
        ],
        function (err, that) {
          that.annualTableLoading = false
        }
      )
    },
    exportToPDF: function () {

      if (this.annualSalesItems.length == 0) {
        this.snackbar({
          color: 'error',
          msg: 'Nothing to export.',
          delay: 4000
        })
        return
      }

      let names = [], pdfdata = []

      this.annualTableLoading = true // anim

      if(this.$store.getters.isManager) {
        for (let p in this.selectedSalesAgentArray) {
          names.push({
            name: this.salesAgentArray[this.selectedSalesAgentArray[p]].text,
            nav: this.salesAgentArray[this.selectedSalesAgentArray[p]].value
          })
        }
      } else {
        names.push({name: this.$store.getters.name, nav: this.$store.getters.salescode })
      }

      if (this.expanded.length) {
        pdfdata = { retype: 'set', header: this.expanded, data: this.annualSubItems }
        axios.post(`${this.$store.getters.NODEURL}/pdf/asbce`,
          { "data": pdfdata, "agents": names, "startyear": this.$store.getters.currentSalesYear - 3 },
          { withCredentials: true, responseType: 'arraybuffer' })
          .then((response) => {
            let filesaver = require('file-saver')
            filesaver.saveAs(new Blob([response.data]), `${this.$store.getters.salescode}_Annual_Export_Expanded.pdf`)
          })
          .catch((err) => { console.error(err) })
          .finally(() => { this.annualTableLoading = false })
      }
      else {
        pdfdata = { retype: 'set', data: this.annualSalesItems }
        axios.post(`${this.$store.getters.NODEURL}/pdf/asbc`,
          { "data": pdfdata, "agents": names, "startyear": this.$store.getters.currentSalesYear - 3 },
          { withCredentials: true, responseType: 'arraybuffer' })
          .then((response) => {
            let filesaver = require('file-saver')
            filesaver.saveAs(new Blob([response.data]), `${this.$store.getters.salescode}_Annual_Export.pdf`)
          })
          .catch((err) => { console.error(err) })
          .finally(() => { this.annualTableLoading = false })
      }
    }
  }
}
</script>

<style>
 #annualSalesbyCustomer .v-data-table__wrapper{height:calc(100vh - 200px) !important;}
 #annualSalesbyCustomer td:nth-child(n+7) { background-color:#e3f2fd; }
 
 </style>

<style scoped>
 #annualSalesbyCustomer >>> #annualSalesbyCustomerSub td:nth-child(n+7) { background-color:#e3f2fd; }
 #annualSalesbyCustomer >>> #annualSalesbyCustomerSub td:nth-child(7) { border-left: solid 2px black!important; }
 #annualSalesbyCustomer >>> #annualSalesbyCustomerSub td:nth-child(8) { border-left:none!important; }
 #annualSalesbyCustomer >>> #annualSalesbyCustomerSub .v-data-table__wrapper{height:auto!important;}
</style>
