import ZoneRegionCodes from './headers/RegionCodes'

import QuarterlySalesbyItem from './headers/hQuarterlySalesbyItem'
import QuarterlySalesSubHeaders from './headers/hQuarterlySubHeaders'

import AnnualSalesbyItem from './headers/hAnnualSalesbyItem'
import AnnualSalesbyItemSubHeaders from './headers/hAnnualSalesSubHeaders'

import AnnualSalesbyCompany from './headers/hAnnualSalesbyCompany'
import AnnualSalesbyCompanySubHeaders from './headers/hAnnualSalesbyCompanySubHeaders'

export default {
    ZoneRegionCodes,
    AnnualSalesbyItem,
    QuarterlySalesbyItem,
    QuarterlySalesSubHeaders,
    AnnualSalesbyItemSubHeaders,
    AnnualSalesbyCompany,
    AnnualSalesbyCompanySubHeaders
}
