import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import store from '@/store/store'
import VueCookie from 'vue-cookie'

import Int from '@/components/Int'
import Home from '@/components/Home'
import Base from '@/components/Base'
import Login from '@/components/Login'
import ItemsAnnual from '@/components/ItemsAnnual'
import ItemsQuarterly from '@/components/ItemsQuarterly'
import CompanyAnnual from '@/components/CompanyAnnual'
import CompanyQuarterly from '@/components/CompanyQuarterly'
import NewBusinessAlert from '@/components/NBA'
import NewBusinessAlertDetails from '@/components/NBADetails'
// import Admin from '@/components/Admin'
import Rollup from '@/components/Rollup'
import Projection from '@/components/Projection'
import Dashboard from '@/components/Dashboard'
import SalesReports from '@/components/SalesReports'
import SalesLibrary from '@/components/SalesLibrary'
import RawMaterialCalculator from '@/components/RawMaterialCalculator'
import CustomerPKMapping from '@/components/CustomerPKMapping'

import SampleRequestForm from '@/components/SampleRequest'
import OilPricing from '@/components/OilPricing'

Vue.use(Router)
Vue.use(Vuex)

const Admin = () => import( /* webpackChunkName: "Admin" */ '@/components/Admin')
const PurchaseOrders = () => import( /* webpackChunkName: "PurchaseOrders" */ '@/components/OpenPurchase')

/**
 * checkAuthentication
 *
 * @date 2020-08-28
 * @param {*} state
 * @returns
 */
const checkAuthentication = (to, from, next) => {
    if (VueCookie.get('auth')) {
        store
            .dispatch('verify')
            .then((response) => {
                if (store.getters.auth && response) {
                    next()
                } else {
                    next('/login')
                }
            })
            .catch((err) => {
                console.error(`checkAuthentication > ${err.message}`)
                if (from.fullPath != '/login') {
                    next('/login')
                }
            })
    } else {
        next('/login')
    }
}

/**
 * checkAdminAuthentication
 *
 * @date 2020-08-28
 * @param {*} state
 * @returns
 */
const checkAdminAuthentication = (to, from, next) => {
    if (VueCookie.get('auth')) {
        store
            .dispatch('verify')
            .then((response) => {
                if (store.getters.isAdministrator && response) {
                    next()
                } else {
                    next('/d/home')
                }
            })
            .catch((err) => {
                console.error(`checkAuthentication > ${err.message}`)
                if (from.fullPath != '/login') {
                    next('/login')
                }
            })
    } else {
        next('/login')
    }
}

export default new Router({
    mode: 'hash',
    routes: [{
            path: '/int',
            component: Int
        },
        {
            path: '/',
            redirect: '/d'
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/test',
            name: 'test',
            component: () => import( /* webpackChunkName: "Formulation" */ '@/components/Firebase.vue'),
        },
        {
            path: '/d',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: checkAuthentication,
            redirect: '/d/base',
            children: [{
                    path: '/d/base',
                    name: 'Base',
                    component: Base,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/d/oilpricing',
                    name: 'Oil Pricing',
                    component: OilPricing,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/d/customerpk',
                    name: 'Customer PK Mapping',
                    component: CustomerPKMapping,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/d/rmc',
                    name: 'Raw Material Calcuatlor',
                    component: RawMaterialCalculator,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/d/samplerequest',
                    name: 'Sample Request',
                    component: SampleRequestForm,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/d/home',
                    name: 'Home',
                    component: Home,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/formulations',
                    name: 'Formulations',
                    component: () => import( /* webpackChunkName: "Formulation" */ '@/components/Formulations.vue'),
                    beforeEnter: checkAuthentication,
                },
                {
                    path: '/formulations/editor/:id?',
                    name: 'FormulationEditor',
                    component: () => import( /* webpackChunkName: "FormulationEditor" */ '@/components/FormulationEditor.vue'),
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/formulations/viewer/:id?',
                    name: 'FormulationViewer',
                    component: () => import( /* webpackChunkName: "FormulationViewer" */ '@/components/FormulationViewer.vue'),
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/admin',
                    name: 'Admin',
                    component: Admin,
                    beforeEnter: checkAdminAuthentication
                },
                {
                    path: '/docs/reports',
                    name: 'SalesReports',
                    component: SalesReports,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/docs/sales',
                    name: 'SalesLibrary',
                    component: SalesLibrary,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/nba',
                    name: 'NewBusinessAlert',
                    component: NewBusinessAlert,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/nba/details/:pid?',
                    props: true,
                    name: 'NewBusinessAlertDetails',
                    component: NewBusinessAlertDetails,
                    beforeEnter: checkAuthentication,
                },
                {
                    path: '/purchaseorders',
                    name: 'PurchaseOrder',
                    component: PurchaseOrders,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/items/quarter',
                    name: 'ItemsQuarterly',
                    component: ItemsQuarterly,
                    beforeEnter: checkAuthentication,
                    meta: {
                        title: 'Quarterly Items - FMI'
                    }
                },
                {
                    path: '/vendors/annual',
                    name: 'VendorsAnnual',
                    component: () => import( /* webpackChunkName: "VendorsAnnual" */ '@/components/VendorAnnual.vue'),
                    beforeEnter: checkAuthentication,
                    meta: {
                        title: 'Vendors Annual Sales'
                    }
                },
                {
                    path: '/company/quarter',
                    name: 'CompanyQuarterly',
                    component: CompanyQuarterly,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/items/annual',
                    name: 'ItemsAnnual',
                    component: ItemsAnnual,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/company/annual',
                    name: 'CompanyAnnual',
                    component: CompanyAnnual,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/projection',
                    name: 'Projection',
                    component: Projection,
                    beforeEnter: checkAuthentication
                },
                {
                    path: '/projection/rollup',
                    name: 'Rollup',
                    component: Rollup,
                    beforeEnter: checkAuthentication
                }
            ]
        }

    ]
})