<template>
  <v-container fluid>
    <v-expansion-panels accordion v-model="expansion_panel">
      <!-- -->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">Customer PK Mapping (IN DEVELOPMENT)</v-expansion-panel-header>
        <v-expansion-panel-content class="grey-striped">
          <!-- -->
          <v-card flat class="mt-4">
            <v-card-text>
            <v-row>
              <v-col>
                <v-data-table :headers="customer.headers"
                              :items="customer.items"
                              :loading="customer.loading"
                              :search="customer.search"></v-data-table>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
     </v-expansion-panels>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios'
import { helperMixin } from '../mixins/helper'
import { EventBus } from '../components/EventBus.js'
import formatDistance from 'date-fns/formatDistance'

const MAX_STAGE = 4
export default {
  name: 'RMC',
  mixins: [helperMixin],
  data: () => ({
    expansion_panel: 0,
    customer: {
      headers: [],
      items: [],
      loading: false,
      search: ''
    },
  }),
  beforeCreate () {

  },
  mounted() {

  },
  methods: {

  },
  computed: {

  }
}
</script>

<style scoped>
 .grey-striped { background: repeating-linear-gradient(55deg,#f4f4f4 5px,#eee 25px); padding: 5px!important; margin:0px!important; }
</style>
