export const helperMixin = {
  created() { },
  methods: {
    /**
     * @name    - 
     * @desc    - 
     * @param   - 
     * @returns - 
     */
    parseError(err) {
      if (err === null || err === undefined) {
        throw "Error object cannot be null or undefined."
      }
      switch (err) {
        case 404:
          this.response.msg = "Page Missing, this is critical.  Contact Support"
          break
        case 401:
          this.response.msg = "Not Authorized"
          break
      }
      return this.response
    }
    ,
    /**
     * @name    - 
     * @desc    - 
     * @param   - 
     * @returns - 
     */
    toCurrency(value, digits) {
      // console.log(`toCurrency::${value}`)
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: digits || 0,
        maximumFractionDigits: digits || 0
      })
    }
    ,
    /**
     * @name    - toKilos
     * @desc    - returns a comma delinated string representing kilograms
     * @param   - 
     * @returns - 
     */
    toKilos(value) {
      // console.log(`toKilos::${value}`)
      return value.toLocaleString("en-US", {
        minimumFractionDigits: value < 10 ? 1 : 0,
        maximumFractionDigits: value < 10 ? 1 : 0
      })
    }
    ,
    /**
     * @name    - toColor / Gray or Black
     * @desc    - return a vuetify string representing either grey text if the value is less than zero
     * @param   - a number
     * @returns - either 'black' or 'grey' --text
     */
    toColor(value) {
      return `${value <= 0 ? 'secondary' : 'black'}--text`
    }
    ,
    /**
     * @name    - notice
     * @desc    - shows a message in the console window in either important or info
     * @param   - message [string]
     * @returns - nothing
     */
    notice(msg) {
      typeof console.important === "undefined" ? console.info(`::${msg}`) : console.important(`::${msg}`)
    }
  }
}