<template>
  <v-container fluid>
  <v-data-table :headers="computedHeaders"
                :items="salesTableItems"
                :search="tableSearch"
                :loading="tableLoading"
                :footer-props="{
                  itemsPerPageText: '',
                  itemsPerPageOptions: [],
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus'}">
    <template v-slot:top>
      <v-toolbar color="blue darken-4 elevation-0" dark>
        <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">Sales Documents</v-toolbar-title>
        <v-toolbar-title v-else>Docs</v-toolbar-title>
        <v-divider class="mx-2 grey" inset vertical></v-divider>
        <v-btn outlined text color="white" @click="loadDocuments()">{{$store.getters.buttonDetails ? 'Refresh' : ''}}<v-icon :right="$store.getters.buttonDetails" color="white">mdi-refresh</v-icon></v-btn>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-text-field class="mt-5" v-model="tableSearch" label="Search"></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.agent="props"><span :class="props.item.agent == null ? 'secondary--text':''">{{props.item.agent == null ? 'Global': 'Agent/Self'}}</span></template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small class="mr-3" icon :href="linkurl(item)" target="_new"><v-icon>mdi-download</v-icon></v-btn>
      <v-btn x-small icon v-clipboard:copy="linkurl(item)" v-clipboard:success="copied" target="_new"><v-icon>mdi-share-variant</v-icon></v-btn>
    </template>
  </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { helperMixin } from '../mixins/helper'
import axios from 'axios'
export default {
  name: 'SalesLibrary',
  mixins: [helperMixin],
  data: () => ({
    url_base: 'https://portal.flavormaterials.com',
    salesTableHeaders: [
      { text: "Description", align: "left", value: "description" },
      { text: "Filename", align: "left", value: "filename", hide: 'mdAndDown' },
      { text: "Uploaded", align: "left", value: "uploaded", hide: 'mdAndDown' },
      { text: "Agent", align: "left", value: "agent", hide: 'mdAndDown' },
      { text: "Action", value: "actions", sortable: false }
    ],
    salesTableItems: [],
    tableSearch: '',
    tableLoading: false
  }),
  mounted() {
    this.loadDocuments()
  },
  computed: {
    computedHeaders () {
      return this.salesTableHeaders.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])  
    }
  },
  methods: {
    loadDocuments() {
      this.tableLoading = true
      axios.get(`${this.$store.getters.NODEURL}/library`, { withCredentials: true })
        .then((result) => {
          this.salesTableItems = result.data
        })
        .catch((err) => {
          console.warn(err)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    copied() { },
    linkurl(val) { return `${this.url_base}${val.path}` }
  }
}
</script>
