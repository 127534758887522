const ItemProductHeaders = [
{
    text: "Item",
    align: "left",
    sortable: !1,
    value: "DescItem",
    width: "100px",
    class: "white--text body-2 grey darken-1"
},
{
    text: "Description",
    align: "left",
    sortable: !1,
    value: "description",
    class: "white--text body-2 grey darken-1"
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "pq1",
    width: "90px",
    class: "white--text body-2 grey darken-1"
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "pq2",
    width: "90px",
    class: "white--text body-2 grey darken-1"
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "pq3",
    width: "90px",
    class: "white--text body-2 grey darken-1"
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "pq4",
    width: "90px",
    class: "white--text body-2 grey darken-1"
},
{
    text: "Total",
    align: "right",
    sortable: !1,
    value: "ptotal",
    width: "105px",
    class: "white--text body-2 grey darken-1",
    divider: true
},
{
    text: "",
    sortable: !1,
    value: "action",
    width: "20px",
    class: "grey darken-1",
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "cq1",
    width: "110px",
    class: "white--text body-2 grey darken-1",
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "cq2",
    class: "white--text body-2 grey darken-1",
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "cq3",
    class: "white--text body-2 grey darken-1",
},
{
    text: "",
    align: "right",
    sortable: !1,
    value: "cq4",
    class: "white--text body-2 grey darken-1",
},
{
    text: "Total",
    align: "right",
    sortable: !1,
    value: "ctotal",
    class: "white--text body-2 grey darken-1"
}
]

export default ItemProductHeaders