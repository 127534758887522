<template>
  <v-container fluid>

    <v-snackbar v-model="sb_model" top :color="sb_color" :timeout="sb_delay">
      {{ sb_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="sb_model = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-row no-gutters>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title>Annual Sales by Item</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-btn text outlined class="blue darken-2" elevation="0" @click="load(false)">
            <v-icon color="white">mdi-refresh</v-icon>
          </v-btn>
          <v-btn text outlined class="blue darken-2 ml-2" elevation="0" @click="exportToCSV"
            :disabled="!$store.getters.allowCSVExport">
            <v-icon color="white">mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn text outlined class="blue darken-2 ml-2" elevation="0" disabled light>
            <v-icon color="white">mdi-adobe-acrobat</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="annualItemSearch" label="Search" hide-details></v-text-field>
          <v-btn color="white" small depressed @click="drawer = !drawer" class="ml-4">
            <v-icon color="indigo">mdi-menu</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table id="itemAnnualDataTable" :headers="annualDataHeaders" :items="annualDataItems" class="elevation-3"
          :loading="annualDataLoading" item-key="id" :search="annualItemSearch" fixed-header dense :single-expand="true"
          show-expand hide-default-footer :expanded.sync="expanded" @item-expanded="rowExpand" :disable-sort="sortDesc"
          :footer-props="{ 'items-per-page-options': [-1] }">

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length"
              style="border-top:solid 4px #444;border-bottom:solid 4px #444;background-color:#444;" class="pa-3 ma-0">
              <v-data-table id="itemAnnualSubTable" :headers="annualSubHeaders" :items="annualSubItems"
                :loading="annualDataLoading" class="elevation-4 caption font-weight-bold" item-key="pid" dense
                hide-default-footer :footer-props="{ 'items-per-page-options': [-1] }">
                <template v-slot:top>
                  <v-toolbar color="indigo lighten-2 elevation-0" dark dense>
                    <v-toolbar-title>{{item.item}}</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.usd1`]="{ item }">{{ cvt(item.usd1) }}</template>
                <template v-slot:[`item.usd2`]="{ item }">{{ cvt(item.usd2) }}</template>
                <template v-slot:[`item.usd3`]="{ item }">{{ cvt(item.usd3) }}</template>
                <template v-slot:[`item.usd4`]="{ item }">{{ cvt(item.usd4) }}</template>
                <template v-slot:[`item.kg1`]="{ item }">{{ cvk(item.kg1) }}</template>
                <template v-slot:[`item.kg2`]="{ item }">{{ cvk(item.kg2) }}</template>
                <template v-slot:[`item.kg3`]="{ item }">{{ cvk(item.kg3) }}</template>
                <template v-slot:[`item.kg4`]="{ item }">{{ cvk(item.kg4) }}</template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:[`item.usd1`]="{ item }"><span :class="clr(item.usd1)">{{ cvt(item.usd1) }}</span></template>
          <template v-slot:[`item.usd2`]="{ item }"><span :class="clr(item.usd2)">{{ cvt(item.usd2) }}</span></template>
          <template v-slot:[`item.usd3`]="{ item }"><span :class="clr(item.usd3)">{{ cvt(item.usd3) }}</span></template>
          <template v-slot:[`item.usd4`]="{ item }"><span :class="clr(item.usd4)">{{ cvt(item.usd4) }}</span></template>
          <template v-slot:[`item.kg1`]="{ item }"><span :class="clr(item.kg1)">{{ cvk(item.kg1) }}</span></template>
          <template v-slot:[`item.kg2`]="{ item }"><span :class="clr(item.kg2)">{{ cvk(item.kg2) }}</span></template>
          <template v-slot:[`item.kg3`]="{ item }"><span :class="clr(item.kg3)">{{ cvk(item.kg3) }}</span></template>
          <template v-slot:[`item.kg4`]="{ item }"><span :class="clr(item.kg4)">{{ cvk(item.kg4) }}</span></template>
          <template v-slot:[`body.append`]>
            <tr style="background-color:#e3f2fd;color:black;height:40px!important;font-weight:bold">
              <td colspan="3"></td>
              <td class="text-right">{{cvt(totals[1])}}</td>
              <td class="text-right">{{cvt(totals[2])}}</td>
              <td class="text-right">{{cvt(totals[3])}}</td>
              <td class="text-right">{{cvt(totals[8])}}</td>
              <td class="text-right">{{cvk(totals[4])}}</td>
              <td class="text-right">{{cvk(totals[5])}}</td>
              <td class="text-right">{{cvk(totals[6])}}</td>
              <td class="text-right">{{cvk(totals[7])}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-navigation-drawer v-model="drawer" absolute temporary right width="400" @transitionend="watchDrawer($event)"
      class="bg-color">
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allLocations" block @click="flipAllLocations" dark
        class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allLocations?'No':'All'}} Locations
      </v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectLocationArray">
            <v-chip v-for="t in locationArray" :key="t.id" :small="!vs_button_larger" label
              :disabled="canHazPermission(t.idx)" active-class="success">
              <v-avatar left>
                <v-icon small v-if="selectLocationArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                </v-icon>
              </v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allRegionsFlag" block @click="flipAllRegions" dark
        class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allRegionsFlag?'No':'All'}} Regions
      </v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectedRegionCodeArray">
            <v-chip v-for="t in regionCodeArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
              <v-avatar left>
                <v-icon small v-if="selectedRegionCodeArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                </v-icon>
              </v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-btn color="blue-grey darken-2" elevation="0" v-model="allTypes" block @click="flipAllTypes" dark
        class="rounded-0">
        <v-icon left color="blue-grey">mdi-label</v-icon> {{allTypes?'No':'All'}} Types
      </v-btn>
      <v-row>
        <v-col md="10" offset-md="1">
          <v-chip-group active-class="primary--text" column multiple v-model="selectedTypeCodeArray">
            <v-chip v-for="t in typeCodeArray" :key="t.id" :small="!vs_button_larger" label active-class="success">
              <v-avatar left>
                <v-icon small v-if="selectedTypeCodeArray.includes(t.id)" color="white">mdi-checkbox-marked-circle
                </v-icon>
              </v-avatar>
              {{ t.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col md="10" offset-md="1">
            <v-btn @click="refreshData()" outlined color="green" block small>Search <v-icon right color="green" small>
                mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import async from 'async'
import axios from 'axios'
import { helperMixin } from '../mixins/helper'
import Headers from './data/items'

const rowNewLine = () => { return '\r\n' }
const DATABASE_FIRST_YEAR = 2013

export default {
  name: "AnnualSalesbyItem",
  mixins: [ helperMixin ],
  data: () => ({
    vs_button_larger: false,
    annualDataHeaders: Headers.AnnualSalesbyItem,
    annualSubHeaders: Headers.AnnualSalesbyItemSubHeaders,
    annualDataItems: [],
    annualSubItems: [],
    annualItemSearch: '',
    annualItemDense: true,
    annualDataLoading: false,
    exportDataLoading: false,
    dbError: false,
    totals: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // snack bar pop ups
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    iReportYears: [],
    vsUSDorKG: false,
    drawer: true,
    items_misc: false,
    items_ing: false,
    items_flv: true,
    expanded: [],
    sortDesc: false,

    // vswitch selections
    selectLocationArray: [0],
    selectedRegionCodeArray: [11],
    regionCodeArray: Headers.ZoneRegionCodes,
    allRegionsFlag: false,
    allLocations: false,
    allTypes: false,
    locationArray: [
      { text: 'FMI', id: 0, idx: 25 },
      { text: 'SMG', id: 1, idx: 26 },
      { text: 'GT', id: 2, idx: 27 },
      { text: 'CR', id: 3, idx: 28 },
    ],
    typeCodeArray: [ { text: 'Ingredients', id: 0, code: 'ING' }, { text: 'Flavors', id: 1, code:'FLV' } ],
    selectedTypeCodeArray:[],
  }),
  mounted: function () {
    this.columnQtrTitle()
  },
  computed: { },
  methods: {
    loadDrawerSelections() {
      let __flags = this.$store.getters.salesSelectionFlags
      // load the prior state of the selection array
      // this.selectLocationArray = __flags.companyByAnnual.locations.slice()
      // this.selectedRegionCodeArray = __flags.companyByAnnual.regions.slice()
      // this.selectedSalesAgentArray = __flags.companyByAnnual.agents.slice()
      // show the nav bar
      this.drawer = true
    },
    watchDrawer(e) {
      /*
      if (e.propertyName === 'transform' && !this.drawer) {
        this.$store.commit('', {
          locations: this.selectLocationArray,
          regions: this.selectedRegionCodeArray,
          agents: this.selectedSalesAgentArray
        })
      }
      */
    },
    canHazPermission(index) {
      return this.$store.getters.serializePermissions.toString(2)[index] == '1'
    },
    flipAllTypes () {
      this.allTypes = !this.allTypes
      if (this.allTypes) {
        for (var v in this.typeCodeArray) {
          this.selectedTypeCodeArray.push(this.typeCodeArray[v].id)
        }
      } else {
        this.selectedTypeCodeArray = []
      }
    },
    flipAllLocations() {
      this.allLocations = !this.allLocations
      if (this.allLocations) {
        for (var v in this.locationArray) {
          this.selectLocationArray.push(this.locationArray[v].id)
        }
      } else {
        this.selectLocationArray = []
      }
    },
    flipAllRegions() {
      this.allRegionsFlag = !this.allRegionsFlag
      if (this.allRegionsFlag) {
        for (var v in this.regionCodeArray) {
          this.selectedRegionCodeArray.push(this.regionCodeArray[v].id)
        }
      } else {
        this.selectedRegionCodeArray = []
      }
    },
    /* eslint-disable no-unreachable */
    rowExpand(item) {
      if (!item.value) return
      
      let qi = item.item.item
      let _tmpCountryCodes = this.jsonifyCountryCodes()
      this.tlPurchase = true
      
      this.iPurchaseList = []
      this.annualSubItems = []

      axios.post(`${this.$store.getters.NODEURL}/item/history/annual/company`, {
          countries: _tmpCountryCodes.join(','),
          itemcore: qi
        }, { withCredentials: true })
        .then(response => { this.annualSubItems = response.data })
        .catch(err => { console.log(err.response.status) })
        .finally(() => { this.tlPurchase = false })
    },
    columnQtrTitle: function () {
      let p = (this.$store.getters.currentSalesYear - 3).toString()
      for (let c = 2; c <= 5; ++c) {
        this.annualDataHeaders[c].text = `${p}$`
        this.annualDataHeaders[c + 4].text = `${p}KG`
        this.annualSubHeaders[c - 1].text = `${p}$`
        this.annualSubHeaders[c + 3].text = `${p}KG`
        ++p
      }
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    buildItemHeaders(padcol) {
      let row = '',
        idx = 0
      for (var i in this.annualDataHeaders) {
        if (i != undefined)
          row += `"${this.annualDataHeaders[i].text}",`
        if (++idx === 2 && padcol) {
          row += `"",`
        }
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildAllItemDataRows() {
      for (var i = 0, row = '', columnID = 0; i < this.annualDataItems.length; i++) {
        for (var index in this.annualDataItems[i]) {
          if(++columnID > 1) // skip the index field
            if(columnID >= 3 && columnID <= 7)
              row += `"${this.cvt(this.annualDataItems[i][index])}",`
            else
              row += `"${this.cvk(this.annualDataItems[i][index])}",`
        }
        columnID = 0
        row += rowNewLine()
      }
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildSingleItemDataRow(item, padcol) {
      let columnID = 0, row = '', idx = 0
      for (var index in this.annualDataItems[item]) {
        if (++columnID > 1 && columnID < 12) {
          if(columnID >= 3 && columnID <= 7)
              row += `"${this.cvt(this.annualDataItems[item][index])}",`
            else
              row += `"${this.cvk(this.annualDataItems[item][index])}",`

          if(idx++===1 && padcol) { row += `"",` }
        }
      }
      columnID = 0
      row += rowNewLine()
      return row.slice(0, row.length - 1) + rowNewLine()
    },
    buildItemHistoryHeaders(padcol) {
      let row = '', idx = 0
      for (var v in this.annualSubHeaders) {
          if (v != undefined)
            row += `"${this.annualSubHeaders[v].text}",`
          if (++idx === 1 && padcol) { row += `"","",` }
      }
      return row.slice(0, -1) + rowNewLine()
    },
    buildItemHistoryDataRows() {
      var idx
      for (var i = 0, row = '', columnID = 0; i < this.annualSubItems.length; i++) {
        idx = 0
        for (var t in this.annualSubItems[i]) {
          if (++columnID > 2) {
            if(columnID >= 3 && columnID <= 7)
              row += `"${this.cvt(this.annualSubItems[i][t])}",`
            else
              row += `"${this.cvk(this.annualSubItems[i][t])}",`
          }
          if (++idx === 3) { row += `"","",` }
        }
        columnID = 0
        row += rowNewLine()
      }
      return  row.slice(0, row.length - 1) + rowNewLine()
    },
    exportToCSV() {

      if (this.annualDataHeaders.length === 0) {
        this.snackbar({ color: 'error', msg: 'Nothing to export.', delay: 6000 })
        return
      }

      this.startLoadingAnimation()

      let CSVOUT = 'sep=,' + '\r\n', row = ''

      // if a row is currently expanded, show the name of the company
      // with the item parts below it only
      if (this.expanded.length) {
        CSVOUT += this.buildItemHeaders(true)
        CSVOUT += this.buildSingleItemDataRow(this.annualDataItems.findIndex(o => o.item === this.expanded[0].item), true)
        CSVOUT += this.buildItemHistoryHeaders(true)
        CSVOUT += this.buildItemHistoryDataRows()
      } else {
        CSVOUT += this.buildItemHeaders(false)
        CSVOUT += rowNewLine()
        CSVOUT += this.buildAllItemDataRows()
      }

      // build the link filename, hidden <A> and send it
      try {
        let fileName = `${this.$store.getters.name}_Annual_Items_Export`.replace(/ /g, "_")
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSVOUT)
        var link = document.createElement("a")
        link.href = uri
        link.style = "visibility:hidden"
        link.download = fileName + ".csv"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err) {
        this.showsnack({
          message: 'Cannot create file for export, the error has been logged.',
          color: 'warning'
        })
      }

      this.stopLoadingAnimation()
    },
    refreshData() {
      this.loadData()
    },
    clr(v) {
      return `${v <= 0 ? 'grey--text':''}`
    },
    cvt(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    cvk(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: value < 10 ? 1 : 0,
        maximumFractionDigits: value < 10 ? 1 : 0
      })
    },
    startLoadingAnimation() {
      this.annualDataLoading = true
    },
    stopLoadingAnimation() {
      this.annualDataLoading = false
    },
    loadData: function () {

      this.drawer = false
      this.annualDataItems = []
      let selectRegions = [], selectTypes = [], selectLocations = []

      if (this.selectLocationArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one location.', delay: 4000 })
        return
      }
      if (this.selectedRegionCodeArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one region.', delay: 4000})
        return
      }
      if (this.selectedTypeCodeArray.length === 0) {
        this.snackbar({ color: 'error', msg: 'You must select at least one type to display.', delay: 4000})
        return
      }

      // build up an array of selected country codes
      for (let p in this.selectLocationArray) {
        selectLocations.push(this.locationArray[this.selectLocationArray[p]].text)
      }
      for (let p in this.selectedRegionCodeArray) {
        selectRegions.push(this.regionCodeArray[this.selectedRegionCodeArray[p]].value)
      }
      for (let p in this.selectedTypeCodeArray) {
        selectTypes.push(this.typeCodeArray[this.selectedTypeCodeArray[p]].code)
      }

      this.startLoadingAnimation()

      async.waterfall(
        [
          async.apply(function (that, callback) {
            axios
              .post(`${that.$store.getters.NODEURL}/item/history/annual`, {
                countries: selectRegions.join(','),
                itemtypes: selectTypes.join(','),
                locations: selectLocations.join(',')
              }, {
                withCredentials: true
              })
              .then(response => {
                let itemcore = response.data.data.itemsArray // unique list of company items processed by NODE
                itemcore.sort((a, b) => b.usd3 - a.usd3)
                itemcore.forEach(r => {
                  that.totals[1] += r.usd1
                  that.totals[2] += r.usd2
                  that.totals[3] += r.usd3
                  that.totals[8] += r.usd4
                  that.totals[4] += r.kg1
                  that.totals[5] += r.kg2
                  that.totals[6] += r.kg3
                  that.totals[7] += r.kg4
                })
                that.annualDataItems = itemcore // initially show the USD values in the table
              })
              .catch(err => { console.log(err.response.status) })
              .finally(() => { that.stopLoadingAnimation() })
          }, this) /* async.apply */
        ],
        function (err, that) {
          if (err) {
            that.dbError = true
            this.snackbar({
              msg: 'Failed to load data.',
              color: 'error',
              delay: 3000
            })
          }
        }
      ) /* async.waterfall */
    },
    jsonifyCountryCodes () {
      let tmp = []
      for (var p in this.vmRegionCodes) {
        if (this.vmRegionCodes[p]) {
          tmp.push(this.irRegionCodes[p].value)
        }
      }
      return tmp
    },
  }
}
</script>

<style>
 #itemAnnualDataTable .v-data-table__wrapper{height:calc(100vh - 235px) !important;}
 #itemAnnualDataTable td:nth-child(n+8) { background-color:#e3f2fd; }
</style>

<style scoped>
 #itemAnnualDataTable >>> #itemAnnualSubTable td:nth-child(n+6) { background-color:#e3f2fd; }
 #itemAnnualDataTable >>> #itemAnnualSubTable td:nth-child(6) { border-left: solid 2px black!important; }
 #itemAnnualDataTable >>> #itemAnnualSubTable td:nth-child(8) { border-left:none!important; }
 #itemAnnualDataTable >>> #itemAnnualSubTable .v-data-table__wrapper{height:auto!important;}
</style>
