<template>
  <v-container fluid>
    <v-expansion-panels accordion mandatory v-model="expansion_panel">
      <!-- -->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">Oil Pricing</v-expansion-panel-header>
        <v-expansion-panel-content class="grey-striped">
          <!-- -->
          <v-card flat class="mt-4">
            <v-row>
              <v-col cols="12">

              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import formatDistance from 'date-fns/formatDistance'
import { helperMixin } from '../mixins/helper'

export default {
  name: 'Oil Pricing',
  mixins: [helperMixin],
  data: () => ({
    expansion_panel: 0,
  }),
  beforeCreate () { },
  mounted() { },
  methods: {

  },
  computed: {}
}
</script>

<style scoped>
 .grey-striped { background: repeating-linear-gradient(55deg,#f4f4f4 5px,#eee 25px); padding: 5px!important; margin:0px!important; }
</style>
