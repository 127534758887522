<template>
  <v-app id="dashboard">

    <!-- Top Application Bar -->
    <v-app-bar fixed app color="blue darken-4" dark flat style="border-bottom:solid 2px #ddd!important;">
      <div style="max-width:90px!important" class="d-flex align-center">
        <v-img src="@/assets/logo_blue.jpg" max-height="56" max-width="90" contain @click="goto('/d/base')"></v-img>
      </div>
      <v-divider vertical class="mx-2 blue darken-3"></v-divider>
        <!-- -->
        <v-menu class="d-none d-md-flex">`
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text v-bind="attrs" v-on="on" class="text-overline">HOME <v-icon small right>mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list flat dense>
            <v-list-item @click="goto('/d/base')" class="link">
                <v-list-item-title>Home</v-list-item-title>
                <v-list-item-icon><v-icon small>mdi-home-outline</v-icon></v-list-item-icon>
            </v-list-item>
            <v-list-item @click="rightDrawer = !rightDrawer" class="link">
                <v-list-item-title>My Settings</v-list-item-title>
                <v-list-item-icon><v-icon small>mdi-cog-outline</v-icon></v-list-item-icon>
            </v-list-item>
            <v-list-item @click="goto('/admin')" v-if="$store.getters.isRoot">
                <v-list-item-title>Admin Settings</v-list-item-title>
                <v-list-item-icon><v-icon small>mdi-lock-outline</v-icon></v-list-item-icon>
            </v-list-item>
            <v-divider inset class="mx-2"></v-divider>
            <v-list-item @click="signOut()">
                <v-list-item-title>Logout</v-list-item-title>
                <v-list-item-icon><v-icon small>mdi-logout</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="mx-2 blue darken-3"></v-divider>

        <!-- -->
        <v-menu offset-y class="d-none d-md-flex">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text v-bind="attrs" v-on="on" class="text-overline">Applications <v-icon small right >mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list flat dense>
            <v-list-item v-if="$store.getters.editFormulations" @click="goto('/formulations')"> <v-list-item-title>Formulations</v-list-item-title> </v-list-item>
            <v-list-item @click="goto('/d/samplerequest')"> <v-list-item-title>Samples Request Management</v-list-item-title> </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="mx-2 blue darken-3"></v-divider>
        <!-- -->
        <v-menu offset-y class="d-none d-md-flex">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text  v-bind="attrs" v-on="on" class="text-overline" @click="goto('/nba')" v-if="$store.getters.allowBusinessAlerts">Business Alerts</v-btn>
          </template>
        </v-menu>
        <v-divider vertical class="mx-2 blue darken-3"></v-divider>
        <!-- -->
        <v-menu offset-y class="d-none d-md-flex">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text  v-bind="attrs" v-on="on" class="text-overline" @click="goto('/purchaseorders')" v-if="$store.getters.allowPurchases">Purchases</v-btn>
          </template>
        </v-menu>
        <v-divider vertical class="mx-2 blue darken-3"></v-divider>
        <!-- -->
        <v-menu offset-y class="d-none d-md-flex" v-if="$store.getters.allowSales">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text  v-bind="attrs" v-on="on" class="text-overline">Sales <v-icon small right >mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list flat dense>
            <v-list-item @click="goto('/company/annual')"> <v-list-item-content> <v-list-item-title>Annual Sales by Customer</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-list-item @click="goto('/company/quarter')"> <v-list-item-content> <v-list-item-title>Quarterly Sales by Customer</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-divider inset class="mx-2"></v-divider>
            <v-list-item @click="goto('/items/annual')"> <v-list-item-content> <v-list-item-title>Annual Sales by Item</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-list-item @click="goto('/items/quarter')"> <v-list-item-content> <v-list-item-title>Quarterly Sales by Item</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-divider inset class="mx-2"></v-divider>
            <v-list-item disabled> <v-list-item-content> <v-list-item-title>Annual Sales by Vendor</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-list-item disabled> <v-list-item-content> <v-list-item-title>Sales Library</v-list-item-title> </v-list-item-content> </v-list-item>
          </v-list>
        </v-menu>
        <v-divider vertical class="mx-2 blue darken-3"></v-divider>
        <!-- -->
        <v-menu offset-y class="d-none d-md-flex" v-if="$store.getters.allowSales">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text  v-bind="attrs" v-on="on" class="text-overline">Projections</v-btn>
          </template>
        </v-menu>
        <v-divider vertical class="mx-2 blue darken-3"></v-divider>
        <!-- -->
        <v-menu offset-y class="d-none d-md-flex" v-if="$store.getters.allowSales">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text  v-bind="attrs" v-on="on" class="text-overline">Utilities <v-icon small right >mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list flat dense>
            <v-list-item @click="goto('/d/rmc')"> <v-list-item-content> <v-list-item-title>RMC</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-list-item @click="goto('/d/oilpricing')"> <v-list-item-content> <v-list-item-title>Oils Pricing</v-list-item-title> </v-list-item-content> </v-list-item>
            <v-list-item @click="goto('/d/customerpk')"> <v-list-item-content> <v-list-item-title>Customer PK Mapping</v-list-item-title> </v-list-item-content> </v-list-item>
          </v-list>
        </v-menu>

      <v-spacer></v-spacer>
      <v-btn text color="grey lighten-3" v-if="$store.getters.isRoot" to="/admin"> <v-icon large>mdi-account-group</v-icon> </v-btn>
      <v-btn text color="grey lighten-3" class="ml-2" @click="rightDrawer=!rightDrawer"> <v-icon large>mdi-account</v-icon> </v-btn>

    </v-app-bar>

    <!-- Main Content -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- Navigation Drawer / Password -->
    <v-navigation-drawer v-model="rightDrawer" absolute temporary :permanent="forcePasswordChange" right width="450">
      <v-overlay absolute :value="overlay" opacity=".85">
        <div class="text-center">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class='text-h4'
                :class="bannerMessage.includes('Fail') ? 'orange--text':'yellow--text'">{{bannerMessage}}
              </v-list-item-title>
              <v-list-item-subtitle class="white--text text-subtitle-2">{{submessage}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-progress-circular indeterminate size="32"></v-progress-circular>
          </v-list-item>
        </div>
      </v-overlay>
      <v-container fluid>
        <v-list-item v-if="forcePasswordChange">
          <v-list-item-content>
            <v-list-item-title>
              <v-btn block outlined color="red" class="font-weight-bold">Password Update Required</v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="blue--text">{{$store.getters.name}}</v-list-item-title>
            <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-form>
          <v-row>
            <v-col md="5" offset-md="1">
              <v-text-field class="lblcolor" autocomplete="off" label="New Password" type="password" v-model="pn1"
                maxlength="24" :counter="24" :error-messages="passwordError" @input="$v.pn1.$touch()"
                @blur="$v.pn1.$touch()" required></v-text-field>
            </v-col>
            <v-col md="5">
              <v-text-field class="lblcolor" autocomplete="off" label="Confirm Password" type="password" v-model="pn2"
                maxlength="24" :counter="24" :error-messages="confirmError" @input="$v.pn2.$touch()"
                @blur="$v.pn2.$touch()" required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col md="10" offset-md="1">
            <v-progress-linear class="mb-1" :value="passwordScore(pn1)" :color="passwordColorMeter"></v-progress-linear>
            <v-btn block small color="success" :loading="loadPasswordChange" :disabled="allowChange()" outlined
              @click="updateProfilePassword()">Update Password</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="10" offset-md="1">
            <v-switch dense small v-model="buttonDetails" @change="buttonSwap" label="Label Details"
              hint="Enable/Disable details on all dialog buttons that support it" persistent-hint></v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="10" offset-md="1">
            <v-switch dense small v-model="display_table_dense" @change="dense_swap" label="Dense Tables"
              hint="Set the density of tables" persistent-hint></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="$store.getters.allowBusinessAlerts">
          <v-col md="12">
            <v-list>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="blue--text">New Business Alert Permissions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item-group class="mt-6">
                <v-list-item v-for="m in drawerItems" :key="m.label" class="mt-n6" disabled dense>
                  <v-list-item-content>
                    <span
                      :class="canHazPermission(m.idx) ? 'overline black--text' : 'overline grey--text text--lighten-2'">{{m.label}}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="blue--text">Site Permissions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item-group class="mt-6">
                <v-list-item v-for="m in permissionItems" :key="m.label" class="mt-n6" disabled dense>
                  <v-list-item-content>
                    <span
                      :class="canHazPermission(m.idx) ? 'overline black--text' : 'overline grey--text text--lighten-2'">{{m.label}}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <!-- Footer/Large -->
    <v-footer app color="blue darken-4" dark>
      <span class="caption">&copy; 2019-22 FLAVOR MATERIALS</span>
      <v-divider class="mx-4 grey" vertical></v-divider>
      <span class="caption yellow--text font-weight-bold">{{$store.getters.name}}</span>
      <span v-if="$vuetify.breakpoint.smAndUp" class="caption white--text ml-1">{{$store.getters.memberToString}}</span>
      <v-divider class="mx-4 grey" vertical></v-divider>
      <span class="caption white--text">{{duo()}}</span>
      <v-spacer></v-spacer>
      <span class="caption indigo--text text--lighten-3"><small>Coded by United Computer Technologies - Version July/Beta 51</small></span>
    </v-footer>

  </v-app>
</template>

<script>
const MAX_DISPLAY_QUEUE = 25
/* eslint-disable no-unused-vars */
import axios from 'axios'
import VueCookie from 'vue-cookie'
import { EventBus } from './EventBus.js'
import {
  required,
  minLength,
  sameAs
} from "vuelidate/lib/validators"

export default {
  name: 'App',
  components: {},
  beforeMount() {},
  mounted() {
    this.email = this.$store.getters.email
    this.lastlogin = '1/1/2021'
    this.buttonDetails = this.$store.getters.buttonDetails
    // user has the system default password - force a password reset
    if (this.$store.getters.defaultPasswordFlag) {
      this.forcePasswordChange = true
      this.rightDrawer = true
    }
    EventBus.$on('activity', (e) => {
      console.log('adding ' + e.message)
      this.actions.push(e.message)
      if(this.actions.length >= MAX_DISPLAY_QUEUE) { this.actions.shift() }
    })
  },
  data: () => ({
    actions: [],
    overlay: false,
    forcePasswordChange: false,
    passwordColorMeter: '',
    bannerMessage: '',
    submessage: '',
    nbadetails: false,
    // snackbar
    sb_model: false,
    sb_color: '',
    sb_delay: 4000,
    sb_msg: '',
    // page
    userProfile: false,
    lastlogin: '',
    drawer: true,
    email: '',
    pn1: '',
    pn2: '',
    loadPasswordChange: false,
    buttonDetails: false,
    display_table_dense: false,
    rightDrawer: false,
    // allowed: [],
    accountMenu: [{
      title: 'My Profile',
      method: 'showProfile'
    }],
    drawerItems: [
      {
        label: 'Research & Development',
        idx: 15
      },
      {
        label: 'Quality Control',
        idx: 16
      },
      {
        label: 'Purchasing',
        idx: 17
      },
      {
        label: 'Customer Service',
        idx: 18
      },
      {
        label: 'Operations',
        idx: 19
      },
      {
        label: 'Archiving',
        idx: 20
      },
    ],
    permissionItems: [{
        label: 'CSV Exporting',
        idx: 23
      },
      {
        label: 'PDF Exporting',
        idx: 24
      },
    ]
  }),
  methods: {
    duo () {
      return VueCookie.get('duo') === "true" ? 'DUO':''
    },
    canHazPermission(index) {
      return this.$store.getters.serializePermissions.toString(2)[index] == '1'
    },
    allowChange: function () {
      return !this.$v.pn1.$invalid && !this.$v.pn2.$invalid && this.$v.pn2.sameAsPassword ? false : true
    },
    dense_swap: function () {
      this.$store.commit('preference_dense', this.display_table_dense)
    },
    buttonSwap: function () {
      this.$store.commit('buttonDetails', this.buttonDetails)
    },
    snackbar: function (data) {
      this.sb_color = data.color
      this.sb_msg = data.msg
      this.sb_delay = data.delay || 4000
      this.sb_model = true
    },
    updateProfilePassword() {
      this.loadPasswordChange = true
      axios.post(`${this.$store.getters.NODEURL}/update`, {
          newp: this.pn1,
          emailaddress: this.$store.getters.email
        }, {
          withCredentials: true
        })
        .then((response) => {
          if(!response.data.error) {
            this.overlay = true
            this.bannerMessage = 'Password Updated'
            this.submessage = 'You will now be logged out to refresh your credentials.'
            setTimeout(() => { this.signOut() }, 4000)
          } else {
            this.overlay = true
            this.bannerMessage = 'Update Failed'
            if(response.data.data === 'dfp') {
              this.submessage = 'Contact support to reset your password (DFP)'
            } else {
              this.submessage = 'Contact support to reset your password (Server)'
            }
            this.pn1 = ''
            this.pn2 = ''
          }
        })
        .catch(err => {
          this.bannerMessage = 'Password update failed, possible server error.'
        })
        .finally(() => {
          this.loadPasswordChange = false
        })
    },
    showProfile() {
      this.userProfile = true
    },
    goto(link) {
      if (this.$router.currentRoute.path != link) {
        this.$router.push(`${link}`)
      }
    },
    signOut() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    passwordScore(pass) {
      let score = 0
      if (!pass) return score
      let letters = new Object()
      for (var i=0; i<  pass.length; i++) {
          letters[pass[i]] = (letters[pass[i]] || 0) + 1
          score += 5.0 / letters[pass[i]]
      }
      let variations = { digits: /\d/.test(pass), lower: /[a-z]/.test(pass), upper: /[A-Z]/.test(pass), nonWords: /\W/.test(pass) }
      let variationCount = 0
      for (let check in variations) { variationCount += (variations[check] == true) ? 1 : 0 }
      score += (variationCount - 1) * 10
      this.passwordColorMeter = score < 20 ? '#ff4400' : score >= 20 && score <= 50 ? '#ffaa00' : score >=51 && score <= 81 ? '#41ff00' : '#00aa00'
      return parseInt(score)
    }
  },
  computed: {
    passwordError() {
      const errors = []
      if (!this.$v.pn1.$dirty) return errors
      !this.$v.pn1.minLength && errors.push("Password must be at least 8 characters long.")
      return errors
    },
    confirmError() {
      const errors = []
      if (!this.$v.pn2.$dirty) return errors
      if (!this.$v.pn2.sameAsPassword) errors.push("Passwords must match.")
      return errors
    }
  },
  validations: {
    pn1: {
      required,
      minLength: minLength(8)
    },
    pn2: {
      sameAsPassword: sameAs('pn1')
    }
  }
}
</script>

<style scoped>
  .link { cursor: pointer; }
  .lblcolor >>> .v-label {color:#aaa!important;font-size:small!important;}
  /deep/ div.v-toolbar__extension { background-color: #1A237E; }
</style>