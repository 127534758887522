<template>
  <v-container fluid class="bgimage" style="padding:0!important;margin:0!important;overflow:hidden;">
    <v-app id="login" style="background:none;">

      <v-dialog persistent v-model="disclaimer" max-width="600">
        <v-card>
          <v-toolbar color="indigo darken-2" dark flat>
            <v-toolbar-title>Flavor Materials Application Portal</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="pt-5">
              This is a disclaimer for Flavor Materials that you must agree to before accessing this site. Once you
              accept the terms, a cookie is set to hide the window until the terms change or your cookies are cleared. This is not the
              final text, that must be finalized.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined ripple color="error" @click="disagree">Disagree</v-btn>
            <v-spacer></v-spacer>
            <v-btn outlined ripple color="success" @click="agree">Agree</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="loginDialog" max-width="500px" hide-overlay>
        <v-alert prominent :type="alertColor" v-model="alert" close-text="Close Alert" dismissible>
          <v-row justify="center" align="center">
            <v-col class="grow">
              <div class="caption">{{alertMessage}}</div>
            </v-col>
          </v-row>
        </v-alert>
        <v-card tile elevation="0">
          <v-toolbar color="indigo darken-3" dark flat>
            <v-toolbar-title>
              <v-img src="@/assets/logo64px.png" alt="" max-height="54" max-width="130" contain></v-img>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-form ref="loginform" @keyup.native.enter="auth">
              <v-text-field v-model="emailAddress" :error-messages="emailAddressError" required
                @input="$v.emailAddress.$touch()" @blur="$v.emailAddress.$touch()" label="Email Address" counter="45"
                max-length="45" prepend-icon="person" hint="Email address" type="text" autofocus autocomplete="off">
              </v-text-field>
              <v-text-field v-model="password" :error-messages="passwordError" required @input="$v.password.$touch()" counter="24"
                @blur="$v.password.$touch()" label="Password" name="password" prepend-icon="lock" type="password" autocomplete="current-password">
              </v-text-field>
              <!-- -->
              <v-text-field v-model="twofactor" :error-messages="twofactorError" disabled
                            label="Two Factor Authorization" name="twofactor" prepend-icon="mdi-two-factor-authentication" counter="6" maxlength="6"
                            hint="You are not yet required to provide a two-factor key" persistent-hint type="text">
              </v-text-field>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="loadingstatus" x-small fab elevation="0"> <v-icon>mdi-database</v-icon> </v-btn>
            <span class="overline secondary--text">{{timerMsg}}</span>
            <v-spacer></v-spacer>
            <v-btn text outlined color="success" :loading="loginLoading" :disabled="allowlogin" @click="auth">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from "axios"
import VueCookie from 'vue-cookie'

import {
  required,
  minLength,
  email
} from "vuelidate/lib/validators"

export default {
  name: "Login",
  data: () => ({
    twofactor: '',
    // loadings
    loginLoading: false,
    loadingstatus: false,
    // form fields
    emailAddress: '',
    disclaimer: false,
    password: '',
    loginDialog: true,
    loading: false,
    alert: false,
    alertMessage: '',
    alertColor: 'error',
    required: false,
    loginallowed: false,
    statuscolor: 'success',
    intervalTimer: null,
    timerMsg: '',
  }),
  beforeMount: function () {
    localStorage.clear()
  },
  methods: {
    /*
      stage one - username password > validate in SQL database, if valid, build duo authentication token
      and send it to duo auth iframe with SIG
    */
    auth() {
      this.loginLoading = true
      axios.post(`${this.$store.getters.NODEURL}/auth`, {
          email: this.emailAddress,
          password: this.password
        }, {
          withCredentials: true
        })
        .then(response => {
          if (response.data.sig) {
            window.location = this.$store.getters.isDeveloperMode ? `http://localhost:8080/duo.html?sig=${response.data.sig}` : `https://portal.flavormaterials.com/duo.html?sig=${response.data.sig}`
          }
          if (response.data) {
            response.data.pid = response.data.pid || 0
            let payload = response.data.data
            if (payload.pid) {
              //* store the users access levels and personal information into the vue store
              this.$store.commit('details', payload)
              this.$store.commit('deserializePermissions', payload.pms)
              // email goes into separate store object
              this.$store.commit('email', this.emailAddress)
              // flag the user as being authed
              this.$store.commit('auth', true)
              // send them to the base page
              this.$router.push("/d/base").catch((e) => {
                console.log(e)
              })
            } else {
              this.showalert("Cannot find the database server, the system may be down for maintenance.", "warning")
              this.failedLogin = true
            }
          }
        })
        .catch(e => {
          this.parseServerResponse(e)
        })
        .finally(() => {
          this.loginLoading = false
        })
    },
    showalert(message, color) {
      this.alertColor = color
      this.alertMessage = message
      this.alert = true
    },
    clearalert() {
      this.message = ""
      this.alert = false
    },
    agree() {
      this.disclaimer = false
      VueCookie.set('disclaimer', true, {
        expires: '1Y'
      })
    },
    disagree() {
      this.disclaimer = false
      VueCookie.delete('disclaimer')
      location.href = 'http://www.flavormaterials.com/'
    },
    parseServerResponse(data) {
      if (data.message === 'Network Error' || data.response.status === 'ETIMEOUT') {
        this.showalert("The system may be down for maintenance.  Try again shortly or contact support ...", "warning")
        this.loginallowed = false
      } else if (data.response.status === 401) {
        this.showalert("Invalid login credentials.", "error")
        this.loginallowed = this.failedLogin = true
      }
    },
    periodicCheck() {
      this.timerMsg = 'waiting ...'
      this.statuscolor = 'error'
      this.loadingstatus = true
      this.intervalTimer = setTimeout(function () {
        this.intervalTimer = null
        this.statuscolor = 'success'
        this.serverstatus()
      }.bind(this), 30 * 1000)
    },
    serverstatus() {
      this.loadingstatus = true
      axios.get(`${this.$store.getters.NODEURL}/status`)
        .then(response => {
          if (!response.data.thumbprint || !response.data.cache) {
            this.showalert("The system may be down for maintenance or there is a problem with your Internet connection.", "warning")
            this.periodicCheck()
          } else {
            this.loadingstatus = false
            this.timerMsg = ''
            this.loginallowed = true
            this.alert = false
          }
        })
        .catch(e => {
          this.parseServerResponse(e)
          this.periodicCheck()
        })
        .finally(() => {})
    }
  },
  mounted: function () {
    // check if the user has signed the disclaimer
    this.disclaimer = VueCookie.get('disclaimer') === 'true' ? false : true
    // check server status
    this.serverstatus()
  },
  computed: {
    allowlogin() {
      if (!this.loginallowed)
        return true
      return !this.$v.emailAddress.$invalid && !this.$v.password.$invalid ? false : true
    },
    emailAddressError() {
      const errors = []
      if (!this.$v.emailAddress.$dirty)
        return errors;
      !this.$v.emailAddress.email && errors.push("Not a valid e-mail")
      return errors
    },
    passwordError() {
      const errors = []
      if (!this.$v.password.$dirty)
        return errors;
      !this.$v.password.minLength && errors.push("Password must be at least 8 characters long")
      return errors
    },
    twofactorError() {
      const errors = []
      return errors
    },
    checkform: function () {
      return this.emailAddress.length >= 3 && this.password.length >= 3
    }
  },
  validations: {
    emailAddress: { required, email },
    password: { required, minLength: minLength(8) },
    twofactor: { required, minLength: minLength(6), }
  }
}
</script>

<style lang="scss" scoped>
.bgimage { background-image: url('../assets/chem.jpg'); background-size: cover; }
</style>