var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"blue darken-2 ml-1",attrs:{"elevation":"2","text":"","outlined":""},on:{"click":function($event){return _vm.loadDocuments()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('v-text-field',{staticClass:"mt-5",attrs:{"hide-details":"","label":"Search"},model:{value:(_vm.reports.search),callback:function ($$v) {_vm.$set(_vm.reports, "search", $$v)},expression:"reports.search"}})],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-data-table',{attrs:{"headers":_vm.reports.headers,"items":_vm.reports.items,"search":_vm.reports.search,"loading":_vm.reports.loading,"items-per-page":20,"item-key":"fid","footer-props":{
                      'items-per-page-options': [20,20],
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                      'items-per-page-text':'Reports per page'}},scopedSlots:_vm._u([{key:"item.agent",fn:function(props){return [_c('span',{class:props.item.agent == null ? 'secondary--text':''},[_vm._v(_vm._s(props.item.agent == null ? 'Global': 'Agent/Self'))])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"blue darken-2 ml-1",attrs:{"small":"","text":"","outlined":"","target":"_new"},on:{"click":function($event){return _vm.getIdentifier(item)}}},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-download")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }