const SalesPriorHeaders = [{
        text: "Unit",
        align: "right",
        sortable: !1,
        value: "text",
        class: "white--text body-2 grey darken-1"
    },
    {
        text: "Q1",
        align: "right",
        sortable: !1,
        value: "q1",
        class: "white--text body-2 grey darken-1"
    },
    {
        text: "Q2",
        align: "right",
        sortable: !1,
        value: "q2",
        class: "white--text body-2 grey darken-1"
    },
    {
        text: "Q3",
        align: "right",
        sortable: !1,
        value: "q3",
        class: "white--text body-2 grey darken-1"
    },
    {
        text: "Q4",
        align: "right",
        sortable: !1,
        value: "q4",
        class: "white--text body-2 grey darken-1"
    },
    {
        text: "Total",
        align: "right",
        sortable: !1,
        value: "total",
        class: "white--text body-2 grey darken-1"
    }
]

export default SalesPriorHeaders